import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const enrollmentApi = createApi({
    baseQuery: baseQuery,
    reducerPath: "enrollmentApi",
    endpoints: builder => ({
        addEnrollPayment: builder.mutation({
            query: (body) => ({
                url: "/enroll/addEnrollPayment",
                method: "POST",
                body,
            }),
        }),
        deleteEnrollPayment: builder.mutation({
            query: (id) => ({
                url: `/enroll/enrollPayment/${id}`,
                method: "DELETE",
            }),
        }),
        uploadAcademicProve: builder.mutation({
            query: ({ studentId, file }) => {
                const formData = new FormData();
                formData.append('studentId', studentId)
                formData.append('file', file)

                return {
                    url: '/student/uploadAcademicProve',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        uploadPaymentSlip: builder.mutation({
            query: ({ studentId, courseId, intake, file }) => {
                const formData = new FormData();
                formData.append('studentId', studentId)
                formData.append('courseId', courseId)
                formData.append('intake', intake)
                formData.append('file', file)

                return {
                    url: '/enroll/uploadPaymentSlip',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        uploadSupportingDocument: builder.mutation({
            query: ({ studentId, courseId, intake, file, documentKey }) => {
                const formData = new FormData();
                formData.append('studentId', studentId)
                formData.append('courseId', courseId)
                formData.append('intake', intake)
                formData.append('documentKey', documentKey)
                formData.append('file', file)

                return {
                    url: '/enroll/uploadSupportDocument',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
    }),
});

export const {
    useAddEnrollPaymentMutation,
    useDeleteEnrollPaymentMutation,
    useUploadAcademicProveMutation,
    useUploadPaymentSlipMutation,
    useUploadSupportingDocumentMutation,
} = enrollmentApi;
