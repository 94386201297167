import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useCloneIntakeMutation, useCreateNextIntakeMutation,
    useGetIntakeListQuery,
    useLazyGetIntakeListQuery
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate} from "react-router-dom";
import {delay} from "../../../../utils/util";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {IOSSwitch} from "../../../../components/Switcher";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {useDispatch} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
const statusColors = {
    'PUBLISHED': 'green',
    'ACTIVE': 'blue',
    'INACTIVE': '#D3D3D3',
};

const StatusBall = styled('div')(({ theme, status }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: statusColors[status] || theme.palette.error.main,
    display: 'inline-block',
    marginLeft: 5,
    marginRight: 10,
}));


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}


function CustomGridTreeDataGroupingCell(props) {
    const { id, field, row, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };
    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                <Tooltip title={props.row.status}>
                    <StatusBall status={props.row.status} />
                </Tooltip>
                { row.group.length === 1 ?
                    <strong>Intake {row.intake}</strong>
                    :
                    <span>Intake {row.intake}</span>
                }

                <IconButton
                    onClick={handleClick}
                    size="small"
                >
                    {filteredDescendantCount > 0 &&
                        (rowNode.childrenExpanded ?
                            <ExpandMoreIcon /> :
                            <KeyboardArrowRightIcon />)
                    }
                </IconButton>
            </div>
        </Box>
    );
}
export default function IntakeList() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fetchIntakeList] = useLazyGetIntakeListQuery();
    const [createNewIntake] = useCreateNextIntakeMutation();
    const [loadingRowId, setLoadingRowId] = useState(null)
    const [rawData, setRawData] = useState(null);
    const [data, setData] = useState(null);
    const storeActive = localStorage.getItem('onlyActive');
    const [onlyActive, setOnlyActive] = React.useState(storeActive === null ? true : storeActive === 'true');


    const handleCreateNewIntake = async (row) => {
        setLoadingRowId(row.id);
        await delay(1000);
        const { data } = await createNewIntake({
            id: row.courseId,
        })
        if(data.code !== 'SYS0000') {
            alert("Fail to create new intake");
        }
        navigate(`/intake/${row.courseId}/${data.data.intake}`);
        setLoadingRowId(null);
    }

    const refresh = () => {
        fetchIntakeList().then(({ data }) => {
            let { data: content } = data;
            setRawData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        if(rawData !== null) {
            const filterData = onlyActive ? rawData?.filter((item) => item.status !== 'INACTIVE') : rawData;
            const result = filterData?.map((item, index) => {
                const maxIntake = filterData
                    .filter((i) => i.courseId === item.courseId)
                    .sort((a, b) => b.intake - a.intake)[0].intake;
                if (maxIntake === item.intake) {
                    return {
                        ...item,
                        id: index,
                        group: [`${item.courseId}_${maxIntake}`]
                    }
                }
                return {
                    ...item,
                    id: index,
                    group: [`${item.courseId}_${maxIntake}`, item.intake]
                }
            })
                .sort((a, b) => b.courseId - a.courseId)
                .sort((a, b) => b.intake - a.intake)
                .sort((a, b) => b.startDate - a.startDate);

            setData(result);
        }
    }, [rawData, onlyActive]);

    const groupingColDef = {
        width: 160,
        headerName: 'No.',
        renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
    };



    const columns = [
        {
            field: "intake",
            headerName: "Intake",
            hide: true
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Course Name",
            flex: 2.5
        },
        {
            field: "startDate",
            ...defaultColumnType,
            headerName: "From",
            renderCell: (params) => {
                return moment(params.row.startDate).format('DD/MM/YYYY');
            },
            width: 160,

        },
        {
            field: "endDate",
            ...defaultColumnType,
            headerName: "To",
            renderCell: (params) => {
                return moment(params.row.endDate).format('DD/MM/YYYY');
            },
            width: 160
        },
        {
            field: "enrollment",
            ...defaultColumnType,
            headerName: "Enrollment",
            renderCell: (params) => {
                if(params.row.totalEnrollment === 0){
                    return "N/A"
                }
                return `${params.row.completedEnrollment}/${params.row.totalEnrollment}`
            },
            width: 150
        },
        {
            field: "totalStudent",
            ...defaultColumnType,
            headerName: "Student",
            renderCell: (params) => {
                if(params.row.totalStudent === 0){
                    return "N/A"
                }
                return `${params.row.totalStudent}/${params.row.quota}`
            },
            width: 100
        },
        {
            field: "action",
            ...defaultColumnType,
            headerName: "Active Only",
            renderHeader: (params) => {
                return (
                    <Box sx={{ width: 200 }}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                            {params.colDef.headerName}{" "}
                            <IOSSwitch
                                sx={{ m: 1 }}
                                checked={onlyActive}
                                onChange={(e) => {
                                    localStorage.setItem('onlyActive', (!onlyActive).toString());
                                    setOnlyActive(!onlyActive)
                                }}
                            />
                        </Stack>
                    </Box>
                )
            },
            renderCell: ({ row, api, id }) => {
                const rowNode = api.getRowNode(id);
                if (rowNode.depth === 0) {  // Only show the button for the first record in each tree
                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            {row.cloneable &&
                                <LoadingButton
                                    variant="outlined"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        handleCreateNewIntake(row)
                                    }}
                                    startIcon={<AddIcon />}
                                    loading={loadingRowId === row.id}
                                >
                                    New Intake
                                </LoadingButton>
                            }
                        </Box>
                    );
                }
                return null;
            },
            width: 180
        }
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "intake": false,
                "brokerId": false
            }
        },
        pagination: { paginationModel: { pageSize: 5 } },
    }

    const onRowClick = ({ row }) => {
        if(row.editable) {
            dispatch(setIntakeTab({
                route: 'Enrollment',
                state: null,
            }))
            navigate(`/intake/${row.courseId}/${row.intake}`);
        }
    };
    console.log("data", data)
    return (
        <Fade in={true}>
        <Box sx={{ width: '100%' }}>
            <StyledTableOne
                autoHeight
                treeData
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                loading={data === null}
                disableRowSelectionOnClick
                getTreeDataPath={(row) => row.group || []}
                groupingColDef={groupingColDef}
                getRowId={(row) => row.id}
                pageSizeOptions={[5]}
                pagination
                disableColumnMenu
                onRowClick={onRowClick}
                sx={{
                    border: 'none'
                }}
            />
        </Box>
        </Fade>
    );
}
