import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Divider,
    FormControlLabel, FormHelperText,
    Grid, IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm, useWatch} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import dayjs from "dayjs";
import {
    useGetIntakeMutation, useGetPreviousIntakeClassesMutation,
    useUpdateIntakeMutation
} from "../../../../api/courseIntakeApi";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {CheckCircle, CloudUpload, Delete, HourglassEmpty} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {
    useAddClassMutation,
    useAddMaterial2Mutation,
    useAddMaterialMutation,
    useDeleteAttachmentMutation,
    useEditClassMutation,
    useEditMaterial2Mutation,
    useEditMaterialMutation,
    useGetMaterialAttachmentMutation,
    useGetWeeksMutation,
    useRemoveClassMutation,
    useRemoveMaterial2Mutation,
    useRemoveMaterialMutation,
    useUploadMaterialAttachmentMutation
} from "../../../../api/courseClassApi";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import {DateTimePicker} from "@mui/x-date-pickers";
import {IOSSwitch} from "../../../../components/Switcher";
export default function ClassEdit({ courseId, intake }) {
    const { intakeTab } = useSelector((state) => state.intake);
    const { state: { row } } = intakeTab;


    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [addClass] = useAddClassMutation();
    const [editClass] = useEditClassMutation();
    const [removeClass] = useRemoveClassMutation();
    const [fetchCourseIntake] = useGetIntakeMutation();
    const [getWeeks] = useGetWeeksMutation();
    const [getPreviousClasses] = useGetPreviousIntakeClassesMutation();
    const [previousClass, setPreviousClass] = useState({});
    const [tutorList, setTutorList] = useState([]);
    const userRole = localStorage.getItem('userRole');
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()
    let classId = row?.classId;

    const refresh = ()  => {
        Promise.all([
            fetchCourseIntake({courseId, intake})
        ]).then(([ tutorData] ) => {
            if(Number(intake) > 1) {
                getPreviousClasses({
                    courseId,
                    intake: Number(intake) - 1
                }).then(({ data: { data } }) => {
                    setPreviousClass(data);
                });
            }
            setTutorList(tutorData.data.data.tutor);
        });
    }
    const onSubmit = async (data) => {

        setIsSubmitting(true);
        if(row){
            await editClass({
                ...data,
                courseId,
                intake,
            });
        }else {
             await addClass({
                ...data,
                courseId,
                intake,
            });
        }

        setIsSubmitting(false);
        dispatch(setIntakeTab(intakeTab.previousTab))
    }

    const handleRemove = () => {
        if (window.confirm("Are you sure to remove? All the related data will not be recovered.")) {
            setIsRemoving(true);
            removeClass({ courseId, intake, classId, weekId: getValues("weekId") }).then(() => {
                setIsRemoving(false);
                dispatch(setIntakeTab(intakeTab.previousTab))
            });
        }
    }
    useEffect(() => {
        refresh();
        if (row) {
            const { weekId, classId, assignedTutorId, name, description, type, startDate, endDate, venue, previousClassId, laptopRequired, examMaxPoint, examPassPercentage } = row;
            setValue('weekId', weekId);
            setValue('classId', classId);
            setValue('assignedTutorId', assignedTutorId);
            setValue('name', name);
            setValue('description', description);
            setValue('type', type);
            setValue('startDate', dayjs(startDate));
            setValue('endDate', dayjs(endDate));
            setValue('venue', venue);
            setValue('previousClassId', previousClassId);
            setValue('laptopRequired', laptopRequired);
            setValue('examMaxPoint', examMaxPoint);
            setValue('examPassPercentage', examPassPercentage);

        }else{
            setValue('type', 'OFFLINE');
            getWeeks({courseId, intake}).then((result) => {
                const weekData = result.data.data;
                setValue('weekId', weekData?.weeks[0].weekId);
            })


        }

    }, []);


    // Use watch to track the value of 'type'
    const typeValue = useWatch({
        control,
        name: 'type',
    });

    // Log the value whenever it changes
    useEffect(() => {
        console.log("Current value of 'type':", typeValue);
    }, [typeValue]);

    const isOnline = getValues("type") === 'ONLINE';
    return (
        <Fade in={true}>
            <Box sx={{p: 6, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                    <IconButton sx={{m: -1, mb: 2}} onClick={() => dispatch(setIntakeTab(intakeTab.previousTab))}>
                        <ArrowBackIosNewIcon/>
                    </IconButton>
                    {row &&
                        <Box>
                            <LoadingButton type="button" variant="outlined" color="inherit" sx={{mt: -1}}
                                           startIcon={<DeleteIcon/>} disabled={isSubmitting} loading={isRemoving}
                                           onClick={handleRemove}>
                                Remove {intakeTab.subRoute}
                            </LoadingButton>
                        </Box>

                    }
                </Stack>
                <Controller
                    name='type'
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={<IOSSwitch sx={{ m: 1 }} onChange={(e) => {
                                if(e.target.checked){
                                    onChange('ONLINE');
                                }else{
                                    onChange('OFFLINE');
                                }
                            }}  />}
                            label={`${value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase()} ${intakeTab.subRoute}`}
                            checked={value === 'ONLINE'}
                            disabled={!!row}
                        />
                    )}
                />

                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
                                Name *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='name'
                                type='text'
                                placeholder=''
                                {...register('name', {required: 'This is required field.'})}
                            />
                            {errors.name &&
                                <FormHelperText error>{errors.name.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>
                                Assigned Tutor
                            </label>
                            <Controller
                                name='assignedTutorId'
                                control={control}
                                render={({field, field: {onChange, value}}) => (
                                    <Select
                                        {...field}
                                        labelId='assignedTutorId-selection'
                                        id='assignedTutorId-selection'
                                        fullWidth
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        value={Number(value)}
                                        sx={{height: 40}}
                                    >
                                        <MenuItem key={'default'} value={0}>-- Please Select --</MenuItem>
                                        {tutorList?.map((tutor) => (
                                            <MenuItem key={tutor.id} value={tutor.id}>{tutor.nameEn}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Description *
                    </label>
                    <textarea
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='description'
                        rows={3}
                        placeholder=''
                        {...register('description', {required: 'This is required field.'})}

                    />
                    {errors.description &&
                        <FormHelperText error>{errors.description.message}</FormHelperText>}
                </div>

                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                Start Date *
                            </label>
                            <Controller
                                name='startDate'
                                control={control}
                                rules={{required: 'This is required field.'}}
                                render={({field}) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-input': {
                                                    height: 10,
                                                }
                                            }}
                                            {...field}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            {errors.startDate &&
                                <FormHelperText error>{errors.startDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                End Date *
                            </label>
                            <Controller
                                name='endDate'
                                control={control}
                                rules={{required: 'This is required field.'}}
                                render={({field}) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-input': {
                                                    height: 10,
                                                }
                                            }}
                                            {...field}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            {errors.endDate &&
                                <FormHelperText error>{errors.endDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                {!isOnline &&
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={9.5}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='venue'>
                                    Venue *
                                </label>
                                <input
                                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    id='venue'
                                    type='text'
                                    placeholder=''
                                    {...register('venue', { required: 'This is required field.' })}
                                />
                                {errors.venue && <FormHelperText error>{errors.venue.message}</FormHelperText>}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={2.5}>
                            <div className='mt-3 mb-4' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Controller
                                    name='laptopRequired'
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        onChange(e.target.checked);
                                                    }}
                                                    checked={!!value}
                                                />
                                            }
                                            label="Laptop Required"
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                    </Grid>
                }
                {Number(intake) > 1 &&
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>
                                Equals class of intake {intake -1}
                            </label>
                            <Controller
                                name='previousClassId'
                                control={control}
                                render={({field, field: {onChange, value}}) => (
                                    <Select
                                        {...field}
                                        labelId='previousClassId-selection'
                                        id='previousClassId-selection'
                                        fullWidth
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={Number(value)}
                                        sx={{height: 40}}
                                    >
                                        <MenuItem key={'default'} value={0}>-- Please Select --</MenuItem>
                                        {Object.keys(previousClass).map(id => {
                                            return <MenuItem key={id} value={id}>{previousClass[id]}</MenuItem>
                                        })}
                                    </Select>
                                )}
                            />

                        </div>
                    </Grid>
                </Grid>
                }

                <Stack alignItems='end'>
                    <LoadingButton type="button" variant="contained" color="primary" sx={{mt: 3}}
                                   startIcon={(row ? <SaveIcon/> : <AddIcon/>)} loading={isSubmitting}
                                   onClick={handleSubmit(onSubmit)}>
                        {row ? "Save Change" : "Create"}
                    </LoadingButton>
                </Stack>

            </Box>
        </Fade>
    );
}
