import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {Button, Chip, CircularProgress, Divider, IconButton, LinearProgress, Stack, TextField} from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import pdfIcon from './images/pdf-file.svg';
import docxIcon from './images/word-file.svg';
import xlsxIcon from './images/excel-file.svg';
import zipIcon from './images/zip-file.svg';
import AccredibleIcon from './images/accredible.png'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/system';
import { formatDistanceToNow, parseISO } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import {
    useGenerateAccredibleCertMutation,
    useGenerateAttendanceListMutation,
    useGenerateDeclarationMutation,
    useGenerateLeafletMutation,
    useGenerateProofOfCompletionMutation,
    useGenerateStudentGuidelineMutation,
    useGenerateTableOfCollectionMutation,
    useGetCourseDocumentsMutation,
    useRegenerateAccredibleCertMutation,
    useRegenerateCourseDocumentsMutation,
    useUpdateMetaDataMutation,
    useUploadTemplateMutation
} from "../../api/courseDocumentApi";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import StyledTableOne from "../../components/StyledTable/StyledTableOne";


const defaultColumnType = {
    resizable: false,
    filterable: false,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: true
};

const EditableCell = ({ value, onSave, onCancel }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(value);
    const [showEditButton, setShowEditButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleEditClick = () => {
        setEditValue(value)
        setIsEditing(true);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        onCancel();
    };

    const handleSaveClick = async () => {
        setIsLoading(true);
        await onSave(editValue);
        setIsLoading(false);
        setIsEditing(false);
    };

    const handleChange = (event) => {
        setEditValue(event.target.value);
    };


    document.addEventListener('keydown', (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
    }, true);

    return (
        <div
            className="editable-cell"
            style={{ position: 'relative', display: 'flex', alignItems: 'center', flexGrow: 1 }}
            onMouseEnter={() => setShowEditButton(true)}
            onMouseLeave={() => setShowEditButton(false)}
        >
            {isEditing ? (
                <>
                    <TextField
                        fullWidth
                        value={editValue}
                        onChange={handleChange}
                        variant="standard"
                        size="small"
                    />
                    {isLoading ? <CircularProgress  />
                     :
                        <>
                    <IconButton size="small" onClick={handleSaveClick}>
                        <CheckIcon />
                    </IconButton>
                        <IconButton size="small" onClick={handleCancelClick}>
                    <CloseIcon />
                    </IconButton>

                            </>
                    }

                </>
            ) : (
                <>
                    <div style={{ flexGrow: 1, whiteSpace: 'pre-line' }}>
                        {value}
                    </div>
                    {showEditButton && (
                        <IconButton
                            size="small"
                            onClick={handleEditClick}
                            style={{ position: 'absolute', right: 0 }}
                        >
                            <EditIcon />
                        </IconButton>
                    )}
                </>
            )}
        </div>
    );
};

export default function DocumentPage(props) {

    const { courseId, intake } = useParams();
    const [fetchDocumentList] = useGetCourseDocumentsMutation();
    const [regenerateDocument] = useRegenerateCourseDocumentsMutation();
    const [generateAccredibleCert] = useGenerateAccredibleCertMutation();
    const [regenerateAccredibleCert] = useRegenerateAccredibleCertMutation();
    const [generateProofOfCompletion] = useGenerateProofOfCompletionMutation();
    const [generateTableOfCollection] = useGenerateTableOfCollectionMutation();
    const [generateAttendanceList] = useGenerateAttendanceListMutation();
    const [generateStudentGuideline] = useGenerateStudentGuidelineMutation();
    const [generateDeclaration] = useGenerateDeclarationMutation();
    const [generateLeaflet] = useGenerateLeafletMutation();
    const [updateMetaData] = useUpdateMetaDataMutation();
    const [data, setData] = useState([]);
    const [accredibleLoading, setAccredibleLoading] = useState({});
    const [proofOfCompletionLoading, setProofOfCompletionLoading] = useState({});
    const [attendanceListLoading, setAttendanceListLoading] = useState(false);
    const [tableOfCollectionLoading, setTableOfCollectionLoading] = useState(false);
    const [studentGuidelineLoading, setStudentGuidelineLoading] = useState(false);
    const [declarationLoading, setDeclarationLoading] = useState(false);
    const [leafletLoading, setLeafletLoading] = useState(false);

    const updateNewMetaData = async (name, description) => {
        await updateMetaData({
            name,
            description
        });
        await fetchAndSetData();
    }
    const fetchAndSetData = async () => {
        const { data } = await fetchDocumentList({
            courseId,
            intake
        });
        setData(data.data);
    }
    useEffect(() => {
        fetchAndSetData();
        const intervalId = setInterval(fetchAndSetData, 2 * 60 * 1000); // 2 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, []);

    const generateNewProofOfCompletion = (enrolId, courseId, intake) => {
        setProofOfCompletionLoading({
            ...proofOfCompletionLoading,
            [enrolId]: true
        })
        generateProofOfCompletion({
            enrolId,
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => delete proofOfCompletionLoading[enrolId]);

        })
    }

    const generateNewLeaflet = (courseId, intake) => {
        setLeafletLoading(true);
        generateLeaflet({
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => setLeafletLoading(false));

        })
    }

    const generateNewStudentGuideline = (courseId, intake) => {
        setStudentGuidelineLoading(true);
        generateStudentGuideline({
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => setStudentGuidelineLoading(false));

        })
    }

    const generateNewDeclaration = (courseId, intake) => {
        setDeclarationLoading(true);
        generateDeclaration({
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => setDeclarationLoading(false));

        })
    }

    const generateNewAttendanceList = (courseId, intake) => {
        setAttendanceListLoading(true);
        generateAttendanceList({
            courseId,
            intake,
        }).then(() => {
            fetchAndSetData().then(() => setAttendanceListLoading(false));
        })
    }
    const generateNewTableOfCollection = (courseId, intake) => {
        setTableOfCollectionLoading(true);
        generateTableOfCollection({
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => setTableOfCollectionLoading(false));
        })
    }

    const generateNewAccredibleCert = (enrolId, courseId, intake) => {
        setAccredibleLoading({
            ...accredibleLoading,
            [enrolId]: true
        })
        generateAccredibleCert({
            enrolId,
            courseId,
            intake
        }).then(() => {
            fetchAndSetData().then(() => delete accredibleLoading[enrolId]);
        })
    }

    const handleUpload = async (template, file) => {

        if (file) {
            await uploadFile({
                file: file,
                template: template
            });
            alert("Template updated.")
            fetchAndSetData();
        }
    };

    const [uploadFile, { isLoading }] = useUploadTemplateMutation();

    const groupingColDef = {
        headerName: 'File Name',
    };

    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "",
            flex: 0.5,
            renderCell: (params) => {

                let icon, color;
                switch (params.row.extension) {
                    case 'pdf':
                        icon = pdfIcon;
                        break;
                    case 'docx':
                        icon = docxIcon;
                        break;
                    case 'xlsx':
                        icon = xlsxIcon;
                        break;
                    case 'zip':
                        icon = zipIcon;
                        break;
                    case 'accredible':
                        icon = AccredibleIcon;
                        break;
                    default:
                }
                return (
                    <Stack
                        spacing={1}
                    >
                        <Stack direction={"row"} spacing={1}>
                            {params.row.templateName ?
                                <>
                                    <input
                                        type="file"
                                        accept={`.${params.row.extension}`} // Specify accepted file extensions
                                        style={{display: 'none'}}
                                        id={`file-upload-${params.row.id}`}
                                        onChange={(event) => {
                                            const file = event.target.files[0];
                                            handleUpload(params.row.templateName, file);
                                        }}
                                    />
                                    <label htmlFor={`file-upload-${params.row.id}`}>
                                        <IconButton component="span" disabled={isLoading} sx={{ ml: -2}}>
                                            <img src={icon} alt={params.row.extension} style={{width: '30px'}}/>
                                        </IconButton>
                                    </label>
                                </>
                                :
                                <img src={icon} alt={params.row.extension} style={{width: '30px'}}/>
                            }
                        </Stack>
                        {params.row.templateName &&
                            <Box><a href={params.row.templateURL} target={"_blank"}>{params.row.templateURL ? "PREVIEW" : "N/A"}</a></Box>
                        }
                    </Stack>
                )
            }
        },
        {
            field: 'description',
            ...defaultColumnType,
            headerName: 'Description',
            wrapText: true,
            renderCell: (params) => {
                const handleSave = async (newValue) => {
                    await updateNewMetaData(params.row.name[0], newValue);
                };
                const handleCancel = () => {
                };
                if(params.row.name.length === 1){
                return (
                    <EditableCell
                        value={params.value}
                        onSave={handleSave}
                        onCancel={handleCancel}
                    />
                );
                }else{
                    return (
                        <div style={{flexGrow: 1, whiteSpace: 'pre-line'}}>
                            {params.row.description}
                        </div>
                    )
                }
            },
            flex: 2.9
        },
        {
            field: "status",
            ...defaultColumnType,
            headerName: "Last Modified Date",
            renderCell: (params) => {
                if(params.row.name.includes("Certification") || params.row.name.includes("Proof of Completion")) {
                    if (params.row.name.length === 1) {
                        return null;
                    }
                }
                return (params.row.checksum || (params.row.name.includes("Certification") && params.row.lastModifiedDate)) ?
                    <Chip icon={<DoneIcon/>} label={`${formatDistanceToNow(parseISO(moment(new Date(params.row.lastModifiedDate)).format("YYYY-MM-DD HH:mm:ss.SS")))} ago`} color={"success"} variant="outlined"/>
                    :
                    <Chip icon={<AutorenewIcon/>} label="Pending" variant="outlined"/>
            },
            flex: 1
        },
        {
            field: "action",
            ...defaultColumnType,
            headerName: "Archived",
            renderHeader: (params) => {
                return (
                    <>
                        {params.colDef.headerName}
                        <IconButton aria-label="refresh"  onClick={() => {}}>
                            <CloudDownloadIcon sx={{ color: '#000000'}}/>
                        </IconButton>
                    </>
                )
            },
            renderCell: (params) => {
                const enrolId  = params.row.description.substring(0, params.row.description.indexOf("\n"));
                if(params.row.name.includes("Certification")) {
                    if (params.row.name.length === 1) {
                        return null;
                    }
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewAccredibleCert(
                                            Number(enrolId),
                                            Number(courseId),
                                            Number(intake)
                                        )
                                }}
                                disabled={params.row.disabled}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                accredibleLoading[enrolId] ? <CircularProgress/> :
                                    params.row.disabled ?
                                        <DownloadIcon style={{filter: 'grayscale(100%)'}}/>
                                        :
                                        <DownloadIcon sx={{color: '#354147'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateAccredibleCert({enrolId});
                                    delete accredibleLoading[enrolId];
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                }else if(params.row.name.includes("Proof of Completion")) {
                    if (params.row.name.length === 1) {
                        return null;
                    }
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewProofOfCompletion(
                                            Number(enrolId),
                                            Number(courseId),
                                            Number(intake)
                                        )
                                }}
                                disabled={params.row.disabled}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                proofOfCompletionLoading[enrolId] ? <CircularProgress/> :
                                    params.row.disabled ?
                                        <DownloadIcon style={{filter: 'grayscale(100%)'}}/>
                                            :
                                        <DownloadIcon sx={{color: '#354147'}}/>


                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                    delete proofOfCompletionLoading[enrolId];
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else if (params.row.name.includes("Student Guideline")) {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewStudentGuideline(
                                            Number(courseId),
                                            Number(intake)
                                        )
                                }}
                                disabled={!params.row.templateURL}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                studentGuidelineLoading ? <CircularProgress/> :
                                    params.row.templateURL ?
                                        <DownloadIcon sx={{color: '#354147'}}/>
                                        :
                                        <DownloadIcon style={{filter: 'grayscale(100%)'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else if (params.row.name.includes("Leaflet")) {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewLeaflet(
                                            Number(courseId),
                                            Number(intake),
                                        )
                                }}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                leafletLoading ? <CircularProgress/> :
                                    < DownloadIcon sx={{color: '#354147'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else if (params.row.name.includes("Declaration")) {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewDeclaration(
                                            Number(courseId),
                                            Number(intake),
                                        )
                                }}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                declarationLoading ? <CircularProgress/> :
                                    < DownloadIcon sx={{color: '#354147'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else if (params.row.name.includes("Attendance List")) {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewAttendanceList(
                                            Number(courseId),
                                            Number(intake),
                                        )
                                }}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                attendanceListLoading ? <CircularProgress/> :
                                    < DownloadIcon sx={{color: '#354147'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else if (params.row.name.includes("Table of Collection")) {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    params.row.url ?
                                        window.open(params.row.url, '_blank') :
                                        generateNewTableOfCollection(
                                            Number(courseId),
                                            Number(intake)
                                        )
                                }}
                            >   {params.row.url ?
                                <VisibilityIcon sx={{color: '#354147'}}/>
                                :
                                tableOfCollectionLoading ? <CircularProgress/> :
                                    < DownloadIcon sx={{color: '#354147'}}/>

                            }
                            </IconButton>
                            {params.row.url &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                } else {
                    return (
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={0}
                        >
                            <IconButton
                                aria-label="download"
                                onClick={() => {
                                    window.open(params.row.url, '_blank');
                                }}
                                disabled={!params.row.checksum}
                            >   {params.row.checksum ?
                                <DownloadIcon sx={{color: '#354147'}}/>
                                :
                                <DownloadIcon style={{filter: 'grayscale(100%)'}}/>
                            }
                            </IconButton>
                            {params.row.checksum &&
                                <IconButton aria-label="refresh" onClick={() => {
                                    const index = data.findIndex(item => item.id === params.row.id);
                                    if (index !== -1) {
                                        const newData = [
                                            ...data.slice(0, index),
                                            {...data[index], url: null, lastModifiedDate: null, checksum: null},
                                            ...data.slice(index + 1)
                                        ];
                                        setData(newData);
                                    }
                                    regenerateDocument({fileId: params.row.fileId});
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            }
                        </Stack>
                    )
                }
            },
            flex: 1
        },
    ];
    console.log("data", data)
    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
                "brokerId": false
            },
            pagination: { paginationModel: { pageSize: 1000 } },
        }
    }
    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTableOne
                autoHeight
                treeData
                rows={data || []}
                columns={columns}
                initialState={initState}
                loading={data.length === 0}
                rowHeight={100}
                disableRowSelectionOnClick
                getTreeDataPath={(row) => row.name}
                groupingColDef={groupingColDef}
                sx={{ border: 'none'}}
                defaultGroupingExpansionDepth={1}
                pageSizeOptions={[1000]}
                disableColumnMenu
                pagination
                hideFooterPagination
                hideFooterSelectedRowCount
            />
        </Box>
    );
}
