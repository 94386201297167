import { mainRequest } from "./request";

export const getZoomParticipant = (meetingId) =>
mainRequest.post(`/report/zoom/report/participant`, {
    meetingId
})

export const getZoomReport = (meetingId) =>
mainRequest.post(`/report/zoom/report/`, {
    meetingId
})

export const getZoomPollsReport = (meetingId) =>
mainRequest.post(`/report/zoom/report/polls`, {
    meetingId
})
        