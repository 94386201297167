import React, {useEffect} from 'react';
import {
    Box,
    Button, Divider,
    FormControlLabel, FormHelperText,
    Grid,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm, useWatch} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import dayjs from "dayjs";
import {useUpdateIntakeMutation} from "../../../../api/courseIntakeApi";


export default function IntakeEdit({ courseId, intake, currentRecord, setIntakeExpanded}) {

    const [updateCourseIntake] = useUpdateIntakeMutation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()


    const onSubmit = (data) => {
        setIsSubmitting(true);
        const {
            quota,
            academicLevel,
            startDate,
            endDate,
            cutOffDate,
            price,
            discountedPrice,
            earlyDiscountEnable,
            earlyDate,
            earlyDiscount,
            earlyDiscountWithCEF,
            scheduleDescriptionChi: courseTimeDesc,
            scheduleDescriptionEng: courseTimeDescEng,
            venueChi: address,
            venueEng: addressEng,
        } = data;

        updateCourseIntake({
            courseId,
            intake,
            quota,
            academicLevel,
            startDate: dayjs(startDate).unix() * 1000,
            endDate: dayjs(endDate).unix() * 1000,
            cutOffDate: dayjs(cutOffDate).unix() * 1000,
            price,
            discountedPrice,
            earlyDiscountEnable,
            earlyDate,
            earlyDiscount,
            earlyDiscountWithCEF,
            courseTimeDesc,
            courseTimeDescEng,
            address,
            addressEng,
        }).unwrap()
            .then(() => {
                setIsSubmitting(false);
                setIntakeExpanded(false);
            })
            .catch((error) => {
                setIsSubmitting(false);
                setIntakeExpanded(false);
            });

    }

    useEffect(() => {
        if (currentRecord) {
            setValue('intakeNo', currentRecord.intake);
            setValue('quota', currentRecord.quota);
            setValue('academicLevel', currentRecord.academicLevel);
            setValue('startDate', dayjs(currentRecord.startDate));
            setValue('endDate', dayjs(currentRecord.endDate));
            setValue('cutOffDate', dayjs(currentRecord.cutOffDate));
            setValue('price', currentRecord.price);
            setValue('discountedPrice', currentRecord.discountedPrice);
            setValue('earlyDiscountEnable', currentRecord.earlyDiscountEnable);
            setValue('earlyDate', dayjs(currentRecord.earlyDate));
            setValue('earlyDiscount', currentRecord.earlyDiscount);
            setValue('earlyDiscountWithCEF', currentRecord.earlyDiscountWithCEF);
            setValue('scheduleDescriptionChi', currentRecord.courseTimeDesc);
            setValue('scheduleDescriptionEng', currentRecord.courseTimeDescEng);
            setValue('venueChi', currentRecord.address);
            setValue('venueEng', currentRecord.addressEng);
        }

    }, [currentRecord]);

    useWatch({
        control,
        name: 'earlyDiscountEnable',
    });

    const isEarlyDiscountEnabled = getValues("earlyDiscountEnable");
    return (
        <Fade in={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Intake No. *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='intakeNo'
                                type='text'
                                placeholder='1'
                                disabled
                                {...register('intakeNo', { required: 'This is required field.' })}
                            />
                            {errors.intakeNo &&
                                <FormHelperText error>{errors.intakeNo.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Quota *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='quota'
                                type='text'
                                placeholder='50'
                                {...register('quota', { required: 'This is required field.' })}
                            />
                            {errors.quota &&
                                <FormHelperText error>{errors.quota.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-6'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>
                                QF Level *
                            </label>
                            <Controller
                                name='academicLevel'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field, field: { onChange, value} }) => (
                                    <Select
                                        {...field}
                                        labelId='academicLevel-level-selection'
                                        id='academicLevel-level-selection'
                                        fullWidth
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        value={Number(value)}
                                        sx={{ height: 40 }}
                                    >
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={0}>0</MenuItem>
                                    </Select>
                                )}
                            />
                            {errors.academicLevel &&
                                <FormHelperText error>{errors.academicLevel.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                Start Date *
                            </label>
                            <Controller
                                name='startDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                            />
                            {errors.startDate &&
                                <FormHelperText error>{errors.startDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                End Date *
                            </label>
                            <Controller
                                name='endDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                                />
                                {errors.endDate &&
                                    <FormHelperText error>{errors.endDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                Cut-off Date *
                            </label>
                            <Controller
                                name='cutOffDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                            />
                            {errors.cutOffDate &&
                                <FormHelperText error>{errors.cutOffDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Price (HKD) *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='price'
                                type='text'
                                placeholder='13800'
                                {...register('price', { required: 'This is required field.' })}
                            />
                            {errors.price &&
                                <FormHelperText error>{errors.price.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Discounted Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='discountedPrice'
                                type='text'
                                placeholder='3200'
                                {...register('discountedPrice')}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Controller
                    key={"earlyDiscountEnable"}
                    name={"earlyDiscountEnable"}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color='success'
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                                />
                            }
                            label={"Enable Early Discount"}
                            sx={{mb: 1, '& .MuiTypography-root': {fontSize: 14}}}
                        />
                    )}
                />
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDate'>
                                Cut-off Date
                            </label>
                            <Controller
                                name='earlyDate'
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disabled={!isEarlyDiscountEnabled}
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-input': {
                                                    height: 10,
                                                }
                                            }}
                                            {...field}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDiscount'>
                                Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='earlyDiscount'
                                type='text'
                                placeholder='12000'
                                disabled={!isEarlyDiscountEnabled}
                                {...register('earlyDiscount' )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDiscountWithCEF'>
                                Discounted Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='earlyDiscountWithCEF'
                                type='text'
                                placeholder='3000'
                                disabled={!isEarlyDiscountEnabled}
                                {...register('earlyDiscountWithCEF' )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Schedule Description (Chinese)
                    </label>
                    <textarea
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='scheduleDescriptionChi'
                        rows={3}
                        placeholder='每逢&#10;星期二 : 19:00 - 22:00&#10;星期四 : 19:00 - 22:00'
                        {...register('scheduleDescriptionChi')}

                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Schedule Description (English)
                    </label>
                    <textarea
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='scheduleDescriptionEng'
                        rows={3}
                        placeholder='Every&#10;Tue :   19:00 - 22:00&#10;Thur : 19:00 - 22:00'
                        {...register('scheduleDescriptionEng')}

                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Venue (Chinese)
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='venueChi'
                        type='text'
                        placeholder='北角炮台山英皇道250號北角城中心12樓1201室 (炮台山港鐵站B出口)'
                        {...register('venueChi')}
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Venue (English)
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='venueEng'
                        type='text'
                        placeholder='Rm1201, 12/F, Fortress Tower, 250 King’s Road, North Point, Hong Kong (Exit B, Fortress Hill MTR Station)'
                        {...register('venueEng')}
                    />
                </div>
                <Stack alignItems='end'>
                    <Button type="submit" variant="contained" color="primary" sx={{mt: 3}} disabled={isSubmitting}>
                        Save Change
                    </Button>
                </Stack>

            </Box>
            </form>
        </Fade>
    );
}
