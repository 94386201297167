import { mainRequest } from "./request";

export const createQuiz = (assignmentId, timeLimit, screenLockRequired, questions, answers) =>
    mainRequest.post('/quiz/create', {
        assignmentId,
        timeLimit,
        screenLockRequired,
        questions,
        answers
    })

export const updateQuiz = (quizId, timeLimit, screenLockRequired, questions, answers) =>
mainRequest.post('/quiz/update', {
        quizId,
        timeLimit,
        screenLockRequired,
        questions,
        answers
})

//// Full Quiz = with questions and answers
export const getQuizDetail = (quizId) =>
mainRequest.post('/quiz/full/getQuiz', {
    quizId
})

export const getQuizByAssignmentId = (assignmentId) =>
mainRequest.post('/quiz/getByAssignment', {
    assignmentId
})

export const getQuizSubmissionList = (courseId, intake) =>
mainRequest.post('/submission/getSubmissionList', {
    courseId,
    intake,
    type: 1
})

export const getQuizAnswerList = (quizId) =>
mainRequest.post('/quiz/getStudentAnswers', {
    quizId
})
