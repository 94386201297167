import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, Divider,
    FormControlLabel, FormHelperText,
    Grid, IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import dayjs from "dayjs";
import {useUpdateIntakeMutation} from "../../../../api/courseIntakeApi";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {CheckCircle, CloudUpload, Delete, HourglassEmpty} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {
    useAddMaterialMutation,
    useDeleteAttachmentMutation,
    useEditMaterialMutation,
    useGetMaterialAttachmentMutation, useGetWeeksMutation, useRemoveMaterialMutation,
    useUploadMaterialAttachmentMutation
} from "../../../../api/courseClassApi";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import AddIcon from '@mui/icons-material/Add';
import {getWeeks} from "../../../../utils/request/course";
import DeleteIcon from "@mui/icons-material/Delete";
import {DateTimePicker} from "@mui/x-date-pickers";
export default function AssignmentEdit({ courseId, intake }) {
    const { intakeTab } = useSelector((state) => state.intake);
    const { state: { row, classes: defaultClasses, materials: defaultMaterials } } = intakeTab;

    const [classes, setClasses] = useState(defaultClasses);
    const [materials, setMaterials] = useState(defaultMaterials);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [removedFileIds, setRemovedFileIds] = useState([]);
    const [addMaterial] = useAddMaterialMutation();
    const [editMaterial] = useEditMaterialMutation();
    const [removeMaterial] = useRemoveMaterialMutation();
    const [uploadMaterialAttachment] = useUploadMaterialAttachmentMutation();
    const [getMaterialAttachments] = useGetMaterialAttachmentMutation();
    const [deleteAttachment] = useDeleteAttachmentMutation();
    const [getWeeks] = useGetWeeksMutation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()
    let assignmentId = row?.assignmentId;

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setNewFiles([...newFiles, file]);
        }
    };

    const handleRemoveUploadedFile = (fileId) => {
        setUploadedFiles(uploadedFiles.filter(file => file.id !== fileId));
        setRemovedFileIds([...removedFileIds, fileId]);
    };

    const handleRemoveNewFile = (fileName) => {
        setNewFiles(newFiles.filter(file => file.name !== fileName));
    };



    const onSubmit = async (data) => {

        setIsSubmitting(true);
        let createResult = null;
        if(row){
            await editMaterial({
                ...data,
                courseId,
                intake,
            });
        }else {
            createResult = await addMaterial({
                ...data,
                courseId,
                intake,
                type: intakeTab.subRoute.toLocaleUpperCase()
            });
        }

        if (newFiles.length > 0) {
            let addPromises = [];
            for(const file of newFiles){
                addPromises =  [
                    ...addPromises,
                    uploadMaterialAttachment({
                        assignmentId: assignmentId || createResult.data.data.id,
                        file,
                    })
                ]
            }
            await Promise.all(addPromises);
        }

        if(removedFileIds.length > 0){
            let deletePromise = []
            for(const fileId of removedFileIds){
                 deletePromise = [
                     ...deletePromise,
                     deleteAttachment({ id: fileId })
                 ]
            }
            await Promise.all(deletePromise);
        }
        setIsSubmitting(false);
        dispatch(setIntakeTab(intakeTab.previousTab))
    }

    const handleRemove = () => {
        if (window.confirm("Are you sure to remove? All the related data will not be recovered.")) {
            setIsRemoving(true);
            removeMaterial({ courseId, intake, assignmentId, weekId: getValues("weekId") }).then(() => {
                setIsRemoving(false);
                dispatch(setIntakeTab(intakeTab.previousTab))
            });
        }
    }
    useEffect(() => {

        if (row) {
            const { weekId, assignmentId, associateClassId, name, description, type, startDate, endDate, maxPoint, passPercentage } = row;
            setValue('weekId', weekId);
            setValue('assignmentId', assignmentId);
            setValue('associateClassId', associateClassId);
            setValue('name', name);
            setValue('description', description);
            setValue('type', type);
            setValue('startDate', dayjs(startDate));
            setValue('endDate', dayjs(endDate));
            setValue('maxPoint', maxPoint);
            setValue('passPercentage', passPercentage);

            getMaterialAttachments({ assignmentId}).then((result) => {
                const files = result.data.data;
                setUploadedFiles(files.map(file => ({ id: file.documentId, name: file.documentName })));
            });
        }else{

            getWeeks({courseId, intake}).then((result) => {
                const weekData = result.data.data;
                const flattenedClasses = weekData?.weeks?.flatMap(week => week.classes)
                    .sort((a, b) => a.startDate - b.startDate);

                const flattenedMaterial = weekData?.weeks?.flatMap(week => week.assignments)
                    .sort((a, b) => a.startDate - b.startDate);
                setClasses(flattenedClasses);
                setMaterials(flattenedMaterial);
                setValue('weekId', weekData?.weeks[0].weekId);
            })

        }

    }, []);


    return (
        <Fade in={true}>
                <Box sx={{p: 6, borderRadius: 5, backgroundColor: 'white'}}>
                    <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                    <IconButton sx={{m: -1, mb: 2}} onClick={() => dispatch(setIntakeTab(intakeTab.previousTab))}>
                        <ArrowBackIosNewIcon/>
                    </IconButton>
                        {row &&
                            <Box>
                        <LoadingButton type="button" variant="outlined" color="inherit" sx={{mt: -1}} startIcon={<DeleteIcon /> } disabled={isSubmitting} loading={isRemoving} onClick={handleRemove}>
                            Remove {intakeTab.subRoute}
                        </LoadingButton>
                            </Box>

                        }
                    </Stack>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
                                     Name *
                                </label>
                                <input
                                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    id='name'
                                    type='text'
                                    placeholder=''
                                    {...register('name', {required: 'This is required field.'})}
                                />
                                {errors.name &&
                                    <FormHelperText error>{errors.name.message}</FormHelperText>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2'>
                                    Associated Class *
                                </label>
                                <Controller
                                    name='associateClassId'
                                    control={control}
                                    rules={{required: 'This is required field.'}}
                                    render={({field, field: {onChange, value}}) => (
                                        <Select
                                            {...field}
                                            labelId='associateClassId-selection'
                                            id='associateClassId-selection'
                                            fullWidth
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                            value={Number(value)}
                                            sx={{height: 40}}
                                        >
                                            {classes?.map((c) => (
                                                <MenuItem key={c.classId} value={c.classId}>{c.name}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.associateClassId &&
                                    <FormHelperText error>{errors.associateClassId.message}</FormHelperText>}
                            </div>
                        </Grid>
                    </Grid>
                    <div className='mb-4'>
                        <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                            Description *
                        </label>
                        <textarea
                            className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            id='description'
                            rows={3}
                            placeholder=''
                            {...register('description', {required: 'This is required field.'})}

                        />
                        {errors.description &&
                            <FormHelperText error>{errors.description.message}</FormHelperText>}
                    </div>
                    <div className='mb-4'>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} md={6}>

                                <div className='mb-4'>
                                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                        Start Date *
                                    </label>
                                    <Controller
                                        name='startDate'
                                        control={control}
                                        rules={{required: 'This is required field.'}}
                                        render={({field}) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiInputBase-input': {
                                                            height: 10,
                                                        }
                                                    }}
                                                    {...field}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                    {errors.startDate &&
                                        <FormHelperText error>{errors.startDate.message}</FormHelperText>}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <div className='mb-4'>
                                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                        End Date *
                                    </label>
                                    <Controller
                                        name='endDate'
                                        control={control}
                                        rules={{required: 'This is required field.'}}
                                        render={({field}) => (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiInputBase-input': {
                                                            height: 10,
                                                        }
                                                    }}
                                                    {...field}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                    {errors.endDate &&
                                        <FormHelperText error>{errors.endDate.message}</FormHelperText>}
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='uploadedFiles'>
                                    Files *
                                </label>
                                <div id="uploadedFiles">
                                    {uploadedFiles.map(file => (
                                        <div key={file.id} className="flex items-center mb-2">
                                            <CheckCircle color="success" />
                                            <span className="mx-2">{file.name}</span>
                                            <IconButton onClick={() => handleRemoveUploadedFile(file.id)}>
                                                <CloseIcon color="error" fontSize={"small"} />
                                            </IconButton>
                                        </div>
                                    ))}
                                    {newFiles.map(file => (
                                        <div key={file.name} className="flex items-center mb-2">
                                            <PendingIcon color="action" />
                                            <span className="mx-2">{file.name}</span>
                                            <IconButton onClick={() => handleRemoveNewFile(file.name)}>
                                                <CloseIcon color="error" fontSize={"small"} />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                                <Button variant="outlined" component="label" startIcon={<CloudUpload />}>
                                    Upload File
                                    <input type="file" hidden onChange={handleFileSelect} />
                                </Button>
                                {errors.files && <FormHelperText error>{errors.files.message}</FormHelperText>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='maxPoint'>
                                    Max Point *
                                </label>
                                <input
                                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    id='maxPoint'
                                    type='text'
                                    placeholder='100'
                                    {...register('maxPoint', { required: 'This is required field.' })}
                                />
                                {errors.maxPoint && <FormHelperText error>{errors.maxPoint.message}</FormHelperText>}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className='mb-4'>
                                <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='passPercentage'>
                                    Passing (%) *
                                </label>
                                <input
                                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    id='passPercentage'
                                    type='text'
                                    placeholder='50'
                                    {...register('passPercentage', { required: 'This is required field.' })}
                                />
                                {errors.passPercentage && <FormHelperText error>{errors.passPercentage.message}</FormHelperText>}
                            </div>
                        </Grid>
                    </Grid>
                    <Stack alignItems='end'>
                        <LoadingButton type="button" variant="contained" color="primary" sx={{mt: 3}} startIcon={(row ? <SaveIcon /> : <AddIcon />)} loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
                            {row ? "Save Change": "Create"}
                        </LoadingButton>
                    </Stack>

                </Box>
        </Fade>
    );
}
