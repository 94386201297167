import React, { useState, useEffect } from 'react'
import CreateEditForm from '../../CreateEditForm/CreateEditForm'
import Loading from '../../../Loading/Loading'

import style from './EditIntake.module.scss'

const EditIntake = () => {
    return <CreateEditForm isEditIntake={true} backUrl="/intake"></CreateEditForm>
}

export default EditIntake
