import PollDetail from "./../../../PollInventory/PollDetail";
import {Breadcrumbs} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../../../api/courseIntakeApi";
import Box from "@mui/material/Box";

const EditMeetingPolls = () => {
    const navigate = useNavigate();
    const { pollId, courseId, intake, meetingId } = useParams();
    const { data } = useGetGeneralCourseQuery({ courseId });
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        onClick={() => navigate("/course")}
                        label="Course"
                        icon={<HomeIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb onClick={() => navigate("/intake")} label="Intake" />
                    <StyledBreadcrumb onClick={() => navigate(`/intake/${courseId}/${intake}`, {
                        state: { tab: 'Class Detail'}
                    })} label={data?.data ? `${data?.data?.prefixKey}-${intake}` : '...'} />
                    <StyledBreadcrumb label="Poll" />
                    <StyledBreadcrumb onClick={() => navigate(`/intake/${courseId}/${intake}/polls/${meetingId}`)} label={meetingId} />
                    <StyledBreadcrumb label={pollId === 'Create' ? 'Create' : 'Edit'} />
                </Breadcrumbs>
            </Box>
        <PollDetail />
        </Box>
    )
}

export default EditMeetingPolls;
