import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const submissionApi = createApi({
    baseQuery,
    reducerPath: "submissionApi",
    endpoints: builder => ({
        getSubmission: builder.mutation({
            query: (body) => ({
                url: "/submission/getSubmission",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useGetSubmissionMutation,
} = submissionApi;
