import { mainRequest } from './request'

export const getEnrollmentList = (pageNum, pageSize) =>
    mainRequest.post('/enroll/getList', {
        pageNum,
        pageSize,
    })

export const getEnrollmentRecord = (studentId, courseId, intake) =>
    mainRequest.post('/enroll/getEnrolmentRecord', {
        studentId,
        courseId,
        intake,
    })

export const getAcademicProveList = (id) =>
    mainRequest.post('/student/getAcademicProveList', {
        id,
    })

// should be useless
export const approveAcademicLevel = (studentId, documentId, academicLevel) =>
    mainRequest.post('/student/approveAcademicLevel', {
        studentId,
        documentId,
        academicLevel,
    })

// should use this to update academic level
export const updateAcademicLevel = (id, academicLevel) =>
    mainRequest.post('/student/update', {
        id,
        academicLevel,
    })

export const updateEnrollmentRecord = (studentId, courseId, status, intake) =>
    mainRequest.post('/enroll/update', {
        studentId,
        courseId,
        status,
        intake,
    })

export const getPaymentSlipList = (studentId, courseId, intake) =>
    mainRequest.post('/enroll/getPaymentSlipList', {
        studentId,
        courseId,
        intake,
    })

export const getPaymentRecord = (studentId, courseId, intake) =>
    mainRequest.post('/enroll/getPaymentRecord', {
        studentId,
        courseId,
        intake,
    })

// body example
// {
//     "paymentStatus": "UNPAID",
//     "paymentId": 455,
//     "transferDate": "2022-01-01",
//     "transferredAmt": 100,
//     "courseId" : 62,
//     "intake" : 47,
//     "studentId" : 6
// }
export const updatePaymentRecord = (body) =>
    mainRequest.post('/enroll/updatePaymentRecord', {
        paymentStatus: body.paymentStatus,
        paymentId: body.paymentId,
        transferDate: body.transferDate,
        transferredAmt: body.transferredAmt,
        courseId: body.courseId,
        intake: body.intake,
        studentId: body.studentId,
    })

export const getEnrollmentSupportDocumentList = (studentId, courseId, intake) =>
    mainRequest.post('/enroll/getSupportDocumentList', {
        studentId,
        courseId,
        intake,
    })

export const getEnrollmentRecordByIntake = (courseId, intake) =>
    mainRequest.post('/enroll/getByCourse', {
        courseId,
        intake,
    })
