import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {LOCAL_STORAGE_ADMIN} from "../utils/constants";

const baseQuery = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: process.env.REACT_APP_MAIN_API_URL,
        prepareHeaders: async (headers, { getState }) => {
            const accessToken = localStorage.getItem('token');
            if (accessToken) {
                headers.set("Authorization", accessToken);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const error = result.error.data.error;
        if (error === "Unauthorized") {
            // Clear the token from localStorage
            localStorage.clear()
            // Optionally, you can also dispatch a logout action or redirect the user
        }
    }

    return result;
};


const authQuery = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: process.env.REACT_APP_UAA_API_URL,
        prepareHeaders: async (headers, { getState }) => {
            const accessToken = localStorage.getItem('token');
            if (accessToken) {
                headers.set("Authorization", accessToken);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const error = result.error.data.error;
        if (error === "Unauthorized") {
            // Clear the token from localStorage
            localStorage.clear()
            // Optionally, you can also dispatch a logout action or redirect the user
        }
    }

    return result;
};


export { authQuery };
export default baseQuery;
