import {Breadcrumbs, Button, Icon, Stack} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import Admin from "./Admin";
export default function AdminInitPage(props) {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <StyledBreadcrumb
                            label="Add Admin"
                            icon={<Icon fontSize="small" >person_add_alt1</Icon>}
                        />
                    </Breadcrumbs>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button color="inherit" startIcon={<AddCircleOutlineIcon />} sx={{ p: 0, pr: 1}} onClick={() => navigate("/admin/create")}>
                            NEW
                        </Button>
                    </Box>
                </Stack>
            </Box>
            <Admin {...props} />
        </Box>
    )
}
