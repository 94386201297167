import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    IconButton,
} from '@mui/material'
import { getCourseIntakeList } from '../../utils/request/course'
import Loading from '../Loading/Loading'
import style from './Assignment.module.scss'
import moment from 'moment'
import TablePage from '../UI/TablePage'

const Assignment = () => {

    const [intakeList, setIntakeList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(intakeList.length)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const init = async () => {
        setLoading(true)
        try {
            let courseIntakeList = await getCourseIntakeList(0, 20)
            console.log('Get course intake list successfuly : ', courseIntakeList)
            setPage(0)
            setTotalNumberOfRecords(courseIntakeList.data.data.totalElements)
            setIntakeList(courseIntakeList.data.data.content)
        } catch (error) {
            console.log('Get course intake list error : ', error)
        } finally {
            setLoading(false)
        }
    }

    const onTableRowClick = (record) => {
        // console.log(record)
        navigate(`/assignment/${record.courseId}/${record.intake}`)
    }
    const onPageChange = async (e, pageNumber) => {
        let newNumberOfRecord = Math.min(rowsPerPage * (pageNumber + 1), totalNumberOfRecords)
        if (newNumberOfRecord > intakeList.length) {
            setLoading(true)
            try {
                // 20 is the LCM of 5, 10, 20
                // get 20 records each time to simplify the logic
                let result = await getCourseIntakeList(Math.floor(intakeList.length / 20), 20)
                console.log('Get course intakeList successfully : ', result)
                setIntakeList(intakeList.concat(result.data.data.content))
                setPage(pageNumber)
            } catch (error) {
                console.log('Get course intakeList error : ', error)
            } finally {
                setLoading(false)
            }
        } else {
            setPage(pageNumber)
        }
    }
    const onRowsPerPageChange = (e) => {
        setPage(0)
        setRowsPerPage(parseInt(e.target.value))
    }

    useEffect( () => {
        init()
    }, [])

    return (
        <TablePage title='Intakes'>
            <TableContainer className="tableContainer">
                <Table>
                    <TableHead className="tableHead">
                        <TableRow>
                            <TableCell className="theadCell">START DATE</TableCell>
                            <TableCell className="theadCell">END DATE</TableCell>
                            <TableCell className="theadCell">COURSE</TableCell>
                            <TableCell className="theadCell">INTAKE</TableCell>
                            <TableCell className="theadCell">NO. STUDENT</TableCell>
                            <TableCell className="theadCell">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {intakeList &&
                            intakeList
                                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                .map((record, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className="tableRow"
                                            onClick={() => onTableRowClick(record)}
                                        >
                                            <TableCell className="tableCell">
                                                {moment(record.startDate).format('D MMM YYYY')}
                                            </TableCell>
                                            <TableCell className="tableCell">
                                                {moment(record.endDate).format('D MMM YYYY')}
                                            </TableCell>
                                            <TableCell className="tableCell">{record.name}</TableCell>
                                            <TableCell className="tableCell">{record.intake}</TableCell>
                                            <TableCell className="tableCell">
                                                {record.numOfStudent}
                                            </TableCell>
                                            <TableCell className="tableCell">
                                                <div>{record.status}</div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={totalNumberOfRecords}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[10, 20]}
                                onPageChange={onPageChange}
                                page={page}
                                onRowsPerPageChange={onRowsPerPageChange}
                            ></TablePagination>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </TablePage>


    )
}

export default Assignment
