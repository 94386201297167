
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    Stack,
    IconButton,
} from '@mui/material';
import Loading from '../Loading/Loading'
import style from './Assignment.module.scss'
import moment from 'moment'
import TablePage from '../UI/TablePage'
import { getSubmissionList, getFullSubmissionRecord, postSubmitEvaluation, postMarkSubmission} from '../../utils/request/assignment';
import backArrow from '../../image/back_arrow.svg';
import {Modal, ModalInner} from '../UI/Modal';
import { downloadDocument } from '../../utils/request/utility';


const AssignmentList = () => {

    const navigate = useNavigate();
    const param = useParams();

    const [submissionList, setSubmissionList] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(submissionList.length)
    const [loading, setLoading] = useState(false)

    const [currentSubmission, setCurrentSubmission] = useState([]);
    const [openSubmission, setOpenSubmission] = useState(false);
    const [file, setFile] = useState('');
    const [submissionScore, setSubmissionScore] = useState(0);
    const [submissionFeedback, setSubmissionFeedback] = useState('');


    const onBackToAssignment = () => {
        navigate('/assignment');
    }

    const onTableRowClick = (record) => {
        console.log(record)
        onGetSubmissionRecord(record);
    }

    const onPageChange = async (e, pageNumber) => {

    }

    const onRowsPerPageChange = (e) => {
        setPage(0)
        setRowsPerPage(parseInt(e.target.value))
    }

    useEffect( () => {
        init().then(() => setOpenSubmission(false));
    }, [])

    useEffect(()=>{
        if(currentSubmission){
            //reset
            console.log('currentSubmission', currentSubmission)
            setSubmissionScore(currentSubmission.score);
            setSubmissionFeedback(currentSubmission.comment);
            setFile('');
        }
    },[currentSubmission])

    // Submission record
    const onGetSubmissionRecord = async (record) =>{
        try {
            let result = await getFullSubmissionRecord(
                record.studentId,
                record.courseId,
                record.intake,
                record.submissionId
            )
            setCurrentSubmission(result.data.data)
            console.log('get submission record success', result)
        }catch(error){
            console.log('get submission record error', error)
        }
        setOpenSubmission(true)
    }

    const onClickCloseSubmission = () =>{
        setOpenSubmission(false)
    }


    const onSubmitMarking = () =>{
        if (file){
            onSubmitEvaluation();
        } else {
            onSubmitMarkingOnly();
        }
    }

    const onSubmitEvaluation = async () =>{
        // upload evaluation
        try {
            let result = await postSubmitEvaluation(
                currentSubmission.studentId,
                currentSubmission.courseId,
                currentSubmission.intake,
                currentSubmission.assignmentId,
                currentSubmission.submissionId,
                file
            )
            console.log('submit evaluation success', result);
            onSubmitMarkingOnly();
        } catch(error){
            console.log('submit evaluation error', error)
        }

    }

    const onSubmitMarkingOnly = async () =>{
        // submit marking
        try {
            let result = await postMarkSubmission(
                currentSubmission.studentId,
                currentSubmission.courseId,
                currentSubmission.intake,
                currentSubmission.submissionId,
                submissionScore,
                submissionFeedback
            )
            console.log('update marking success', result)
            setOpenSubmission(false)
        } catch(error){
            console.log('submit marking error', error)
        }
    }

    const downloadDocumentToLocal = async (doc) => {
        try {
            let file = await downloadDocument(doc.path)
            const e = document.createElement('a')
            e.href = URL.createObjectURL(file.data)
            e.download = doc.documentName
            e.click()
        } catch (error) {
            console.log('Download document error : ', error)
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };


    const init = async () => {
        try {
            let result = await getSubmissionList(param.courseId, param.intake)
            //// sort by student ID

            let data = result.data.data;
            const sortedData = [...data].sort((a, b) => a.studentId - b.studentId);
            setTotalNumberOfRecords(sortedData.length);
            setSubmissionList(sortedData);
            console.log('Get getSubmissionList success : ', result)

        } catch (error) {
            console.log('Get getSubmissionList error : ', error)
        }
    }

    return (

        <>
        {
            openSubmission?
            <Modal>
                <ModalInner>
                <div className="uM_title">
                                Evaluation
                            </div>
                            <div className="uM_body">
                                <div className='modal-info-wrapper'>
                                    <div className='modal-info'>
                                        <div>StudentId</div>
                                        <div>{currentSubmission.studentId}</div>
                                    </div>
                                    {/* <div className='modal-info'>
                                        <div>Submission Date</div>
                                        <div>{currentSubmission.studentId}</div>
                                    </div> */}
                                    <div className='modal-info'>
                                        <div>Attachment</div>
                                        <div><Button variant="outlined" onClick={()=>downloadDocumentToLocal(currentSubmission.submissionDoc)}>Download</Button></div>
                                    </div>
                                </div>
                                <div className='modal-input-wrapper'>
                                <TextField
                                className={style.name}
                                label="Score"
                                placeholder="0"
                                value={submissionScore}
                                onChange={(e) =>
                                    setSubmissionScore(e.target.value)
                                }
                            />
                                </div>
                                <div className='modal-input-wrapper full'>
                                <TextField
                                className={style.name}
                                label="Comment"
                                placeholder="Leave your comment here"
                                value={submissionFeedback}
                                onChange={(e) =>
                                    setSubmissionFeedback(e.target.value)
                                }
                            />
                                </div>
                                <div className='modal-info'>
                                        <div>Upload feedback</div>
                                        <input id="evaluationDoc" type="file" onChange={handleFileChange} title=" "/>
                                    </div>

                            </div>
                            <div className="uM_action">
                            <Stack spacing={2} direction="row" justifyContent="flex-end">
                            <Button onClick={onClickCloseSubmission} variant="text">Cancel</Button>
                                <Button onClick={onSubmitMarking} variant="contained" color="primary">Submit</Button>
                            </Stack>
                            </div>
                </ModalInner>
            </Modal>: null
        }
            <div className="backContainer">
                <img src={backArrow} />
                <Button onClick={onBackToAssignment} variant="Text">Back to List</Button>
            </div>

            <TablePage title="Assignment Summary">
                <TableContainer className="tableContainer">
                    <Table>
                        <TableHead className="tableHead">
                            <TableRow>
                                <TableCell className="theadCell">ID</TableCell>
                                <TableCell className="theadCell">Assignment</TableCell>
                                <TableCell className="theadCell">Student</TableCell>
                                <TableCell className="theadCell">Score</TableCell>
                                <TableCell className="theadCell">Status</TableCell>
                                <TableCell className="theadCell">Progress</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {submissionList &&
                                submissionList
                                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                    .map((record, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                className="tableRow"
                                                onClick={() => onTableRowClick(record)}
                                            >
                                                <TableCell className="tableCell">{record.submissionId}</TableCell>
                                                <TableCell className="tableCell">
                                                    <div>{record.assignmentName}</div>
                                                    <div>{record.assignmentId}</div>
                                                </TableCell>
                                                <TableCell className="tableCell">
                                                    {record.studentName}
                                                    {record.studentId}
                                                </TableCell>
                                                <TableCell className="tableCell">{record.score}</TableCell>
                                                <TableCell className="tableCell">
                                                    <div>
                                                        {record.status === 1?'Latest':'Depre'}
                                                    </div>
                                                </TableCell>
                                                <TableCell className="tableCell">
                                                    <div>
                                                        {record.progress === 1?'Marked':'-'}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={totalNumberOfRecords}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[10, 20]}
                                    onPageChange={onPageChange}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                ></TablePagination>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </TablePage>
        </>
    )
}

export default AssignmentList;
