import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useDeletePollInventoryMutation,
    useLazyGetPollInventoryDetailQuery,
    usePollInventoryDetailMutation
} from "../../api/courseGeneralApi";
import {
    Box,
    Breadcrumbs,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Button,
    IconButton,
    FormHelperText
} from '@mui/material';
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import {useDeletePollMutation, useLazyGetPollQuery, useSavePollMutation} from "../../api/courseIntakeApi";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../redux/slices/IntakeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export default function PollDetail(props) {

    const { intakeTab } = useSelector((state) => state.intake);
    const { courseId: courseId1, intake: intake1, pollId: pollId1, meetingId: meetingId1 } = useParams();
    const { courseId2, intake2 } = props;
    const { state } = intakeTab;

    let courseId = courseId2 || courseId1;
    let intake = intake2 || intake1;
    let pollId = state?.pollId || pollId1;
    let meetingId = state?.meetingId || meetingId1;

    const dispatch = useDispatch();


    const initState =
        {
            "createDate": null,
            "lastModifiedDate": null,
            "id": null,
            courseId,
            "title": null,
            "questions": [],
            "createdBy": null,
            "updatedBy": null,
            "prefixKey": null
        }
    const navigate = useNavigate();

    const [fetchPollInventoryDetail] = useLazyGetPollInventoryDetailQuery();
    const [pollData, setPollData] = useState(initState);
    const [pollInventory] = usePollInventoryDetailMutation();
    const [deletePollInventory] = useDeletePollInventoryMutation();

    const [fetchPollDetail] = useLazyGetPollQuery();
    const [savePoll] = useSavePollMutation();
    const [deletePoll] = useDeletePollMutation();
    const refresh = () => {
        if(state?.meetingId || meetingId){
            fetchPollDetail({ meetingId, pollId }).then(({data}) => {
                const pollDetail = data.data;
                const pollDetailWithParsedQuestions = {
                    ...pollDetail,
                    questions: JSON.parse(pollDetail.questions)
                };
                setPollData(pollDetailWithParsedQuestions);
            });

        } else {
            fetchPollInventoryDetail({courseId}).then(({data}) => {
                const content = data.data;
                const pollDetail = content.find(item => item.id === Number(pollId));
                // Clone pollDetail into a new object and parse its questions
                const pollDetailWithParsedQuestions = {
                    ...pollDetail,
                    questions: JSON.parse(pollDetail.questions)
                };
                setPollData(pollDetailWithParsedQuestions);
            });
        }
    };

    useEffect(() => {
        if(pollId != "Create") {
            refresh();
        }
    }, []);

    const handlePollNameChange = (event) => {
        const { value } = event.target;
        setPollData(prevState => {
            return { ...prevState, title: value };
        });
    }

    const handleDelete = () => {
        if (window.confirm("Are you sure to delete?")) {
            if(meetingId){
                deletePoll({ meetingId, pollId }).then(() => {
                    dispatch(setIntakeTab(intakeTab.previousTab))
                });
            }else {
                deletePollInventory(pollData.id).then(() => {
                    navigate(`/pollInventory/${courseId2 || courseId}`);
                });
            }
        }
    }

    const handleInputChange = (event, questionIndex) => {
        const { value } = event.target;
        setPollData(prevState => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions[questionIndex] = {
                ...updatedQuestions[questionIndex],
                name: value
            };
            return { ...prevState, questions: updatedQuestions };
        });
    };

    const handleAnswerChange = (event, questionIndex, answerIndex) => {
        const { value } = event.target;
        setPollData(prevState => {
            const updatedQuestions = [...prevState.questions];
            const updatedAnswers = [...updatedQuestions[questionIndex].answers];
            updatedAnswers[answerIndex] = value;
            updatedQuestions[questionIndex] = {
                ...updatedQuestions[questionIndex],
                answers: updatedAnswers
            };
            return { ...prevState, questions: updatedQuestions };
        });
    };

    const handleAddChoice = (questionIndex) => {
        setPollData(prevState => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions[questionIndex].answers.push('');
            return { ...prevState, questions: updatedQuestions };
        });
    };

    const handleRemoveChoice = (questionIndex, answerIndex) => {
        setPollData(prevState => {
            const updatedQuestions = [...prevState.questions];
            const updatedAnswers = [...updatedQuestions[questionIndex].answers];
            updatedAnswers.splice(answerIndex, 1);
            updatedQuestions[questionIndex].answers = updatedAnswers;
            return { ...prevState, questions: updatedQuestions };
        });
    };

    const handleAddQuestion = () => {
        setPollData(prevState => ({
            ...prevState,
            questions: [...prevState.questions, { name: '', answers: [''] }]
        }));
    };

    const handleRemoveQuestion = (questionIndex) => {
        setPollData(prevState => {
            const updatedQuestions = [...prevState.questions];
            updatedQuestions.splice(questionIndex, 1);
            return { ...prevState, questions: updatedQuestions };
        });
    };

    const handleSave = () => {
        const updatedPoll = {
            ...pollData,
            questions: JSON.stringify(pollData.questions)
        };
        if(meetingId){
            savePoll({ id: meetingId, body: updatedPoll}).then((res) => {
                if (res?.data?.code == "SYS9999") {
                    alert("Please check all the fields and try again.");
                    return;
                }
                dispatch(setIntakeTab(intakeTab.previousTab))
            });
        }else {
            pollInventory(updatedPoll).then((res) => {
                if (res?.data?.code == "SYS9999") {
                    alert("Please check all the fields and try again.");
                    return;
                }
                navigate(`/pollInventory/${courseId}`);
            });
        }
    };

    return (
        <Fade in={true}>
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Box sx={{p: 6, pb: 5, borderRadius: 5, mt: 3, backgroundColor: 'white'}}>
                <Stack direction="row" alignContent={"space-between"} justifyContent={state ? "space-between" : "flex-end"}>
                    {state &&
                    <IconButton sx={{m: -1, mb: 2}} onClick={() => dispatch(setIntakeTab(intakeTab.previousTab))}>
                        <ArrowBackIosNewIcon/>
                    </IconButton>
                    }

                    {pollId !== 'Create' &&
                        <Box>
                            <LoadingButton type="button" variant="outlined" color="inherit"
                                           onClick={handleDelete}
                                           sx={{mt: -1}}
                                           startIcon={<DeleteIcon/>}>
                                Remove Poll
                            </LoadingButton>
                        </Box>
                    }


                </Stack>
                <div className="mt-2">
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
                        Poll Name *
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='pollName'
                        type='text'
                        placeholder=''
                        value={pollData?.title}
                        onChange={(event) => handlePollNameChange(event)}
                    />
                </div>
            </Box>
            {pollData?.questions?.map((question, questionIndex) => (
                <Box key={questionIndex} sx={{ position: 'relative', p: 6, mt: 3, borderRadius: 5, backgroundColor: 'white' }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleRemoveQuestion(questionIndex)}
                        sx={{
                            position: 'absolute',
                            top: 15,
                            right: 15,
                            color: 'grey',
                            '&:hover': {
                                color: 'red',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor={`question-${questionIndex}`}>
                            {`Question ${questionIndex + 1}`} *
                        </label>
                        <input
                            className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            id={`question-${questionIndex}`}
                            type='text'
                            placeholder=''
                            value={question.name}
                            onChange={(event) => handleInputChange(event, questionIndex)}
                        />
                        <FormControl component="fieldset">
                            <label className='block text-gray-700 text-sm font-bold mt-4 mb-2' htmlFor={`answers-${questionIndex}`}>
                                Option(s) *
                            </label>
                            <RadioGroup>
                                {question.answers.map((answer, answerIndex) => (
                                    <Box key={`${questionIndex}-${answerIndex}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <input
                                            className='appearance-none border rounded w-80 py-2 px-3 my-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                            type='text'
                                            value={answer}
                                            onChange={(event) => handleAnswerChange(event, questionIndex, answerIndex)}
                                        />
                                        <IconButton onClick={() => handleRemoveChoice(questionIndex, answerIndex)}>
                                            <RemoveCircleOutlineIcon fontSize={"small"} />
                                        </IconButton>
                                    </Box>
                                ))}
                                <Button color={"success"} startIcon={<AddCircleOutlineIcon />} onClick={() => handleAddChoice(questionIndex)}>
                                    Add choice
                                </Button>
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            ))}
            <Stack spacing={2} alignItems={"end"} alignContent={"flex-end"} p={2}>
                <Button color={"success"} startIcon={<AddCircleOutlineIcon/>} onClick={handleAddQuestion}>
                    Add Question
                </Button>
            </Stack>
            <Box sx={{p: 6, py: 3, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack alignItems='end'>
                    <LoadingButton type="button" variant="contained" color="primary" onClick={handleSave}>
                        {pollId !== "Create" ? "Save Change": "Create"}
                    </LoadingButton>
                </Stack>
            </Box>
        </Box>
        </Fade>
    );
}
