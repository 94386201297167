import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Stack,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    IconButton,
} from '@mui/material'
import { getCourseIntakeList } from '../../utils/request/course'
import Loading from '../Loading/Loading'
import style from './Quiz.module.scss'
import moment from 'moment'
import TablePage from '../UI/TablePage'
import { getWeeks } from '../../utils/request/course';
import backArrow from '../../image/back_arrow.svg'



const Quiz = () => {

    const [intakeList, setIntakeList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(intakeList.length)
    const [loading, setLoading] = useState(false)

    const [currentRecord, setCurrentRecord] = useState(null)
    const [showIntakeDetails, setShowIntakeDetails] = useState(false)
    const [currentWeeks, setCurrentWeeks] = useState(null)


    const navigate = useNavigate();

    const init = async () => {
        setLoading(true)
        try {
            let courseIntakeList = await getCourseIntakeList(0, 20)
            console.log('Get course intake list successfuly : ', courseIntakeList)
            setPage(0)
            setTotalNumberOfRecords(courseIntakeList.data.data.totalElements)
            setIntakeList(courseIntakeList.data.data.content)
        } catch (error) {
            console.log('Get course intake list error : ', error)
        } finally {
            setLoading(false)
        }
    }

    // const onNavigateToSubmissionList = (assignmentId) =>{
    //     navigate(`/quiz/${currentRecord.courseId}/${currentRecord.intake}/${assignmentId}`)
    // }

    const onTableRowClick = async (record) => {
        console.log('ran')
        setCurrentRecord(record)
        navigate(`/quiz/${record.courseId}/${record.intake}`)
    }

    const onPageChange = async (e, pageNumber) => {
        let newNumberOfRecord = Math.min(rowsPerPage * (pageNumber + 1), totalNumberOfRecords)
        if (newNumberOfRecord > intakeList.length) {
            setLoading(true)
            try {
                // 20 is the LCM of 5, 10, 20
                // get 20 records each time to simplify the logic
                let result = await getCourseIntakeList(Math.floor(intakeList.length / 20), 20)
                console.log('Get course intakeList successfully : ', result)
                setIntakeList(intakeList.concat(result.data.data.content))
                setPage(pageNumber)
            } catch (error) {
                console.log('Get course intakeList error : ', error)
            } finally {
                setLoading(false)
            }
        } else {
            setPage(pageNumber)
        }
    }
    const onRowsPerPageChange = (e) => {
        setPage(0)
        setRowsPerPage(parseInt(e.target.value))
    }

    useEffect(() => {
         init()
    }, [])

    // const renderCurrentIntake = () => {
    //     return (
    //         <div className={style.intakeClassContainer}>
    //             {currentWeeks && currentWeeks.weeks.map((week) => (
    //                 <div>
    //                     {week.assignments.map((c) => (
    //                         c.type === 'QUIZ' ? (
    //                             <div className={style.intakeClassWrapper}>
    //                                 <div className={style.intakeClassHeader}>
    //                                     <div className={style.intakeClassName}>{c.name}</div>
    //                                     <Stack spacing={2} direction="row">
    //                                         <div className={style.intakeClassAction}>
    //                                             <Button variant='outlined' onClick={() => onNavigateToSubmissionList(c.assignmentId)}>View Submissions</Button>
    //                                         </div>
    //                                     </Stack>
    //                                 </div>
    //                                 <div className={style.intakeClassContent}>
    //                                     <div className={style.date}>
    //                                         {moment(c.startDate).format('D MMM YYYY')} -{' '}
    //                                         {moment(c.endDate).format('D MMM YYYY')}
    //                                     </div>
    //                                 </div>
    //                                 {c.maxPoint && <span>Max Point: {c.maxPoint}</span>}

    //                             </div>
    //                         ) : null

    //                     ))}
    //                 </div>
    //             ))}
    //         </div>
    //     )
    // }

    return (
        <>
            {
                    <TablePage title='Intakes'>
                        <TableContainer className="tableContainer">
                            <Table>
                                <TableHead className="tableHead">
                                    <TableRow>
                                        <TableCell className="theadCell">START DATE</TableCell>
                                        <TableCell className="theadCell">END DATE</TableCell>
                                        <TableCell className="theadCell">COURSE</TableCell>
                                        <TableCell className="theadCell">INTAKE</TableCell>
                                        <TableCell className="theadCell">NO. STUDENT</TableCell>
                                        <TableCell className="theadCell">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {intakeList &&
                                        intakeList
                                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                            .map((record, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className="tableRow"
                                                        onClick={() => onTableRowClick(record)}
                                                    >
                                                        <TableCell className="tableCell">
                                                            {moment(record.startDate).format('D MMM YYYY')}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            {moment(record.endDate).format('D MMM YYYY')}
                                                        </TableCell>
                                                        <TableCell className="tableCell">{record.name}</TableCell>
                                                        <TableCell className="tableCell">{record.intake}</TableCell>
                                                        <TableCell className="tableCell">
                                                            {record.numOfStudent}
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            <div>{record.status}</div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={totalNumberOfRecords}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[10, 20]}
                                            onPageChange={onPageChange}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                        ></TablePagination>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TablePage>
                    // <div className={style.parentContainer}>
                    //     <div className={style.detailRecordContainer}>
                    //         <div className={style.backContainer}>
                    //             <img src={backArrow} />
                    //             <div onClick={() => setShowIntakeDetails(false)}>Back to List</div>
                    //         </div>
                    //         {renderCurrentIntake()}
                    //     </div>
                    // </div>
            }

        </>
    )
}

export default Quiz
