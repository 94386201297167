import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const removeApi = createApi({
    baseQuery: baseQuery,
    reducerPath: "removeApi",
    endpoints: builder => ({
        deleteEnrollment: builder.mutation({
            query: (body) => ({
                url: "/remove/enrollment",
                method: "DELETE",
                body,
            }),
        }),
        deleteIntake: builder.mutation({
            query: (body) => ({
                url: "/remove/intake",
                method: "DELETE",
                body,
            }),
        }),
        deleteCourse: builder.mutation({
            query: (body) => ({
                url: "/remove/course",
                method: "DELETE",
                body,
            }),
        }),
    }),
});

export const {
    useDeleteEnrollmentMutation,
    useDeleteIntakeMutation,
    useDeleteCourseMutation,
} = removeApi;
