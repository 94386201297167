import { mainRequest } from "./request";

export const getSubmissionList = (courseId, intake) =>
    mainRequest.post('/submission/getSubmissionList', {
        courseId,
        intake,
        type: 0,
    })

    export const getFullSubmissionRecord = (studentId, courseId, intake, submissionId) =>
    mainRequest.post('/submission/getFull', {
        studentId,
        courseId,
        intake,
        submissionId
    })

    export const postSubmitEvaluation = (studentId, courseId, intake, assignmentId, submissionId, file) => {
        let formData = new FormData()
        formData.append('studentId', studentId)
        formData.append('courseId', courseId)
        formData.append('intake', intake)
        formData.append('assignmentId', assignmentId)
        formData.append('submissionId', submissionId)
        formData.append('file', file)
        formData.append('docType', 'Evaluation')
        return mainRequest.post('/submission/submitEvaluation', formData)
    }


export const postMarkSubmission = async (studentId, courseId, intake, submissionId,score,comment) =>{
    await mainRequest.post('/submission/markSubmission', {
        studentId,
        courseId,
        intake,
        submissionId,
        score,
        comment
    })
}

