import {Breadcrumbs} from "@mui/material";
import StyledBreadcrumb from "../../../components/StyledBreadcrumb";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import EditTutor from "./EditTutor";
export default function EditTutorPage() {
    const { tutorId } = useParams();
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        onClick={() => navigate("/tutor")}
                        label="Add Tutor"
                        icon={<SupportAgentIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb label={`${tutorId === "create" ? "New" : "Edit"}`} />
                </Breadcrumbs>
            </Box>
            <EditTutor />
        </Box>
    )
}
