import IntakeList from "./IntakeList";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
export default function IntakeInitPage(props) {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Intake"
                        icon={<Icon fontSize={"small"}>playlist_add</Icon>}
                    />
                </Breadcrumbs>
            </Box>
            <IntakeList {...props} />
        </Box>
    )
}
