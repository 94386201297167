import DocumentPage from "./DocumentPage";
import {Breadcrumbs} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import Box from "@mui/material/Box";
export default function Index() {
    const { courseId, intake } = useParams();
    const navigate = useNavigate();
    const { data } = useGetGeneralCourseQuery({ courseId });
    console.log("courseId", courseId)
    console.log("data", data)
    return (
        <Box sx={{ width: '100%'}}>
<div style={{ padding: 30 }}>
        <div style={{  marginBottom: 20}}>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                onClick={() => navigate("/course")}
                label="Course"
                icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb onClick={() => navigate("/intake")} label="Intake" />
            <StyledBreadcrumb onClick={() => navigate(`/intake/${courseId}/${intake}`)} label={data?.data ? `${data?.data?.prefixKey}-${intake}` : '...'} />
            <StyledBreadcrumb label="Document" />
        </Breadcrumbs>
        </div>
        <DocumentPage />
        </div>
        </Box>
    )
}
