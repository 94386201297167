import InternalNotification from "./InternalNotification";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from "@mui/material/Box";
export default function InternalNotificationPage() {
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb label="Whatsapp Alert" icon={<WhatsAppIcon fontSize={"small"} sx={{mr: 1, color: '#b5bbbe'}} />} />
                </Breadcrumbs>
            </Box>
            <InternalNotification />
        </Box>
    )
}
