
import Grid from '@mui/material/Unstable_Grid2';
import {
    Divider,
    FormControlLabel,
    FormGroup,
    TextField,
    TextareaAutosize,
    Button,
    Stack,
    Chip,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import Fade from '@mui/material/Fade';
import React, {useEffect, useState} from "react";
import {useGetCourseScheduleQuery} from "../../api/courseGeneralApi";
import moment from 'moment';
import { delay } from "../../utils/util"
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import {styled} from "@mui/system";
import PublishIcon from '@mui/icons-material/Publish';
import {
    useGetMyCourseQuery,
    useGetTimesheetHistoryQuery,
    useLazyGetMyCourseQuery, useLazyGetTimesheetHistoryQuery,
    useSubmitTimesheetMutation
} from "../../api/timesheetApi";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import Alert from "@mui/material/Alert";
import {useCloneIntakeMutation} from "../../api/courseIntakeApi";
const TimesheetGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

function CustomNoRowsOverlay() {
    return (
        <TimesheetGridOverlay>
            <svg
                style={{ flexShrink: 0 }}
                width="240"
                height="200"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            <Box sx={{ mt: 1 }}>No Pending Items</Box>
        </TimesheetGridOverlay>
    );
}

const defaultColumnType = {
    resizable: false,
    filterable: false,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: true
};

const columns = [
    {
        field: "id",
        hide: true
    },
    {
        field: "lesson",
        ...defaultColumnType,
        headerName: "Lesson",
        flex: 1,
        renderCell: (params) => {
            let color = "secondary";
            if(params.row.type === "Class"){
                color = "success";
            }
            return <Chip label={`${params.row.type} ${params.row.type === "Class" ? params.row.lesson + 1 : ""}`} color={color} />;
        }
    },
    {
        field: "startDate",
        ...defaultColumnType,
        headerName: "Date",
        flex: 1.3,
        renderCell: (params) => {
            return moment(params.row.startDate).format('DD/MM/YYYY');
        }
    },
    {
        field: "lessonName",
        ...defaultColumnType,
        headerName: "Class description",
        renderCell: (params) => {
            return (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {params.value}
                </div>
            );
        },
        flex: 3

    },
];

const initState = {
    "columns": {
        "columnVisibilityModel": {
            "id": false,
            "brokerId": false
        }
    }
}


const TimesheetHistory = ({handleToggle, toggle, courseSchedule}) => {
    const [fetchHistory] = useLazyGetTimesheetHistoryQuery();
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState({});
    const [filterCourse, setFilterCourse] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const getUniqueCourses = (courseArray)=> {
        const unique = {};

        courseArray.forEach(course => {
            const key = `${course.courseId}-${course.intake}`;
            if (!unique[key]) {
                unique[key] = course;
            }
        });

        return Object.values(unique);
    }

    const handleFilterCourse = (e) => {
        const courseMap = courses.find(item => item.classId === e.target.value);
        setCourse(e.target.value)
        setFilterCourse(courseSchedule.filter(item => item.courseId === courseMap.courseId && item.intake === courseMap.intake));
    }

    useEffect(() => {
        if(toggle){
            setCourse({});
            setFilterCourse([]);
            fetchHistory().then(({ data }) => {
                if(data.code === 'SYS0000') {
                    setRowSelectionModel(data.data.map(item => item.classId));
                    setCourses(getUniqueCourses(data.data))
                }
            });
        }
    }, [toggle]);
    return (
        <Dialog
            open={toggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleToggle()
                }
            }}
            maxWidth="lg"
        >
            {/*<DialogTitle id="alert-dialog-title">*/}
            {/*    Clone to New Intake*/}
            {/*</DialogTitle>*/}
            <DialogContent>
                <FormControl variant="standard" sx={{ mt: 1, mb: 2, width: 800 }}>
                    <InputLabel id="course-select-label">Course</InputLabel>
                    <Select
                        labelId="course-select-label"
                        id="course-select"
                        label="Course"
                        value={course}
                        onChange={handleFilterCourse}
                    >
                        {courses.map((item) => {
                            return <MenuItem key={item.id} value={item.classId}>[ {item.intake}期 ] {item.courseName}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <DialogContentText id="alert-dialog-description">
                    <Box sx={{ width: '100%', mt: 3 }}>
                        <DataGridPro
                            initialState={initState}
                            autoHeight
                            columns={columns}
                            rows={filterCourse}
                            slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                            sx={{ '--DataGrid-overlayHeight': '300px' }}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            isRowSelectable={(params) => false}
                            getRowId={(row) => row.classId}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleToggle}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

function CheckinTable() {

    const [showAlert, setShowAlert] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [course, setCourse] = useState("");
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [submitTimesheet] = useSubmitTimesheetMutation();
    const fetchMyCourse = useGetMyCourseQuery();
    const courseSchedule = useGetCourseScheduleQuery()?.data?.data?.filter(item => item.classId) || [];
    const [filterCourse, setFilterCourse] = useState([]);
    const myCourse = fetchMyCourse.data?.data || [];

    const handleFilterCourse = (e) => {
        const courseDetail = myCourse.find(item => item.id === e.target.value);
        setCourse(e.target.value)
        setFilterCourse(courseSchedule.filter(item => item.courseId === courseDetail.courseId && item.intake === courseDetail.intake));
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {

            const selectedDetail = courseSchedule.filter(item => rowSelectionModel.includes(item.classId));
            const requestBody =  selectedDetail.map(item => {
                return {
                    classId: item.classId,
                    courseId: item.courseId,
                    intake: item.intake
                }
            });
            await submitTimesheet(requestBody);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            await delay(1000);
            window.location.reload();
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <Fade in={true}>
            <div>
                <Grid container spacing={2}>
                    <Grid xs={8}>
                        <TimesheetHistory handleToggle={handleToggle} toggle={toggle} courseSchedule={courseSchedule}/>
                        <Box sx={{ width: '100%' }}>
                            <DataGridPro
                                initialState={initState}
                                autoHeight
                                columns={columns}
                                rows={filterCourse}
                                slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                sx={{ '--DataGrid-overlayHeight': '300px' }}
                                checkboxSelection
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                                rowSelectionModel={rowSelectionModel}
                                getRowId={(row) => row.classId}
                            />
                        </Box>
                    </Grid>
                    <Divider orientation="vertical"  variant="middle" flexItem >
                        <div style={{ width: 70, height: 0}}></div>
                    </Divider>
                    <Grid xs>
                        <FormGroup>
                            <h6>Pending Submission</h6>
                            <FormControl variant="standard" sx={{ mt: 1, mb: 2, minWidth: 200, maxWidth: 400 }}>
                                <InputLabel id="course-select-label">Course</InputLabel>
                                <Select
                                    labelId="course-select-label"
                                    id="course-select"
                                    label="Course"
                                    value={course}
                                    onChange={handleFilterCourse}
                                >
                                    {myCourse.map((item) => {
                                        return <MenuItem key={item.id} value={item.id}>[ {item.intake}期 ] {item.courseName}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Stack spacing={1}>
                            <LoadingButton
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                                color={'success'}
                                sx={{ bgcolor : '#354147', mt: 1, minWidth: 200, maxWidth: 410}}
                                disabled={rowSelectionModel.length === 0 || loading}
                            >
                                <span>Submit</span>
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={handleToggle}
                                sx={{ minWidth: 200, maxWidth: 410}}
                            >History
                            </Button>
                            </Stack>
                            {showAlert &&
                                <Fade in={showAlert} {...{ timeout: 1000 }}>
                                    <Alert severity="success" sx={{ mt: 2, minWidth: 200, maxWidth: 410 }}>
                                        Your timesheet has been updated successfully.
                                    </Alert>
                                </Fade>
                            }
                        </FormGroup>
                    </Grid>
                    <Grid xs={8}>
                    </Grid>
                </Grid>
            </div>
        </Fade>
    )
}
export default CheckinTable;
