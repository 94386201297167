import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColumnMenu, gridDetailPanelExpandedRowsContentCacheSelector,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade, TextField, StepLabel, Step, Stepper
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useCloneIntakeMutation, useGetIntakeEnrollListMutation,
    useGetIntakeListQuery, useGetIntakeStudentListMutation,
    useLazyGetIntakeListQuery, useUpdateExamScoreMutation
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate} from "react-router-dom";
import {delay} from "../../../../utils/util";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
    ENROLLMENT_STATUS_PAID,
    ENROLLMENT_STATUS_REJECT,
    ENROLLMENT_STATUS_REVIEW,
    ENROLLMENT_STATUS_UNPAID
} from "../../../../utils/constants";
import style from "../../../Enrollment/Enrollment.module.scss";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {formatDistanceToNow, parseISO} from "date-fns";


export const statusColor = (status) => {
    let style = {};
    switch (status) {
        case "Under review":
            style = {
                color: 'warning',
            }
            break;
        case "Pending for payment":
            style = {
                color: '#6B4378',
            }
            break;
        case "Receipt issue":
            style = {
                color: '#B96338',
            }
            break;
        case "Supporting documents":
            style = {
                color: '#3E6959',
            }
            break;
        case "Proof of completion":
            style = {
                color: '#174DA5',
            }
            break;
        case "Certificate issue":
            style = {
                color: '#ABAE05',
            }
            break;
        case "Completed":
            style = {
                color: '#354147',
            }
            break;
        case "Rejected":
            style = {
                color: '#A50505',
            }
            break;
        default:
            style = {
                color: '#B77B7B',
            }
    }
    return style;
}

const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

function CustomDetailPanelToggle(props) {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);

    return (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

export const steps = [
    'Under review',
    'Pending for payment',
    'Receipt issue',
    'Supporting documents',
    'Proof of completion',
    'Certificate issue',
    'Completed'
];

export default function IntakeEnrollList({ courseId, intake}) {

    const [fetchIntakeEnrollList] = useGetIntakeEnrollListMutation();
    const [data, setData] = useState(null);
    const [toggle, setToggle] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const navigate = useNavigate();

    const handleToggle = (row) => {
        setRow(row);
        setToggle(!toggle);
    };

    const refresh = () => {
        fetchIntakeEnrollList({
            courseId,
            intake
        }).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    const getDetailPanelContent = React.useCallback(
        ({ row }) =>
            <Box sx={{ width: '100%'}}>
                <Stepper activeStep={steps.indexOf(row.status)} alternativeLabel sx={{ pt: 2 }}>
                    {steps.map((label) => (
                        <Step  key={label} >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        ,
        [],
    );


    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "applicationNo",
            ...defaultColumnType,
            headerName: "Application",
            width: 200
        },
        {
            field: "enrollDate",
            ...defaultColumnType,
            headerName: "Enrollment Date",
            renderCell: (params) => {
                const date = `${formatDistanceToNow(parseISO(moment(new Date(params.row.enrollDate)).format("YYYY-MM-DD HH:mm:ss.SS")))} ago`;
                return (
                    <>
                        <strong>{date}</strong>
                    </>
                )
            },
            width: 180
        },
        {
            field: "applicantName",
            ...defaultColumnType,
            headerName: "Student",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.applicantName}
                    </>
                )
            },
            flex: 0.6
        },
        {
            field: "docDeclaration",
            ...defaultColumnType,
            headerName: "Declaration",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.docDeclaration ?
                            <CheckCircleIcon color={'success'} />
                            :
                            <CancelIcon color={'error'} />
                        }
                    </>
                )
            },
            width: 130
        },
        {
            field: "docHkId",
            ...defaultColumnType,
            headerName: "HKID",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.docHkId ?
                            <CheckCircleIcon color={'success'} />
                            :
                            <CancelIcon color={'error'} />
                        }
                    </>
                )
            },
            width: 100
        },
        {
            field: "status",
            ...defaultColumnType,
            headerName: "",
            renderCell: (params) => {
                const { status } = params.row;
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Chip label={status}  variant="filled" sx={{ mr: 1, blockSize: '90%', color: statusColor(status).color}}/>
                    </Box>
                )
            },
            width: 220
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
                <CustomDetailPanelToggle id={params.id} value={params.value} />
            ),
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }

    const onRowClick = (params) => {
        navigate(`/intake/${courseId}/${intake}/enrollment/${params.row.applicantId}`);
    };

    const getDetailPanelHeight = React.useCallback(() => 76, []);
    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                pageSizeOptions={[5]}
                pagination
                onRowClick={onRowClick}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                loading={data === null}
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
