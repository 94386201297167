import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Fade
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {useEffect, useState} from "react";
import {
    useCloneIntakeMutation,
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useNavigate} from "react-router-dom";
import {delay} from "../../../../utils/util";
import {useLazyGetCourseListQuery} from "../../../../api/courseGeneralApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";

const statusColors = {
    'PUBLISHED': 'green',
    'ACTIVE': 'blue',
    'INACTIVE': '#D3D3D3',
};

const StatusBall = styled('div')(({ theme, status }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: statusColors[status] || theme.palette.error.main,
    display: 'inline-block',
    marginLeft: 5,
    marginRight: 10,
}));


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

const CloneIntakeDialog = ({handleToggle, toggle, row, refresh, rawData}) => {
    const [loading, setLoading] = useState(false);
    const [cloneIntake] = useCloneIntakeMutation();
    const maxRow = rawData.filter((item) => item.courseId === row?.courseId).sort((a, b) => b.intake - a.intake)[0];
    return (
        <Dialog
            open={toggle}
            //onClose={handleToggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleToggle()
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                Clone to New Intake
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" width={450}>
                    {row?.name} > {row?.name} <br/>
                    Intake {row?.intake} > Intake {maxRow?.intake + 1}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleToggle}>Cancel</Button>
                <LoadingButton
                    color="primary"
                    onClick={ async () => {
                        setLoading(true)
                        await cloneIntake({
                            courseId: row?.courseId,
                            intake: row?.intake,
                            nextIntake: maxRow?.intake + 1
                        });
                        await refresh();
                        await delay(1000);
                        setLoading(false);
                        handleToggle()
                    }}
                    loading={loading}
                    loadingPosition="center"
                    autoFocus
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

function CustomGridTreeDataGroupingCell(props) {
    const { id, field, row, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };
    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                <Tooltip title={props.row.status}>
                    <StatusBall status={props.row.status} />
                </Tooltip>
                { row.group.length === 1 ?
                    <strong>Intake {row.intake}</strong>
                    :
                    <span>Intake {row.intake}</span>
                }

                <IconButton
                    onClick={handleClick}
                    size="small"
                >
                    {filteredDescendantCount > 0 &&
                        (rowNode.childrenExpanded ?
                            <ExpandMoreIcon /> :
                            <KeyboardArrowRightIcon />)
                    }
                </IconButton>
            </div>
        </Box>
    );
}
export default function CourseList() {

    const navigate = useNavigate()
    const [fetchCourseList] = useLazyGetCourseListQuery();
    const [data, setData] = useState(null);

    const refresh = () => {
        fetchCourseList().then(({ data }) => {
            let { data: content } = data;
            setData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    const columns = [
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Course",
            renderCell: (params) => {
                return <Box><strong>{params.row.name}</strong><br />{params.row.nameEng && `(${params.row.nameEng})`}</Box>
            },
            flex: 1,
        },
        {
            field: "prefixKey",
            ...defaultColumnType,
            headerName: "",
            width: 150,

        },
        {
            field: "courseCode",
            ...defaultColumnType,
            headerName: "",
            width: 200
        },
        {
            field: "accredibleId",
            ...defaultColumnType,
            headerName: "Accredible",
            renderCell: (params) => {
                return params.row.accredibleId ?
                        <CheckCircleIcon color={'success'} />
                        :
                        <CancelIcon color={'error'} />

            },
            width: 120
        },
        {
            field: "mondaySync",
            ...defaultColumnType,
            headerName: "Monday",
            renderCell: (params) => {
                return params.row.mondaySync ?
                    <CheckCircleIcon color={'success'} />
                    :
                    <CancelIcon color={'error'} />

            },
            width: 120
        },
        {
            field: "wordpressSync",
            ...defaultColumnType,
            headerName: "Wordpress",
            renderCell: (params) => {
                return params.row.wordpressSync ?
                    <CheckCircleIcon color={'success'} />
                    :
                    <CancelIcon color={'error'} />

            },
            width: 120
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "brokerId": false
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
    }

    const onRowClick = ({ row }) => {
        navigate(`/course/${row.id}`);
    };

    return (
        <Fade in={true}>
        <Box sx={{ width: '100%'}}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                pageSizeOptions={[10]}
                loading={data === null}
                pagination
                onRowClick={onRowClick}
                sx={{
                    border: 'none'
                }}
            />
        </Box>
        </Fade>
    );
}
