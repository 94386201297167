import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
export const externalApi = createApi({
    baseQuery: fetchBaseQuery(),
    reducerPath: "externalApi",
    endpoints: builder => ({
        getPublicHoliday: builder.query({
            query: (param) => ({
                url: `https://www.1823.gov.hk/common/ical/en.json`,
            })
        }),
    }),
});

export const {
    useGetPublicHolidayQuery,
} = externalApi;
