import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const attendanceApi = createApi({
    baseQuery,
    reducerPath: "attendanceApi",
    endpoints: builder => ({

        getAttendClassAttendance: builder.mutation({
            query: (body) => ({
                url: "/attendance/class/attendList",
                method: "POST",
                body,
            })
        }),
        getMakeupClassAttendance: builder.mutation({
            query: (body) => ({
                url: "/attendance/class/makeupList",
                method: "POST",
                body,
            })
        }),
        createStudentAttendance: builder.mutation({
            query: (body) => ({
                url: "/attendance/attendCreate",
                method: "POST",
                body,
            })
        }),
        removeStudentAttendance: builder.mutation({
            query: (body) => ({
                url: "/attendance/attendCreate",
                method: "DELETE",
                body,
            })
        }),
    }),
});

export const {
    useGetAttendClassAttendanceMutation,
    useGetMakeupClassAttendanceMutation,
    useCreateStudentAttendanceMutation,
    useRemoveStudentAttendanceMutation,
} = attendanceApi;
