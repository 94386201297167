import { mainRequest, uaaRequest } from "./request";

//// Admin
export const createAdminUser = (username, password, roleType) =>
    uaaRequest.post('/user/internal/createUser', {
        username,
        password,
        roleType
    })

export const resetAdminPassword = (username, password) =>
    uaaRequest.post('/user/internal/passwordReset', {
        username,
        password,
    })

export const disableUser = (id, disable) =>
    mainRequest.post('/tutor/update', {
        id: id,
        disable: disable // boolean
    })

export const disableAdminUser = (username) =>
    uaaRequest.post('/user/internal/disableUser', {
        username
    })

export const getAllUser = () =>
    uaaRequest.post('/user/internal/getUserList')


//// Tutor
export const getTutorList = (pageNum, pageSize) =>
    mainRequest.post('/tutor/getList', {
        pageNum,
        pageSize,
    })

export const updateTutor = (id, nameEn, nameZh, description, linkedin, website, title) =>
    mainRequest.post('/tutor/update', {
        id: id,
        nameEn: nameEn,
        nameZh: nameZh,
        description: description,
        linkedin: linkedin,
        website: website,
        title: title
    })

export const updateTutorThumbnail = (tutorId, file) => {
    let formData = new FormData()
    formData.append('tutorId', tutorId)
    formData.append('file', file)
    return mainRequest.post('/tutor/updateThumbnail', formData)
}

export const assignIntakeTutor = (courseId, intake, tutorId) =>
    mainRequest.post('/user/course/assignIntakeTutor', {
        courseId,
        intake,
        tutorId
    })


