import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const courseGeneralApi = createApi({
    baseQuery,
    reducerPath: "courseGeneralApi",
    endpoints: builder => ({
        getGeneralCourse: builder.mutation({
            query: (body) => ({
                url: `/course/getGeneral`,
                method: "POST",
                body,
            }),
        }),
        updateGeneralCourse: builder.mutation({
            query: (body) => ({
                url: `/course/updateGeneral`,
                method: "POST",
                body,
            }),
        }),
        createCourse: builder.mutation({
            query: (body) => ({
                url: `/course/create`,
                method: "POST",
                body,
            }),
        }),
        getCourseList: builder.query({
            query: (param) => ({
                url: `/course/getCourseList`,
            })
        }),
        getCourseSchedule: builder.query({
            query: (param) => ({
                url: `/course/schedule/getScheduleList`,
            })
        }),
        getPollInventory: builder.query({
            query: (param) => ({
                url: `/zoom/getPollInventory`,
            })
        }),
        getPollInventoryDetail: builder.query({
            query: (param) => ({
                url: `/zoom/getPollInventoryDetail?courseId=${param.courseId}`
            })
        }),
        pollInventoryDetail: builder.mutation({
            query: (body) => ({
                url: "/zoom/pollInventory",
                method: "POST",
                body,
            }),
        }),
        deletePollInventory: builder.mutation({
            query: (id) => ({
                url: `/zoom/deletePollInventory/${id}`,
                method: "DELETE",
            }),
        }),
        getZoomJoinUrl: builder.query({
            query: (meetingId) => ({
                url: `/zoom/getJoinURL/${meetingId}`,
            }),
        }),
    }),
});

export const {
    useLazyGetCourseListQuery,
    useGetCourseScheduleQuery,
    useLazyGetPollInventoryQuery,
    useLazyGetPollInventoryDetailQuery,
    usePollInventoryDetailMutation,
    useDeletePollInventoryMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation,
    useCreateCourseMutation,
    useLazyGetZoomJoinUrlQuery,
} = courseGeneralApi;
