import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const notificationApi = createApi({
    baseQuery: baseQuery,
    reducerPath: "notificationApi",
    endpoints: builder => ({
        getInternalNotification: builder.query({
            query: () => ({
                url: `/notification/list`,
            })
        }),
        updateActive: builder.mutation({
            query: (body) => ({
                url: "/notification/active",
                method: "POST",
                body,
            }),
        }),
        getInternalNotificationGroup: builder.query({
            query: (groupId) => ({
                url: `/notification/groupList?groupId=${groupId}`,
            })
        }),
        updateGroupList: builder.mutation({
            query: (body) => ({
                url: "/notification/groupList",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useGetInternalNotificationQuery,
    useUpdateActiveMutation,
    useLazyGetInternalNotificationGroupQuery,
    useUpdateGroupListMutation,
} = notificationApi;
