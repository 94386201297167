import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
} from '@mui/x-data-grid-pro';

import { styled } from '@mui/system';
import {useEffect, useState} from "react";
import { useLazyGetPollInventoryQuery} from "../../api/courseGeneralApi";
import { useNavigate } from 'react-router-dom';
import {Button} from "@mui/material";
import Fade from "@mui/material/Fade";
import StyledTableOne from "../../components/StyledTable/StyledTableOne";


export const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

export function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

export default function PollInventoryList() {

    const navigate = useNavigate(); // Hook for navigation (React Router v6)
    const [fetchPollInventoryList] = useLazyGetPollInventoryQuery();
    const [data, setData] = useState(null);

    const refresh = () => {
        fetchPollInventoryList().then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    };

    useEffect(() => {
        refresh();
    }, []);



    const columns = [
        {
            field: "courseId",
            hide: true
        },
        {
            field: "courseName",
            ...defaultColumnType,
            headerName: "Course Name",
            renderCell: (params) => {
                return <span>{params.row.courseNameZh}<br />{params.row.courseNameEn && `(${params.row.courseNameEn})`}</span>
            },
            flex: 2
        },
        {
            field: "totalPolls",
            ...defaultColumnType,
            headerName: "Total Poll",
            flex: 0.8
        },
        {
            field: "totalQuestions",
            ...defaultColumnType,
            headerName: "Total Question",
            flex: 0.8
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "courseId": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }


    const onRowClick = (params) => {
        // Replace '/some-path' with the actual path you want to navigate to
        navigate(`/pollInventory/${params.row.courseId}`);
    };

    // Updated component with UI/UX enhancements
    return (
        <Fade in={true}>
        <Box sx={{ width: '100%', position: 'relative' }}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                disableRowSelectionOnClick
                getRowId={(row) => row.courseId}
                pageSizeOptions={[5]}
                pagination
                onRowClick={onRowClick}
                loading={data === null}
                sx={{
                    border: 'none',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer', // Change mouse cursor to pointer
                    },
                    // ... existing styles ...
                }}
            />
        </Box>
        </Fade>
    );
}
