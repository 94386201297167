
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery, useGetIntakeStudentListMutation} from "../../../../api/courseIntakeApi";
import {useEffect, useState} from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import {Box, Breadcrumbs, Typography, Paper, Grid, Divider, Stack, FormControl, Button, Icon} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import style from "../IntakeDetail/Intake.module.scss";
import moment from "moment/moment";
import AssignmentList from "./AssignmentList";
import QuizList from "./QuizList";

export default function StudentPage(props) {
    const navigate = useNavigate();
    const { courseId, intake, studentId } = useParams();
    const { data: courseGeneral } = useGetGeneralCourseQuery({ courseId });
    const [studentData, setStudentData] = useState({});
    const [fetchIntakeStudentList] = useGetIntakeStudentListMutation();


    const refresh = () => {
        fetchIntakeStudentList({
            courseId,
            intake,
            studentId: -1
        }).then(({ data }) => {
            const { data: content } = data;
            const currentStudent = content.find(student => student.studentId === Number(studentId));
            setStudentData(currentStudent);
        });
    }


    useEffect(() => {
        refresh();
    }, []);

    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb onClick={() => navigate("/intake")} label="Intake" icon={<Icon fontSize={"small"}>playlist_add</Icon>}/>
                    <StyledBreadcrumb onClick={() => navigate(`/intake/${courseId}/${intake}`, {
                        state: { tab: 'Enrolled Students' }
                    })} label={courseGeneral?.data ? `${courseGeneral?.data?.prefixKey}-${intake}` : '...'} />
                    <StyledBreadcrumb label={studentData?.name ? studentData?.name : '...'} />

                </Breadcrumbs>
        </Box>
            {studentData?.name &&
                <Box sx={{ p: 5, borderRadius: 5, backgroundColor: 'white' }}>
                    <Stack direction="column" spacing={2}>
                        {/* Student name */}
                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: 'grey' }}>
                            {studentData.name}
                        </Typography>

                        {/* Student details and additional info */}
                        <Grid container spacing={2}>
                            {/* Left side - first 3 details */}
                            <Grid item xs={12} sm={4} md={4}>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email</Typography>
                                    <Typography variant="body2">{studentData.email}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Mobile</Typography>
                                    <Typography variant="body2">{studentData.mobile}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Date of birth</Typography>
                                    <Typography variant="body2">{moment(studentData.dob).format("YYYY-MM-DD")}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Job</Typography>
                                    <Typography variant="body2">{studentData.job}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Experience</Typography>
                                    <Typography variant="body2">{studentData.experience} year(s)</Typography>
                                </Stack>
                            </Grid>

                            {/* Center - next 3 details */}
                            <Grid item xs={12} sm={4} md={4}>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Academic Level</Typography>
                                    <Typography variant="body2">Level {studentData.academicLevel}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Company</Typography>
                                    <Typography variant="body2">{studentData.company}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Computer Skills</Typography>
                                    <Typography variant="body2">{studentData.computerSkill ? "Yes" : "No"}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Digital Marketing Plan</Typography>
                                    <Typography variant="body2">{studentData.marketingPlan ? "Yes" : "No"}</Typography>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Personal Info Promotion</Typography>
                                    <Typography variant="body2">{studentData.personalInfoPromotion ? "Yes" : "No"}</Typography>
                                </Stack>
                            </Grid>

                            {/* Right - additional information aligned to the right */}
                            <Grid item xs={12} sm={4} md={4}>
                                <Box sx={{textAlign: 'right'}}>
                                    <Stack spacing={1} alignItems="flex-end">
                                        {studentData.assignmentScore.length === 0 ?
                                            <p>No assignment required</p> :
                                            studentData.assignmentScore.map((assignment, index) => (
                                                <Stack key={index} spacing={1} alignItems="flex-end">
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Assignment {index + 1}</Typography>
                                                    <Typography variant="body2">{assignment.score !== null ? `${assignment.score}/${assignment.maxPoint}` : "N/A"}</Typography>
                                                </Stack>
                                            ))}
                                        {studentData.examScore.length === 0 ?
                                            <p>No exam required</p> :
                                            studentData.examScore.map((exam, index) => (
                                                <Stack key={index} spacing={1} alignItems="flex-end">
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Exam {studentData.examScore.length > 1 ? index + 1 : ""}</Typography>
                                                    <Typography variant="body2">{exam.score !== null ? `${exam.score}/${exam.maxPoint}` : "N/A"}</Typography>
                                                </Stack>
                                            ))}

                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            }
            <Box sx={{ p: 1, mt: 3, borderRadius: 5, backgroundColor: 'white' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: 'grey', p: 5, pb: 0 }}>
                    Assignment
                </Typography>
            <AssignmentList />
            </Box>
            <Box sx={{ p: 1, mt: 3, borderRadius: 5, backgroundColor: 'white' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: 'grey', p: 5, pb: 0 }}>
                    Quiz
                </Typography>
                <QuizList />
            </Box>
        </Box>


    )
}
