import {Breadcrumbs, Button, Icon, Stack} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import CourseList from "./CourseList";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
export default function CourseInitPage(props) {
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');
    if(userRole === 'TUTOR') navigate('/intake');
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Course"
                        icon={<Icon fontSize={"small"}>school</Icon>}
                    />
                </Breadcrumbs>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button color="inherit" startIcon={<AddCircleOutlineIcon />} sx={{ p: 0, pr: 1}} onClick={() => navigate("/course/create")}>
                            NEW
                        </Button>
                    </Box>
                </Stack>
            </Box>
                <CourseList {...props} />
        </Box>
    )
}
