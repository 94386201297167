import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
} from '@mui/x-data-grid-pro';

import { styled } from '@mui/system';
import {useEffect, useState} from "react";
import {useLazyGetPollInventoryDetailQuery} from "../../api/courseGeneralApi";
import {useNavigate, useParams} from 'react-router-dom';
import PollInventoryList, {CustomColumnMenu, defaultColumnType} from "./PollInventoryList";
import moment from "moment";
import {formatDistanceToNow} from "date-fns";
import {Breadcrumbs, Button, Icon, Stack} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import Fade from "@mui/material/Fade";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import StyledTableOne from "../../components/StyledTable/StyledTableOne";


export default function PollInventoryDetail() {

    const navigate = useNavigate();
    const param = useParams();
    const [fetchPollInventoryDetail] = useLazyGetPollInventoryDetailQuery();
    const [data, setData] = useState(null);
    const { data: courseGeneral } = useGetGeneralCourseQuery({ courseId: param.courseId });
    const refresh = () => {
        fetchPollInventoryDetail({courseId: param.courseId}).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    };

    useEffect(() => {
        refresh();
    }, []);



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "title",
            ...defaultColumnType,
            headerName: "Poll Name",
            flex: 2
        },
        {
            field: "questions",
            ...defaultColumnType,
            headerName: "Total",
            renderCell: (params) => {
                return JSON.parse(params.row.questions).length;
            },
            flex: 0.5
        },
        {
            field: "createdBy",
            ...defaultColumnType,
            headerName: "Creator",
            flex: 0.8
        },
        {
            field: "updatedBy",
            ...defaultColumnType,
            headerName: "Last Update",
            flex: 0.8
        },
        {
            field: "lastModifiedDate",
            ...defaultColumnType,
            headerName: "IN",
            renderCell: (params) => {
                return formatDistanceToNow(new Date(params.row.lastModifiedDate), { addSuffix: true });
            },
            flex: 0.8
        }
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }


    const onRowClick = (params) => {
        navigate(`/pollInventory/${params.row.courseId}/${params.row.id}`);
    };

    // Updated component with UI/UX enhancements
    return (

        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Poll Inventory"
                    onClick={() => navigate("/pollInventory")}
                    icon={<Icon fontSize={"small"}>inventory</Icon>}
                />
                <StyledBreadcrumb  label={courseGeneral?.data ? `${courseGeneral?.data?.prefixKey}` : '...'} />
            </Breadcrumbs>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                    <Button color={"inherit"} startIcon={<AddCircleOutlineIcon  />} onClick={() =>  navigate(`/pollInventory/${param.courseId}/Create`)} sx={{ p: 0, pr: 1}}>
                        NEW
                    </Button>
                </Box>
                </Stack>
        </Box>
        <Fade in={true}>
            <Box>
        <Box sx={{width: '100%', mt: 2, position: 'relative'}}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                loading={data === null}
                disableRowSelectionOnClick
                    pageSizeOptions={[5]}
                    pagination
                    onRowClick={onRowClick}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer', // Change mouse cursor to pointer
                        },
                        // ... existing styles ...
                    }}
                />
            </Box>
            </Box>
        </Fade>
    </Box>
    );
}
