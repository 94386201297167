import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userProfile: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserProfile(state, action) {
            return {
                ...state,
                userProfile: action.payload,
            }
        }
    }
});

export const {actions: {setUserProfile}} = authSlice;