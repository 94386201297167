import style from '../EditMaterial.module.scss';
import backArrow from '../../../../../image/back_arrow.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { NavbarContext } from '../../../../../utils/context/navbarContext';
import {
    Button,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    FormControlLabel,
    Switch,
    Stack
} from '@mui/material';
import { createQuiz, getQuizDetail, updateQuiz } from '../../../../../utils/request/quiz';

const QuizPage = (props) => {

    const [pageMode, setPageMode] = useState('create');
    const param = useParams();

    const DefaultQuizObject = {
        assignmentId: param.assignmentId,
        timeLimit: 60,
        screenLockRequired: false,
        questions: [],
        answers: [],
    }

    const DefaultQuestion = {
        type: "",
        question: "",
        options: ["", "", "", ""],
    }

    const DefaultAnswer = {
        questionIndex: '',
        answer: '',
    }


    const navigate = useNavigate();
    const { showNavbar, setShowNavbar } = useContext(NavbarContext)

    const init = async () => {
        setShowNavbar(false)
    }

    const onBackToCourseListClick = () => {
        navigate(-1)
    }

    // Create Quiz 

    const [quizForm, setQuizForm] = useState(DefaultQuizObject);
    const [questionList, setQuestionList] = useState(null);
    const [answerList, setAnswerList] = useState(null);

    // Edit Quiz 

    useEffect(() => {

        init();

        const getQuiz = async () => {
            const res = await getQuizDetail(param.quizId);
            setQuizForm(res.data.data);
            setQuestionList(JSON.parse(res.data.data.questions));
            if(res.data.data.answers === "" || res.data.data.answers === null){
                setAnswerList([]);
            } else {
                setAnswerList(JSON.parse(res.data.data.answers));
            }
        }

        if (param.quizId) {
            setPageMode('edit');
            getQuiz();
        } else {
            setQuestionList([]);
            setAnswerList([]);
        }

    }, [])

    // useEffect(() => {
    //     console.log('questionList', questionList);
    //     console.log('answerList', answerList);
    // }, [questionList, answerList])

    const onAddQuestion = (type) => {
        let newQuestion = DefaultQuestion;
        let newAnswer = DefaultAnswer;
        newQuestion.type = type;
        setQuestionList([...questionList, newQuestion]);
        setAnswerList([...answerList, newAnswer]);
    }

    const handleQuestionChange = (e, OuterIndex) => {
        const updatedNestedArr = questionList.map((item, index) => {
            if (index === OuterIndex) {
                return { ...item, question: e.target.value };
            }
            return item;
        });
        setQuestionList(updatedNestedArr);
    }

    const handleAnswerChange = (e, OuterIndex) => {
        const updatedNestedArr = answerList.map((item, index) => {
            if (index === OuterIndex) {
                return { ...item, answer: e.target.value, questionIndex: (index + 1).toString() };
            }
            return item;
        });
        setAnswerList(updatedNestedArr);
    }

    const handleOptionChange = (e, OuterIndex, InnerIndex) => {
        const updatedNestedArr = questionList.map((item, index) => {
            if (index === OuterIndex) {
                return {
                    ...item, options: item.options.map((option, index) => {
                        if (index === InnerIndex) {
                            return e.target.value;
                        }
                        return option;
                    })
                };
            }
            return item;
        });
        setQuestionList(updatedNestedArr);
    }

    const onSaveQuizForm = () => {
        let formObj = {
            ...quizForm,
            questions: JSON.stringify(questionList),
            answers: JSON.stringify(answerList),
        }
        if (pageMode === 'create') {
            const postQuizForm = async () => {
                try {
                    let res = await createQuiz(
                        formObj.assignmentId,
                        formObj.timeLimit,
                        formObj.screenLockRequired,
                        formObj.questions,
                        formObj.answers,
                    );
                    console.log('create quiz success', res);
                    alert('Quiz Updated')
                } catch (error) {
                    console.log('create quiz error', error);
                }
            }
            postQuizForm();

        } else {
            const updateQuizForm = async () => {
                try {
                    let res = await updateQuiz(
                        param.quizId,
                        formObj.timeLimit,
                        formObj.screenLockRequired,
                        formObj.questions,
                        formObj.answers,
                    );
                    console.log('update quiz success', res);
                    alert('Quiz Updated')
                } catch (error) {
                    console.log('update quiz error', error);
                }
            }
            updateQuizForm();

        }
    }

    const removeThisQuestion = (index) => {
        const filteredQuestions = questionList.filter((item, i) => i !== index);
        console.log('filteredQuestions', filteredQuestions)
        setQuestionList(filteredQuestions);
    }

    if (!questionList) {
        return <div>Loading...</div>;
    }

    if(!answerList){
        return <div>Loading...</div>;
    }

    return <>
        <div className={style.parentContainer}>
            <div className={style.headingContainer}>
                <div className={style.backToCourseListButton} onClick={onBackToCourseListClick}>
                    <img src={backArrow} className={style.backArrow} />
                    <div>Back</div>
                </div>
                <div className={style.header}>New Quiz</div>
            </div>

            <div className={style.mainContainer}>
                <div className={style.weekListContainer}>
                    <div className={style.gridWrapper}>
                        {/* Left side  */}
                        <div className={style.grid4}>
                            <div className={style.quizSetting}>
                                <div className={style.quizSettingBody}>
                                    <div className={style.quizSettingBodyItem}>
                                        <FormControl fullWidth>
                                            <FormLabel component="legend">Quiz Settings</FormLabel>
                                            <TextField
                                                label="Time Limit"
                                                placeholder="Time Limit"
                                                value={quizForm.timeLimit}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                onChange={(e) =>
                                                    setQuizForm({
                                                        ...quizForm,
                                                        timeLimit: e.target.value,
                                                    })
                                                }
                                                required
                                            />

                                            <FormControlLabel control={
                                                <Switch defaultChecked value={quizForm.screenLockRequired} onChange={() => {
                                                    setQuizForm({
                                                        ...quizForm,
                                                        screenLockRequired: !quizForm.screenLockRequired,
                                                    })
                                                }} />} label="Require Screen Lock Browser" />
                                        </FormControl>

                                    </div>
                                </div></div>
                        </div>

                        {/* Right side - Question setup  */}
                        <div className={style.grid8}>
                            <div className={style.quizSetting}>
                                <div className={style.quizListActionWrapper}>
                                    <Stack spacing={2} direction="row" justifyContent="space-between">
                                        <Stack spacing={2} direction="row" justifyContent="flex-end">
                                            <Button variant="outlined" onClick={() => onAddQuestion('MC')}>Add MC</Button>
                                            <Button variant="outlined" onClick={() => onAddQuestion('LONG')}>Add Long Questions</Button>
                                        </Stack>
                                        <Button variant="contained" onClick={onSaveQuizForm}>Save</Button>
                                    </Stack>
                                </div>
                                <div className='divider'></div>
                                {/* Loop out the question list  */}
                                <div className={style.questionList}>
                                    {questionList && answerList && questionList.map((question, OuterIndex) => {
                                        return (
                                            <>
                                                <div className={style.questionItem}>
                                                    <div>Question {OuterIndex + 1}</div>
                                                    <div className={style.questionListQuestion}>
                                                        <input
                                                            type="text"
                                                            value={question.question}
                                                            onChange={(e) => handleQuestionChange(e, OuterIndex)}
                                                        />
                                                    </div>

                                                    {
                                                        question.type === 'MC' ?
                                                            <div className={style.questionListMCItem}>
                                                                {question.options.map((option, InnerIndex) => {
                                                                    return (
                                                                        <div className={style.questionListMCItemOption}>
                                                                            <span className={style.smallLabel}>Option {InnerIndex + 1}</span>
                                                                            <input
                                                                                type="text"
                                                                                value={option}
                                                                                onChange={(e) => handleOptionChange(e, OuterIndex, InnerIndex)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                                <span className={style.smallLabel}>Answer</span>
                                                                <select value={answerList[OuterIndex].answer} onChange={(e) => handleAnswerChange(e, OuterIndex)}>

                                                                    {
                                                                        question.options.map((option, index) => {
                                                                            return (
                                                                                <option value={option}>{option}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div> : null
                                                    }
                                                    <Button onClick={() => removeThisQuestion(OuterIndex)}>Remove</Button>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default QuizPage