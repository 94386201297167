import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade, TextField, Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useCloneIntakeMutation,
    useGetIntakeListQuery, useGetIntakeMutation, useGetIntakeStudentListMutation,
    useLazyGetIntakeListQuery, useUpdateExamScoreMutation
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate} from "react-router-dom";
import {delay} from "../../../../utils/util";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {statusColor} from "../IntakeEnrollList/IntakeEnrollList";
import {
    useAddCourseTutorMutation,
    useDeleteCourseTutorMutation,
    useGetTutorsMutation, useLazyGetTutorQuery
} from "../../../../api/courseTutorApi";
import noneAvator from "../../../Setting/images/none.png";
import noneAvatar from "../../../Setting/images/none.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}


export default function TutorSelectionList({ courseId, intake}) {

    const [fetchTutorList] = useLazyGetTutorQuery();
    const [fetchIntake] = useGetIntakeMutation()
    const [addCourseTutor] = useAddCourseTutorMutation();
    const [removeCourseTutor] = useDeleteCourseTutorMutation();
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    console.log("rowSelectionModel", rowSelectionModel)
    const refresh = () => {
        Promise.all([
            fetchIntake({courseId, intake}),
            fetchTutorList(true)
            ]).then(([intakeData, tutorData]) => {
            const intakeTutor = intakeData.data.data.tutor;
            let { data } = tutorData.data;
            data = data
                .filter(item => !item.disable)
                .map((item) => {
                return {
                    ...item,
                    selected: intakeTutor.some((tutor) => tutor.id === item.id)
                }
            });
            setData(data)
            setRowSelectionModel(data.filter((item) => item.selected).map((item) => item.id));
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    const addTutor = (tutorId) =>{
        addCourseTutor({
            courseId,
            intake,
            tutorId
        });
    }

    const deleteTutor = (tutorId) =>{
        removeCourseTutor({
            courseId,
            intake,
            tutorId
        });
    }


    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Name",
            renderCell: (params) => {
                return <Typography variant={"body2"} fontWeight={"bold"}>{params.row.nameEn} {params.row.nameZh || ""} </Typography>;
            },
            width: 320
        },
        {
            field: "title",
            ...defaultColumnType,
            headerName: "Title",
            flex: 1
        },

        {
            field: "Avatar",
            ...defaultColumnType,
            headerName: "Avatar",
            renderCell: (params) => {
                return (
                    params.row.thumbnailPath ?
                        <Box
                            sx={{
                                width: 64,
                                height: 64,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '4px solid rgba(46, 125, 50, 0.08)', // Adjust the border color and width as needed
                            }}
                        >
                            <img
                                src={params.row.thumbnailPath || noneAvatar}
                                alt="avatar"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                        :
                        <AccountCircleIcon sx={{fontSize: 64, opacity: 0.5 }}/>
                )
            },
            width: 150
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: {paginationModel: { pageSize: 10 } },
    }

    const onRowClick = ({ row }) => {
        navigate(`/tutor/${row.id}`, {
            state: { row }
        });
    };

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                    columnHeaders: () => null,
                }}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    const added = newRowSelectionModel.find(id => !rowSelectionModel.includes(id));
                    const removed = rowSelectionModel.find(id => !newRowSelectionModel.includes(id));

                    if (added) {
                        addTutor(added)
                    }
                    if (removed) {
                        deleteTutor(removed)
                    }
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                initialState={initState}
                rowHeight={80}
                onRowClick={onRowClick}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                pageSizeOptions={[10]}
                loading={data === null}
                pagination
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
