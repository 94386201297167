import { mainRequest, mainBaseURL } from './request'

export const downloadDocument = (path) =>
    mainRequest.post(
        `/document/download`,
        {
            path,
        },
        {
            responseType: 'blob',
        }
    )

export const downloadDocumentToLocal = async (doc) => {
    try {
        let file = await downloadDocument(doc.path)
        const e = document.createElement('a')
        e.href = URL.createObjectURL(file.data)
        e.download = doc.documentName
        e.click()
    } catch (error) {
        console.log('Download document error : ', error)
    }
}