import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Button,
    Box,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material'

import style from './Testing.module.scss'

const Testing = () => {
    const formRef = useRef()

    const [testForm, setTestForm] = useState({
        name: '',
        email: '',
    })
    const onSubmitClick = (e) => {
        e.preventDefault()
        let validate = formRef.current.reportValidity()
        console.log('validate : ', validate)
        console.log('testForm : ', testForm)
    }
    return (
        <form ref={formRef}>
            <TextField
                label="Name"
                placeholder="Name"
                value={testForm.name}
                onChange={(e) => setTestForm({ ...testForm, name: e.target.value })}
                required
            />

            <TextField
                label="Email"
                placeholder="Email"
                value={testForm.email}
                onChange={(e) => setTestForm({ ...testForm, email: e.target.value })}
            />

            <Button type="submit" onClick={onSubmitClick}>
                Submit
            </Button>
        </form>
    )
}

export default Testing
