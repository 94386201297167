import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade, TextField, InputLabel, Select, MenuItem, FormControl, CircularProgress, Typography, Link
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useGetIntakeMutation, useGetPreviousIntakeClassesMutation,
    useLazyGetRecordingZipQuery,
    useUpdatePreviousClassMutation
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate, useParams} from "react-router-dom";
import {delay} from "../../../../utils/util";
import PollIcon from '@mui/icons-material/Poll';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useLazyGetZoomJoinUrlQuery} from "../../../../api/courseGeneralApi";
import {useDispatch} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import {getWeeks} from "../../../../utils/request/course";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

export default function ClassInfoList({ courseId, intake, editClass }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRole = localStorage.getItem('userRole');

    const [getRecordingZip] = useLazyGetRecordingZipQuery();
    const [downloadingStatus, setDownloadingStatus] = useState({});
    const [getZoomJoinUrl] = useLazyGetZoomJoinUrlQuery()
    const [data, setData] = useState(null);
    const [currentWeeks, setCurrentWeeks] = useState(null)
    const [getPreviousClasses] = useGetPreviousIntakeClassesMutation();
    const [previousClass, setPreviousClass] = useState({});
    const [fetchCourseIntake] = useGetIntakeMutation();
    const [tutorList, setTutorList] = useState([]);
    useEffect(() => {

        fetchCourseIntake({courseId, intake}).then((res) => {
            setTutorList(res.data.data.tutor);
        });

        getWeeks(courseId, intake).then((result) => {
            const weekData = result.data.data;
            setCurrentWeeks(weekData)

            const flattenedClasses = weekData?.weeks?.flatMap(week => week.classes)
                .sort((a, b) => a.startDate - b.startDate);
            console.log("result", flattenedClasses);
            if(intake > 1) {
                getPreviousClasses({
                    courseId,
                    intake: intake - 1
                }).then(({ data: { data } }) => {
                    setPreviousClass(data);
                });
            }

            if (userRole === 'TUTOR') {
                const onlineClasses = flattenedClasses.filter((res) => res.zoomId);
                for (const onlineClass of onlineClasses) {
                    getZoomJoinUrl(Number(onlineClass.zoomId)).then((res) => {
                        if (res.data) {
                            weekData.weeks.forEach((week) => {
                                week.classes.forEach((cls) => {
                                    if (cls.zoomId === onlineClass.zoomId) {
                                        cls.zoomStartURL = res.data.data.url;
                                    }
                                });
                            });
                        }
                    });
                }
            }
            setData(flattenedClasses);

        });
    }, [intake]);

    const handleDownload = async (meetingId) => {
        setDownloadingStatus(prevStatus => ({ ...prevStatus, [meetingId]: true })); // Start downloading for specific meetingId
        const { data, isError } = await getRecordingZip(Number(meetingId));
        if (data) {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = url;
            a.download = "recordings.zip"; // or the name you want to save it as
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
        if(isError){
            alert("No recording in the class");
        }
        setDownloadingStatus(prevStatus => ({ ...prevStatus, [meetingId]: false })); // Finish downloading for specific meetingId
    };



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "classDetail",
            ...defaultColumnType,
            headerName: "Class Information",
            renderCell: (params) => {
                const ClassChip = () => {
                    let color;
                    let label = params.row.type;
                    switch (params.row.type) {
                        case 'ONLINE':
                            color = 'success';
                            break
                        case 'OFFLINE':
                            color = 'default';
                            break;
                        case 'EXAM':
                            color = 'secondary';
                            break;
                        case 'ONLINE_EXAM':
                            color = 'primary';
                            label = 'EXAM';
                            break;
                        default:
                            color = "secondary";
                    }
                    return (
                            <Chip label={label} color={color} variant="outlined" sx={{ blockSize: '90%', mr: 1}}/>

                    )
                }

                return (
                    <Stack spacing={1} >
                        <Stack direction="row">
                            <Typography variant="body1" fontWeight="bold">{params.row.name} </Typography>
                            {params.row.previousClassId > 0 &&
                            <Typography variant="caption" mt={0.25}>
                                    <NavigateNextIcon fontSize={"small"}  />
                                    <Link
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            navigate(`/intake/${courseId}/${intake -1}`)
                                        }}
                                    >
                                        {previousClass[params.row.previousClassId]}
                                    </Link>
                                <Chip color={"success"} sx={{ blockSize: 15, fontSize: '90%'}} style={{ marginLeft: 8, marginTop: 0.5}} label={`Intake ${intake -1}`} />
                                </Typography>
                            }
                        </Stack>
                        <span>
                            <ClassChip sx={{ blockSize: 5}} />
                            {moment(params.row.startDate).format("YYYY-MM-DD HH:mm")} - {moment(params.row.endDate).format("hh:mm")}
                            {params.row.type.includes('ONLINE')  &&
                             <>
                             {params.row.zoomStartURL ?
                            <IconButton color={"success"}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        window.open(params.row.zoomStartURL)
                                    }}>
                                <PlayCircleFilledWhiteIcon/>
                            </IconButton>
                                 :
                                 <CircularProgress size={18} sx={{ ml: 1 }}  />
                             }
                            {userRole !== 'TUTOR' &&
                             <IconButton
                                 color={"warning"}
                                 onClick={(event) => {
                                     event.stopPropagation()
                                     handleDownload(params.row.zoomId)
                                 }}
                                 disabled={downloadingStatus[params.row.zoomId]}
                             >
                                 {downloadingStatus[params.row.zoomId] ? <CircularProgress size={18} sx={{ ml: -1 }}  /> : <DownloadForOfflineIcon sx={{ ml: -1.5 }} />}
                             </IconButton>
                            }
                            </>
                            }

                        </span>
                        <span> {params.row.venue || (params.row.type.includes('ONLINE') ? "Zoom Meeting: " + params.row.zoomId  : "N/A")}</span>
                    </Stack>
                )
            },
            flex: 2
        },
        {
            field: "extra",
            ...defaultColumnType,
            renderCell: (params) => {
                const assignedTutorId = params.row.assignedTutorId;
                return (
                    <Stack direction="row" spacing={10}>
                        <Stack spacing={1}>
                            <Typography variant="body2" fontWeight={"bold"}>Assigned Tutor</Typography>
                            <Typography variant="body2">{assignedTutorId > 0 ? tutorList.find(item => item.id === assignedTutorId)?.nameEn : "N/A"}</Typography>
                        </Stack>
                    </Stack>
                )
            },
            width: 200
        },
        {
            field: "action",
            ...defaultColumnType,
            headerName: "",
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '98%' }}>
                         <ArrowForwardIosIcon color={"disabled"} fontSize={"medium"} />
                    </Box>
                )
            },
            width: 20,
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 1000 } },
    }

    const onRowClick = ({ row }) => {
        const assignedTutorId = row.assignedTutorId;
        dispatch(setIntakeTab({
            primary: 'Class Detail',
            route: 'Participation',
            state: {
                ...row,
                editClass,
                tutorName: assignedTutorId > 0 ? tutorList.find(item => item.id === assignedTutorId)?.nameEn : "N/A"
            },
        }))
    }
    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                    columnHeaders: () => null,
                }}
                onRowClick={onRowClick}
                initialState={initState}
                rowHeight={130}
                disableRowSelectionOnClick
                getRowId={(row) => row.classId}
                pageSizeOptions={[1000]}
                disableColumnMenu
                pagination
                hideFooterPagination
                hideFooterSelectedRowCount
                loading={data === null}
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
