import React, {useContext} from 'react';
import styles from './Topbar.module.scss';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Button, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {AdminContext} from "../../../utils/context/adminContext";
import LogoutIcon from '@mui/icons-material/Logout';
import {useSelector} from "react-redux";
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const Topbar = () => {
    const navigate = useNavigate()
    const { admin, setAdmin } = useContext(AdminContext)
    const {userProfile} = useSelector((state) => state.auth);
    const logout = () => {
        setAdmin(null);
        localStorage.clear();
        navigate('/login')
    }

    return (
        <div className={styles.topbarContainer}>
            <div style={{ marginLeft: 25, fontSize: '90%'}}>
                <strong>GA</strong> Version
            </div>
            <Stack direction="row" spacing={1}>
                <div style={{marginTop: 10}}>
                    <Stack direction="row" spacing={1}>
                    <span><strong>Hi,</strong></span>
                    <span>{userProfile?.email} | </span>
                        <Link onClick={logout}>Logout</Link>
                    </Stack>

                </div>
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        variant="dot"
                        label={"123"}
                    >
                        <IconButton
                            variant="contained"
                            onClick={() => navigate("/setting")}
                        >
                            <AccountCircleIcon sx={{fontSize: 40}}/>
                        </IconButton>
                    </StyledBadge>
            </Stack>
        </div>
    );
}

export default Topbar;
