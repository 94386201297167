import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
export const publicApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_MAIN_API_URL}`,
    }),
    reducerPath: "publicApi",
    endpoints: builder => ({
        getPublicHoliday: builder.query({
            query: (param) => ({
                url: `/public/getPublicHolidays`,
            })
        }),
    }),
});

export const {
    useGetPublicHolidayQuery,
} = publicApi;
