
import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import {useEffect, useState} from "react";
import {useLazyGetPollsQuery} from "../../../../api/courseIntakeApi";
import {useNavigate, useParams} from 'react-router-dom';
import PollInventoryList, {CustomColumnMenu, defaultColumnType} from "../../../PollInventory/PollInventoryList";
import moment from "moment";
import {formatDistanceToNow} from "date-fns";
import {Breadcrumbs, Button, Chip, IconButton, Typography} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useGetGeneralCourseQuery} from "../../../../api/courseIntakeApi";
import Fade from "@mui/material/Fade";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImportPoll from "./ImportPoll";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from '@mui/icons-material/Add';
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
export default function ClassPolls({ courseId, intake}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const param = useParams();
    const [fetchPolls] = useLazyGetPollsQuery();
    const [data, setData] = useState(null);
    const [toggle, setToggle] = React.useState(false);
    const { intakeTab } = useSelector((state) => state.intake);
    const { state: { row } } = intakeTab;

    const handleToggle = () => {
        setToggle(!toggle);
    };
    const refresh = () => {
        fetchPolls(row.zoomId).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    };

    useEffect(() => {
        refresh();
    }, [toggle]);



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "title",
            ...defaultColumnType,
            headerName: "Poll Name",
            flex: 1.5
        },
        {
            field: "pollId",
            ...defaultColumnType,
            headerName: "ID",
            flex: 1
        },
        {
            field: "questions",
            ...defaultColumnType,
            headerName: "Questions",
            renderCell: (params) => {
                return JSON.parse(params.row.questions).length;
            },
            flex: 0.5
        },

    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }


    const onRowClick = (params) => {
        const defaultState = {
            primary: 'Class Detail',
            secondary: 'Class and Exam',
            route: 'Manage Classes',
            state: {
                meetingId: row?.zoomId,
                pollId: params?.row?.pollId || 'Create',
            }
        }
        dispatch(setIntakeTab({
            ...defaultState,
            subRoute: 'EditPoll'
        }));
    };

    // Updated component with UI/UX enhancements
    return (



        <Box sx={{ width: '100%', mt: 3, borderRadius: 5, backgroundColor: 'white' }}>
                <ImportPoll courseId={courseId} row={row} toggle={toggle} handleToggle={handleToggle} />
            <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"} p={4} pb={0}>
                <Stack direction="row" alignContent="center" spacing={2}>
                    <IconButton onClick={() => dispatch(setIntakeTab(intakeTab.previousTab ))}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="body1" fontWeight="bold">{row.name}</Typography>
                            <Chip label={row.type} color={row.type === 'ONLINE' ? 'success' : 'default'} variant="outlined" sx={{ blockSize: '90%', mr: 1 }} />
                        </Stack>
                        <Typography>
                            {moment(row.startDate).format("YYYY-MM-DD hh:mm")} - {moment(row.endDate).format("hh:mm")}
                        </Typography>
                    </Stack>
                </Stack>
                <Box>
                    <Stack direction="row" spacing={2}>
                    <Button color={"inherit"} startIcon={<CloudUploadIcon  />} onClick={handleToggle}>
                        IMPORT
                    </Button>
                    <Button color={"inherit"} startIcon={<AddCircleOutlineIcon  />} onClick={onRowClick}>
                        NEW
                    </Button>
                    </Stack>
                </Box>
        </Stack>
        <Box sx={{width: '100%', mt: 2, position: 'relative'}}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                getRowId={(row) => row.pollId}
                loading={data === null}
                disableRowSelectionOnClick
                    pageSizeOptions={[5]}
                    pagination
                    onRowClick={onRowClick}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer', // Change mouse cursor to pointer
                        },
                        // ... existing styles ...
                    }}
                />
            </Box>
            </Box>

    );
}
