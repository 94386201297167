import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import General from "./Tabs/General/General";
export default function SettingTab() {
    const [tab, setTab] = React.useState('general');

    const handleChange = (event, value) => {
        setTab(value);
    };

    return (
        <Box sx={{p: 5, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} >
                        <Tab label="My Profile" value="general" />
                        <Tab label="Billing" value="billing" disabled/>
                        <Tab label="Password" value="password" disabled/>
                    </TabList>
                </Box>
                <TabPanel value="general">
                    <General />
                </TabPanel>
                <TabPanel value="password">Password</TabPanel>
            </TabContext>

        </Box>
    );
}

