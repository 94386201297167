import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const courseIntakeApi = createApi({
    baseQuery,
    reducerPath: "courseIntakeApi",
    endpoints: builder => ({
        getGeneralCourse: builder.query({
            query: (param) => ({
                url: `/course/getGeneral?courseId=${param.courseId}`,
            })
        }),
        getIntakeList: builder.query({
            query: (params) => `/course/getIntakeList`,
        }),
        getIntake: builder.mutation({
            query: (body) => ({
                url: "/course/getIntake",
                method: "POST",
                body,
            })
        }),
        cloneIntake: builder.mutation({
            query: (body) => ({
                url: "/course/cloneCourse",
                method: "POST",
                body,
            })
        }),
        editClass: builder.mutation({
            query: (body) => ({
                url: "/course/editClass",
                method: "POST",
                body,
            })
        }),
        getIntakeStudentList: builder.mutation({
            query: (body) => ({
                url: "/student/intakeStudentList",
                method: "POST",
                body,
            })
        }),
        updateExamScore: builder.mutation({
            query: (body) => ({
                url: "/enroll/updateExamScore",
                method: "POST",
                body,
            })
        }),
        getPreviousIntakeClasses: builder.mutation({
            query: (body) => ({
                url: "/makeup/getPreviousClasses",
                method: "POST",
                body,
            })
        }),
        updatePreviousClass: builder.mutation({
            query: (body) => ({
                url: "/makeup/updatePreviousClass",
                method: "POST",
                body,
            })
        }),
        getAssociateClasses: builder.query({
            query: (param) => ({
                url: `/course/getAssociatedClasses?weekId=${param.weekId}`,
            })
        }),
        getPolls: builder.query({
            query: (id) => ({
                url: `/zoom/getPolls/${id}`,
            })
        }),
        getPoll: builder.query({
            query: (data) => ({
                url: `/zoom/getPolls/${data.meetingId}/${data.pollId}`,
            })
        }),
        savePoll: builder.mutation({
            query: ({ id, body }) => ({
                url: `/zoom/savePoll/${id}`,
                method: "POST",
                body,
            })
        }),
        deletePoll: builder.mutation({
            query: (data) => ({
                url: `/zoom/deletePoll/${data.meetingId}/${data.pollId}`,
                method: "DELETE",
                body: {},
            })
        }),
        getIntakeEnrollList: builder.mutation({
            query: (body) => ({
                url: "/enroll/intakeEnrollList",
                method: "POST",
                body,
            })
        }),
        getRecordingZip: builder.query({
            query: (meetingId) => ({
                url: `/zoom/downloadRecording/${meetingId}`,
                responseHandler: ((response) => response.blob())
            })
        }),
        getStudentParticipant: builder.mutation({
            query: (body) => ({
                url: `/report/zoom/report/participant`,
                method: "POST",
                body,
            })
        }),
        getStudentPoll: builder.mutation({
            query: (body) => ({
                url: `/report/zoom/report/polls`,
                method: "POST",
                body,
            })
        }),
        getMeetingDetail: builder.mutation({
            query: (body) => ({
                url: `/report/zoom/report`,
                method: "POST",
                body,
            })
        }),
        getMeeting: builder.query({
            query: (meetingId) => ({
                url: `/zoom/getMeeting/${meetingId}`,
            })
        }),
        updateMeeting: builder.mutation({
            query: ({ id, body }) => ({
                url: `/zoom/updateMeeting/${id}`,
                method: "POST",
                body,
            })
        }),
        updateIntake: builder.mutation({
            query: (body) => ({
                url: "/course/updateIntake",
                method: "POST",
                body,
            })
        }),
        createNextIntake: builder.mutation({
            query: (body) => ({
                url: "/course/createNextIntake",
                method: "POST",
                body,
            })
        }),
    }),
});

export const {
    useGetGeneralCourseQuery,
    useLazyGetIntakeListQuery,
    useGetIntakeMutation,
    useCloneIntakeMutation,
    useEditClassMutation,
    useGetIntakeStudentListMutation,
    useUpdateExamScoreMutation,
    useGetPreviousIntakeClassesMutation,
    useUpdatePreviousClassMutation,
    useLazyGetAssociateClassesQuery,
    useLazyGetPollsQuery,
    useLazyGetPollQuery,
    useSavePollMutation,
    useDeletePollMutation,
    useGetIntakeEnrollListMutation,
    useLazyGetRecordingZipQuery,
    useGetStudentParticipantMutation,
    useGetStudentPollMutation,
    useGetMeetingDetailMutation,
    useLazyGetMeetingQuery,
    useUpdateMeetingMutation,
    useUpdateIntakeMutation,
    useCreateNextIntakeMutation,
} = courseIntakeApi;
