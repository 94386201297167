import React, { useState, useEffect } from 'react';
import {
 Fade, Chip, Typography,
} from '@mui/material'

import Box from "@mui/material/Box";
import StyledTableOne from "../../components/StyledTable/StyledTableOne";
import {useNavigate} from "react-router-dom";
import noneAvatar from "../Setting/images/none.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useLazyGetAdminListQuery} from "../../api/settingApi";

const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

const Admin = () => {

    const [data, setData] = useState(null)
    const navigate = useNavigate();
    const [getAdmin] = useLazyGetAdminListQuery();

    const columns = [
        {
            field: "Avatar",
            ...defaultColumnType,
            headerName: "Admin",
            renderCell: (params) => {
                return (
                    params.row.thumbnailPath ?
                        <Box
                            sx={{
                                width: 64,
                                height: 64,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: '4px solid rgba(46, 125, 50, 0.08)', // Adjust the border color and width as needed
                            }}
                        >
                            <img
                                src={params.row.thumbnailPath || noneAvatar}
                                alt="avatar"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                        :
                        <AccountCircleIcon sx={{fontSize: 64, opacity: 0.5 }}/>
                )
            },
            width: 150
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Name",
            renderCell: (params) => {
                return <Typography variant={"body2"} fontWeight={"bold"}>{params.row.nameEn}</Typography>;
            },
            width: 200
        },
        {
            field: "username",
            ...defaultColumnType,
            headerName: "Email",
            flex: 1,
        },
        {
            field: "mobile",
            ...defaultColumnType,
            headerName: "Mobile",
            width: 190
        },
        {
            field: "roleName",
            ...defaultColumnType,
            headerName: "Role",
            width: 160
        },
        {
            field: "disable",
            ...defaultColumnType,
            headerName: "",
            renderCell: (params) => {
                const isEnable = params.row.enabled;
                return (
                    <Chip color={isEnable ? "success" : "default"}
                          sx={{ blockSize: 20}}
                          style={{ marginLeft: 8, marginTop: 1}}
                          label={`${isEnable ? 'Active' : 'Inactive'}`}
                    />
                )
            },
            width: 150
        },
    ];

    const init =  () => {
        getAdmin(true).then(({ data }) => {
            let { data: content } = data;
            setData(content);
        });
    }
    useEffect(() => {
            init();
    }, [])


    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "brokerId": false
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
    }

    const onRowClick = ({ row }) => {
        navigate(`/admin/${row.userId}`, {
            state: { row }
        });
    };

    return (
        <Fade in={true}>
            <Box sx={{ width: '100%'}}>
                <StyledTableOne
                    autoHeight
                    rows={data || []}
                    columns={columns}
                    slots={{
                        columnMenu: () => null,
                    }}
                    initialState={initState}
                    rowHeight={80}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.userId}
                    pageSizeOptions={[10]}
                    loading={data === null}
                    pagination
                    onRowClick={onRowClick}
                    sx={{
                        border: 'none'
                    }}
                />
            </Box>
        </Fade>
    )

}

export default Admin
