import IntakeDetail from "./IntakeDetail";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../../../api/courseIntakeApi";
import Box from "@mui/material/Box";
export default function IntakeDetailPage() {
    const { courseId, intake } = useParams();
    const navigate = useNavigate();
    const { data } = useGetGeneralCourseQuery({ courseId });
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb onClick={() => navigate("/intake")} label="Intake" icon={<Icon fontSize={"small"}>playlist_add</Icon>} />
                    <StyledBreadcrumb label={data?.data ? `${data?.data?.prefixKey}-${intake}` : '...'} />
                </Breadcrumbs>
            </Box>
            <IntakeDetail courseGeneral={data?.data} />
        </Box>
    )
}
