import React, { createContext, useState, useEffect } from 'react'
import { mainRequest, uaaRequest, getToken } from '../request/request'
import { useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_ADMIN } from '../constants'

export const AxiosContext = createContext()

export const AxiosWrapper = ({ children }) => {
    const navigate = useNavigate()

    useEffect(() => {
        mainRequest.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = getToken()
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        // for future usage
        mainRequest.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                console.log('mainRequest interceptors error : ', error.response)
                if (error.response.status === 401) {
                    alert('Session expired. Please login again')
                    localStorage.removeItem(LOCAL_STORAGE_ADMIN)
                    // navigate("/login");
                    window.location = '/login'
                }
                return Promise.reject(error)
            }
        )

        // for future usage
        uaaRequest.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    }, [])

    return <AxiosContext.Provider value={{ mainRequest, uaaRequest }}>{children}</AxiosContext.Provider>
}

export default AxiosContext
