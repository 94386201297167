import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    intakeTab: {
        route: 'Enrollment',
        state: null,
    },
};

export const intakeSlice = createSlice({
    name: "intake",
    initialState,
    reducers: {
        setIntakeTab(state, action) {
            return {
                ...state,
                intakeTab: {
                    primary: action.payload.route,
                    secondary: action.payload.subRoute,
                    previousTab: state.intakeTab,
                    ...action.payload,
                },
            }
        }
    }
});

export const {actions: {setIntakeTab}} = intakeSlice;
