import React, {useState, useEffect, useContext, useCallback} from 'react'
import {
    Dialog,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    IconButton,
    Stack,
    Checkbox,
    FormGroup,
    FormControlLabel,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    Chip,
    Icon,
    Typography,
    Link,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    CircularProgress,
} from '@mui/material'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import { updateIntakeStatus, cloneIntake, getWeeks } from '../../../../utils/request/course'
import { NavbarContext } from '../../../../utils/context/navbarContext'
import Loading from '../../../Loading/Loading'
import { getAttendanceByIntake, createAttendance } from '../../../../utils/request/attendance'
import { getEnrollmentRecordByIntake } from '../../../../utils/request/enrollment'
import { getStudentInfoByListOfIds } from '../../../../utils/request/student'
import { sendWhatsapp } from '../../../../utils/request/message'
import style from './Intake.module.scss'
import {
    useEditClassMutation,
    useGetIntakeMutation,
    useGetPreviousIntakeClassesMutation, useUpdatePreviousClassMutation
} from "../../../../api/courseIntakeApi";
import {useGetTutorsMutation} from "../../../../api/courseTutorApi";
import IntakeStudentList from "../IntakeStudentList/IntakeStudentList";
import {useCreateStudentAttendanceMutation, useGetMakeupClassAttendanceMutation} from "../../../../api/attendanceApi";
import ClassInfoList from "../ClassInfo/ClassInfoList";
import DocumentPage from "../../../Document/DocumentPage";
import IntakeEnrollList from "../IntakeEnrollList/IntakeEnrollList";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import TutorSelectionList from "../TutorSelection/TutorSelectionList";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IntakeEdit from "../EditIntake/IntakeEdit";
import ParticipationList from "../Participation/ParticipationList";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import {useDispatch, useSelector} from "react-redux";
import {IOSSwitch} from "../../../../components/Switcher";
import EditMaterial from "../../MasterCourse/EditMaterial/EditMaterial";
import AssignmentEdit from "../../MasterCourse/EditMaterial/AssignmentEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteIntakeMutation} from "../../../../api/removeApi";
const INTAKE_STATUS_ACTIVE = 'ACTIVE'
const INTAKE_STATUS_INACTIVE = 'INACTIVE'
const INTAKE_STATUS_PUBLISHED = 'PUBLISHED'

const statuses = [INTAKE_STATUS_PUBLISHED, INTAKE_STATUS_ACTIVE, INTAKE_STATUS_INACTIVE];

const StyledToggleButtonGroup = (props) => {
    const { value, onChange, disabled } = props;

    return (
        <ToggleButtonGroup
            color="success"
            value={value}
            exclusive
            onChange={onChange}
            aria-label="Platform"
            disabled={disabled}
            sx={{ height: 35}}
        >

            {statuses.map((status) => (
                <ToggleButton key={status} value={status} aria-label={status}>
                    {status}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

const IntakeDetail = ({ courseGeneral}) => {

    const { courseId, intake } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { intakeTab } = useSelector((state) => state.intake);
    const [fetchCourseIntake] = useGetIntakeMutation();
    const [editClass] = useEditClassMutation();
    const [currentRecord, setCurrentRecord] = useState(null);
    const userRole = localStorage.getItem('userRole');
    const [intakeExpanded, setIntakeExpanded] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [deleteIntake] = useDeleteIntakeMutation();

    const intakeInfo = [
        {
            displayName: 'Enrollment',
            backendName: 'Enrollment',
            icon: 'person_add',
        },
        {
            displayName: 'Enrolled Students',
            backendName: 'Enrolled Students',
            icon: 'person_search',
        },
        {
            displayName: 'Tutor Selection',
            backendName: 'Tutor Selection',
            icon: 'support_agent',
        },
        {
            displayName: 'Class Detail',
            backendName: 'Class Detail',
            icon: 'cast_for_education',
        },
        {
            displayName: 'Course Documents',
            backendName: 'Course Documents',
            icon: 'content_paste_search',
        }
    ]

    const [intakeList, setIntakeList] = useState([])

    const [showIntakeDetails, setShowIntakeDetails] = useState(false)

    const [loading, setLoading] = useState(false)

    const { setShowNavbar } = useContext(NavbarContext)

    const navigate = useNavigate()


    useEffect(() => {
        if(location?.state?.tab && location.state.tab !== intakeTab.route) {
            dispatch(setIntakeTab({ route: location.state.tab }));
        }
    }, [location?.state?.tab]);



    const refresh = async () => {
        const { data } = await fetchCourseIntake({
            courseId,
            intake
        });
        setCurrentRecord(data.data);

        setShowIntakeDetails(true)
    }

    useEffect(() => {
        setShowNavbar(true);
    }, [])


    useEffect(() => {
        refresh().finally();
    }, [courseId, intake]);

    const onManageClassChange = () => {
        console.log("intakeTab.route", intakeTab.route)
        if(intakeTab.route !== "Manage Classes") {
            dispatch(setIntakeTab({
                primary: 'Class Detail',
                route: "Manage Classes",
                subRoute: "Class and Exam",
                previousTab: {
                    ...intakeTab,
                    route: 'Class Detail'
                },
            }));
        }else{
            dispatch(setIntakeTab({route: "Class Detail" }));
        }
    }

    const onIntakeStatusChange = async (e) => {
        let newStatus = e.target.value
        try {
            setCurrentRecord({ ...currentRecord, status: newStatus })
            let result = await updateIntakeStatus(currentRecord.courseId, currentRecord.intake, newStatus)
            console.log('Update intake status successfully : ', result)
            let index = intakeList.indexOf(currentRecord)
            let newList = [...intakeList];
            newList[index].status = newStatus;
            setIntakeList(newList);

        } catch (error) {
            console.log('Update intake status error : ', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if(userRole === 'TUTOR' &&  intakeTab.route === 'Enrollment') {
            dispatch(setIntakeTab({ route: 'Enrolled Students' }));
        }
    }, [intakeTab.route]);




    const InfoNavItem = React.memo(({ isActive, onClick, displayName, icon }) => {
        const itemClass = isActive ? `${style.infoNavItem} ${style.active}` : style.infoNavItem;

        return (

            <div className={itemClass} onClick={onClick}>
                <Icon fontSize={"small"} sx={{mr: 1, color: '#b5bbbe'}}>{icon}</Icon>
                {displayName}
            </div>
        );
    });

    const InfoNavbar = () => {
        const handleClick = useCallback(
            (backendName) => {
                dispatch(setIntakeTab({ route: backendName }));
            },
            [setIntakeTab]
        );

        return (
                <Box sx={{p: 5, pt: 1,  mt: 3, mb: -6, borderTopLeftRadius: 20 , borderTopRightRadius: 20, backgroundColor: 'white'}}>
                <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                    <Box className={style.infoNavbar}>
                    {intakeInfo
                        .filter((info) => {
                            if(userRole === 'TUTOR') {
                                return ['Enrolled Students', 'Class Detail'].includes(info.backendName)
                            }
                            return true
                        })
                        .map((info, index) => (
                        <InfoNavItem
                            key={info.backendName} // Prefer unique ids over index if possible
                            isActive={[intakeTab.primary, intakeTab.route].includes(info.backendName)}
                            onClick={() => handleClick(info.backendName)}
                            displayName={info.displayName}
                            icon={info.icon}
                        />
                    ))}
                </Box>
                    <Box mt={4}>
                        <Stack spacing={1} direction="row" >
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} onChange={onManageClassChange} checked={intakeTab.route === "Manage Classes"}/>}
                                label="Manage Classes"
                            />
                        </Stack>
                    </Box>
                </Stack>
                </Box>
        );
    };

    const renderCurrentRecord = () => {
        switch (intakeTab.route) {
            case 'Enrollment':
                return (
                    <IntakeEnrollList courseId={courseId} intake={intake} />
                )
            case 'Enrolled Students':
                return (
                    <IntakeStudentList courseId={courseId} intake={intake} />
                )
            case 'Tutor Selection':
                return (
                    <TutorSelectionList courseId={courseId} intake={intake} />
                )
            case 'Class Detail':
                return (
                    <ClassInfoList  courseId={courseId} intake={intake} editClass={editClass}  />
                )
            case 'Participation':
                return (
                    <ParticipationList state={intakeTab.state}/>
                )
            case 'Course Documents':
                return (
                    <DocumentPage />
                )
            case 'Manage Classes':
                return (
                    <EditMaterial />
                )
        }
    }
    return (
        <div className={style.parentContainer}>
            {loading && <Loading />}
            {showIntakeDetails && currentRecord && (
                <div className={style.detailRecordContainer}>
                    <Box sx={{ p: 5, borderRadius: 5, backgroundColor: 'white'}}>
                        <Box>
                            <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                                <Stack direction="row" spacing={1}  ml={0.5}>
                                <Typography variant="h6">
                                    {courseGeneral?.nameEng || courseGeneral?.name}
                                </Typography>
                                    <IconButton
                                        onClick={() => navigate(`/course/${courseId}`)}
                                        size="small"
                                    >
                                    <ModeEditIcon sx={{ mt: -0.5}}/>
                                    </IconButton>
                                </Stack>
                            <Box mt={-2}>
                            {isRemoving ?
                                <CircularProgress size={18} /> :
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        if (window.confirm("Are you sure to remove? It will delete all the intake related data.")) {
                                            setIsRemoving(true);
                                            deleteIntake({courseId: currentRecord.courseId, intake: currentRecord.intake}).then(() => {
                                                setIsRemoving(false);
                                                navigate(`/intake`)
                                            });
                                        }
                                    }}
                                    size="small"

                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                            </Box>
                            </Stack>
                            <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                                <Stack direction="row" spacing={2} mt={1} mb={2}>
                                    <Box>
                                        <Stack direction="row" spacing={1} mt={1}>
                                        {courseGeneral?.mondaySync ?
                                            <CheckCircleIcon color={'success'} />
                                            :
                                            <CancelIcon color={'error'} />
                                        }
                                        <Typography variant="body2" pt={0.3}>Monday Sync</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} mt={1}>
                                            {courseGeneral?.wordpressSync ?
                                                <CheckCircleIcon color={'success'} />
                                                :
                                                <CancelIcon color={'error'} />
                                            }
                                            <Typography variant="body2" pt={0.3}>WordPress Sync</Typography>
                                        </Stack>
                                    </Box>
                                    <Box>
                                        <Stack direction="row" spacing={1} mt={1}>
                                            {courseGeneral?.accredibleId ?
                                                <CheckCircleIcon color={'success'} />
                                                :
                                                <CancelIcon color={'error'} />
                                            }
                                            <Typography variant="body2" pt={0.3}>Accredible Integration</Typography>
                                        </Stack>
                                    </Box>
                                </Stack>
                                <FormControl>
                                    <StyledToggleButtonGroup
                                        value={currentRecord.status}
                                        onChange={onIntakeStatusChange}
                                        disabled={userRole === 'TUTOR'}
                                    />
                                </FormControl>
                            </Stack>
                                <Typography variant="body2" mt={1}>
                                    <Typography variant="caption" ml={0.5}>URL</Typography>
                                    <NavigateNextIcon fontSize={"small"}  />
                                    <Link
                                        href={courseGeneral?.description}
                                        target="_blank"
                                        >{courseGeneral?.description}
                                    </Link>
                                </Typography>
                        </Box>

                        <Accordion sx={{ mt: 5}} expanded={intakeExpanded} onChange={() => setIntakeExpanded(!intakeExpanded)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="body1" fontWeight={"bold"}>Intake {currentRecord.intake} Setup</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                               <IntakeEdit courseId={courseId} intake={intake} currentRecord={currentRecord} setIntakeExpanded={setIntakeExpanded}/>

                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <InfoNavbar />
                    {renderCurrentRecord()}
                </div>
            )}

        </div>
    )
}

export default IntakeDetail
