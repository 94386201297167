import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import style from './Modal.module.css'

const ModalInner = (props) => {
    return (
        <>
            <section className={style.uploadModalWrapper}>
                <div className={style.uM_Inner}>
                {props.children}
                </div>
                <div className={style.uM_backdrop}></div>
            </section>
        </>
    )
}

const Modal = ({ children }) => {
   const [mounted, setMounted] = useState(false)

   useEffect(() => {
      setMounted(true)
      return () => setMounted(false)
   }, [])

   return mounted
      ? createPortal(children, document.querySelector("#modalWrapper"))
      : null
}

export {Modal, ModalInner}