import { createTheme } from '@mui/material'
import React, { createContext, useState, useEffect } from 'react'

export const CustomThemeContext = createContext()

export const CustomThemeWrapper = ({ children }) => {
    const themeBlack = '#354147'
    const themeGrey = '#697175'
    const themeGreen = '#2f7d67'
    const themePurple = '#5048e5'
    const themeError = '#f44336'

    const [theme, setTheme] = useState(
        createTheme({
            // pages: {
            //     MuiButton: {
            //         styleOverrides: {
            //             root: ({ ownerState }) => {
            //                 switch (ownerState.variant) {
            //                     case 'contained':
            //                         return {
            //                             backgroundColor: themePurple,
            //                             textTransform: 'none',
            //                             ':hover': {
            //                                 backgroundColor: 'rgba(80, 72, 229, 0.9)',
            //                             },
            //                         }
            //                     case 'outlined':
            //                         return {
            //                             borderColor: themePurple,
            //                             color: themePurple,
            //                             textTransform: 'none',
            //                             ':hover': {
            //                                 backgroundColor: 'rgba(80, 72, 229, 0.1)',
            //                             },
            //                         }
            //                 }
            //             },
            //         },
            //     },
            // },
            palette: {
                primary: {
                    main: themeGreen,
                },
                error:{
                    main: themeError,
                },
                grey:{
                    main: themeGrey,
                }
            }
        })
    )
    return <CustomThemeContext.Provider value={theme}>{children}</CustomThemeContext.Provider>
}
