import * as React from 'react';
import Box from '@mui/material/Box';
import {
    GridColumnMenu,
} from '@mui/x-data-grid-pro';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {useGetSubmissionMutation} from "../../../../api/submissionApi";
import {Modal, ModalInner} from "../../../UI/Modal";
import {Backdrop, Button, Stack, TextField} from "@mui/material";
import style from "../../../Assignment/Assignment.module.scss";
import {getFullSubmissionRecord, postMarkSubmission, postSubmitEvaluation} from "../../../../utils/request/assignment";
import {downloadDocument} from "../../../../utils/request/utility";
import moment from "moment/moment";


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}
export default function AssignmentList() {

    const [fetchAssignment] = useGetSubmissionMutation();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { courseId, intake, studentId } = useParams();

    const refresh = () => {
        fetchAssignment({
            courseId,
            intake,
            studentId,
            type: 0
        }).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "createDate",
            ...defaultColumnType,
            headerName: "Submission Date",
            renderCell: (params) => {
                return moment(params.row.createDate).format('YYYY-MM-DD HH:mm:ss');
            },
            width: 280
        },
        {
            field: "assignmentName",
            ...defaultColumnType,
            headerName: "Name",
            width: 280
        },
        {
            field: "score",
            ...defaultColumnType,
            headerName: "Score",
            width: 160
        },
        {
            field: "Status",
            ...defaultColumnType,
            headerName: "Status",
            renderCell: (params) => {
                return params.row.status === 1 ? 'Latest' : 'Depre'
            },
            width: 220
        },
        {
            field: "Progress",
            ...defaultColumnType,
            headerName: "Progress",
            renderCell: (params) => {
                return params.row.progress === 1 ? 'Marked' : '-'
            },
            width: 220
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }

    const [currentSubmission, setCurrentSubmission] = useState([]);
    const [currentAssignmentName, setCurrentAssignmentName] = useState('');
    const [openSubmission, setOpenSubmission] = useState(false);
    const [file, setFile] = useState('');
    const [submissionScore, setSubmissionScore] = useState(0);
    const [submissionFeedback, setSubmissionFeedback] = useState('');

    const onRowClick = (params) => {
        onGetSubmissionRecord(params.row);
    }

    useEffect(()=>{
        if(currentSubmission){
            setSubmissionScore(currentSubmission.score);
            setSubmissionFeedback(currentSubmission.comment);
            setFile('');
        }
    },[currentSubmission])

    // Submission record
    const onGetSubmissionRecord = async (record) =>{
        setCurrentAssignmentName(record.assignmentName);
        try {
            let result = await getFullSubmissionRecord(
                record.studentId,
                record.courseId,
                record.intake,
                record.submissionId
            )
            setCurrentSubmission(result.data.data)
            console.log('get submission record success', result)
        }catch(error){
            console.log('get submission record error', error)
        }
        setOpenSubmission(true)
    }

    const onClickCloseSubmission = () =>{
        setOpenSubmission(false)
    }


    const onSubmitMarking = async () =>{
        if (file){
            await onSubmitEvaluation();
        } else {
            await onSubmitMarkingOnly();
        }
        refresh();
    }

    const onSubmitEvaluation = async () =>{
        // upload evaluation
        try {
            let result = await postSubmitEvaluation(
                currentSubmission.studentId,
                currentSubmission.courseId,
                currentSubmission.intake,
                currentSubmission.assignmentId,
                currentSubmission.submissionId,
                file
            )
            console.log('submit evaluation success', result);
            await onSubmitMarkingOnly();
        } catch(error){
            console.log('submit evaluation error', error)
        }

    }

    const onSubmitMarkingOnly = async () =>{
        // submit marking
        try {
            let result = await postMarkSubmission(
                currentSubmission.studentId,
                currentSubmission.courseId,
                currentSubmission.intake,
                currentSubmission.submissionId,
                submissionScore,
                submissionFeedback
            )
            console.log('update marking success', result)
            setOpenSubmission(false)
        } catch(error){
            console.log('submit marking error', error)
        }
    }

    const downloadDocumentToLocal = async (doc) => {
        try {
            let file = await downloadDocument(doc.path)
            const e = document.createElement('a')
            e.href = URL.createObjectURL(file.data)
            e.download = doc.documentName
            e.click()
        } catch (error) {
            console.log('Download document error : ', error)
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };



    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            {
                openSubmission?
                    <Modal
                        open={openSubmission}
                        onClose={onClickCloseSubmission}
                    >
                        <ModalInner>
                            <div className="uM_title">
                                Evaluation
                            </div>
                            <div className="uM_body">
                                <div className='modal-info-wrapper'>
                                    <div className='modal-info'>
                                        <div>Name</div>
                                        <div>{currentAssignmentName}</div>
                                    </div>
                                    <div className='modal-info'>
                                        <div>Attachment</div>
                                        <div><Button variant="outlined" color={"success"} onClick={()=>downloadDocumentToLocal(currentSubmission.submissionDoc)}>Download</Button></div>
                                    </div>
                                </div>
                                <div className='modal-input-wrapper'>
                                    <TextField
                                        className={style.name}
                                        label="Score"
                                        placeholder="0"
                                        value={submissionScore}
                                        onChange={(e) =>
                                            setSubmissionScore(e.target.value)
                                        }
                                    />
                                </div>
                                <div className='modal-input-wrapper full'>
                                    <TextField
                                        className={style.name}
                                        label="Comment"
                                        placeholder="Leave your comment here"
                                        value={submissionFeedback}
                                        onChange={(e) =>
                                            setSubmissionFeedback(e.target.value)
                                        }
                                    />
                                </div>
                                <div className='modal-info'>
                                    <div>Upload feedback</div>
                                    <input id="evaluationDoc" type="file" onChange={handleFileChange} title=" "/>
                                </div>

                            </div>
                            <div className="uM_action">
                                <Stack spacing={2} direction="row" justifyContent="flex-end">
                                    <Button onClick={onClickCloseSubmission} color={"success"} variant="text">Cancel</Button>
                                    <Button onClick={ async () => await onSubmitMarking()} variant="contained" color="success">Submit</Button>
                                </Stack>
                            </div>
                        </ModalInner>
                    </Modal>: null
            }

            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                onRowClick={onRowClick}
                disableRowSelectionOnClick
                getRowId={(row) => row.submissionId}
                pageSizeOptions={[5]}
                pagination
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
