import { LOCAL_STORAGE_ADMIN } from '../constants'
const axios = require('axios')

const getToken = async () => {
    try {
        let admin = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ADMIN))
        if (admin) {
            console.log('ran')
            return admin.authorization
        } else {
            return ''
        }
    } catch (error) {
        if (error instanceof SyntaxError) {
            localStorage.removeItem(LOCAL_STORAGE_ADMIN)
        }
        return ''
    }
}

const mainRequest = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_URL,
    headers: { Authorization: getToken() },
})

const uaaRequest = axios.create({
    baseURL: process.env.REACT_APP_UAA_API_URL,
    // headers: { Authorization: getToken() },
})

uaaRequest.interceptors.request.use(
    async (config) => {
        const token = await getToken()
        // console.log('token', token)
        config.headers.Authorization = ` ${token}`;
        return config;
    },
    (error) => {
        return error;
    }
)

mainRequest.interceptors.request.use(
    async (config) => {
        const token = await getToken()
        // console.log('token', token)
        config.headers.Authorization = ` ${token}`;
        return config;
    },
    (error) => {
        return error;
    }
)

export { mainRequest, uaaRequest, getToken}
