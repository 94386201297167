import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const timesheetApi = createApi({
    baseQuery: baseQuery,
    reducerPath: "timesheetApi",
    endpoints: builder => ({
        getMyCourse: builder.query({
            query: () => ({
                url: `/timesheet/myCourse`,
            })
        }),
        getTimesheetHistory: builder.query({
            query: () => ({
                url: `/timesheet/timesheetHistory`,
            })
        }),
        submitTimesheet: builder.mutation({
            query: (body) => ({
                url: "/timesheet/submit",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useGetMyCourseQuery,
    useLazyGetTimesheetHistoryQuery,
    useSubmitTimesheetMutation,
} = timesheetApi;
