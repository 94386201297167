import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Button,
    Box,
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox, Stack, Divider, Chip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import pencilIcon from '../../../image/pencil.svg'
import backArrow from '../../../image/back_arrow.svg'
import documentIcon from '../../../image/document.svg'
import { FAIL_CODE, SUCCESS_CODE } from '../../../utils/constants'
import { NavbarContext } from '../../../utils/context/navbarContext'
import { useNavigate, useParams } from 'react-router-dom'
import { getTutorList } from '../../../utils/request/admin'
import {
    createCourse,
    updateGeneral,
    addWeeks,
    getWeeks,
    getGeneral,
    uploadCourseDocument,
    updateIntake,
    getIntakeOnly,
    uploadSupportingDocument,
    getSupportDocument,
    editWeek,
    assignIntakeTutor,
} from '../../../utils/request/course'
import Loading from '../../Loading/Loading'
import _ from 'lodash'
import style from './CreateEditForm.module.scss'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const CreateEditForm = ({ isCreateCourse = false, isEditCourse = false, isEditIntake = false, backUrl = ''}) => {
    const navigate = useNavigate()

    const { courseId, intake } = useParams()
    const DETAIL_STEP = 'Basic Information'
    const BANNER_STEP = 'Context'
    const TUTOR_STEP = 'Tutor Selection'
    const SCHEDULE_STEP = 'Course Schedule'
    const FORM_STEP = 'Document'
    const INTAKE_DETAIL_STEP = 'Intake Information'

    const [courseSteps, setCourseSteps] = useState([])

    const [currentStep, setCurrentStep] = useState('')

    const [newCourseId, setNewCourseId] = useState(0)

    const { setShowNavbar } = useContext(NavbarContext)

    const [currentWeekIndex, setCurrentWeekIndex] = useState(0)

    const [loading, setLoading] = useState(false)

    const [bodyDescriptionEditorState, setBodyDescriptionEditorState] = useState(EditorState.createEmpty())

    const detailFormRef = useRef()
    const bannerFormRef = useRef()
    const tutorFormRef = useRef()
    const scheduleFormRef = useRef()
    const formFormRef = useRef()
    const intakeDetailFormRef = useRef()

    const [tutorData, setTutorData] = useState(null)
    const [selectedTutor, setSelectedTutor] = useState([])

    const [existScheduleData, setExistScheduleData] = useState([])

    const [scheduleData, setScheduleData] = useState([])

    const [formData, setFormData] = useState([])

    const intakeInitData = {
        intake: 1,
        quota: 0,
        startDate: null,
        endDate: null,
        cutOffDate: null,
        academicLevel: 0,
        courseTimeDesc: '',
        courseTimeDescEng: '',
        address: '',
        addressEng: '',
        price: '',
        discountedPrice: '',
        installmentTenor: '',
        monthlyPayment: '',
        stripeUrl: '',
    }
    const [detailData, setDetailData] = useState({
        categoryId: 0,
        name: '',
        nameEng: '',
        description: '',
        prefixKey: '',
        courseCode: '',
        mondaySync: false,
        wordpressSync: false,
        boardId: '',
        postId: '',
        postEngId: '',
        accredibleId: '',
        ...intakeInitData
    })

    const [intakeDetailData, setIntakeDetailData] = useState({
        ...intakeInitData
    })

    const [bannerData, setBannerData] = useState({
        heroBanner: null,
        thumbnail: null,
        brochure: null,
        videoUrl: '',
        body: '',
        certName: '',
        certDescription: '',
    })

    useEffect(() => {
        setShowNavbar(false);
        let initCourseSteps = isCreateCourse ? [DETAIL_STEP, BANNER_STEP, FORM_STEP] : [];

        getTutorList(0, 20).then(tutorRes => {
            const filteredTutorList = tutorRes.data.data.filter(tutor => !tutor.disable);
            setTutorData(filteredTutorList);

            if (isEditCourse) {
                initCourseSteps = [DETAIL_STEP, BANNER_STEP, FORM_STEP];
                Promise.all([getGeneral(courseId), getSupportDocument(courseId)])
                    .then(([courseRes, supportDocumentRes]) => {
                        const course = courseRes.data.data;
                        const supportDocument = supportDocumentRes.data.data;

                        setDetailData({
                            categoryId: course.categoryId,
                            prefixKey: course.prefixKey,
                            name: course.name,
                            nameEng: course.nameEng,
                            postId: course.postId,
                            postEngId: course.postEngId,
                            boardId: course.boardId,
                            wordpressSync: course.wordpressSync,
                            mondaySync: course.mondaySync,
                            description: course.description,
                            courseCode: course.courseCode,
                            accredibleId: course.accredibleId,
                        });

                        setBannerData({
                            videoUrl: course.videoUrl || '',
                            body: course.body,
                            certName: course.certName,
                            certDescription: course.certDescription,
                        });

                        setBodyDescriptionEditorState(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(htmlToDraft(course.body).contentBlocks)
                            )
                        );

                        setFormData(
                            supportDocument.map(doc => ({
                                documentName: doc.documentName,
                                documentDescription: doc.description,
                                disabled: true,
                            }))
                        );
                    })
                    .catch(error => {
                        console.error('Get general or support document error : ', error);
                    });
            }

            if (isEditIntake) {
                initCourseSteps = [INTAKE_DETAIL_STEP, TUTOR_STEP, SCHEDULE_STEP];
                Promise.all([getIntakeOnly(courseId, intake), getWeeks(courseId, intake)])
                    .then(([intakeRes, weekRes]) => {
                        const intakeResult = intakeRes.data.data;
                        const weekResult = weekRes.data.data;

                        setIntakeDetailData({
                            intake: intakeResult.intake,
                            quota: intakeResult.quota,
                            startDate: intakeResult.startDate,
                            endDate: intakeResult.endDate,
                            cutOffDate: intakeResult.cutOffDate,
                            academicLevel: intakeResult.academicLevel,
                            courseTimeDesc: intakeResult.courseTimeDesc,
                            courseTimeDescEng: intakeResult.courseTimeDescEng,
                            address: intakeResult.address,
                            addressEng: intakeResult.addressEng,
                            price: intakeResult.price,
                            discountedPrice: intakeResult.discountedPrice,
                            installmentTenor: intakeResult.installmentTenor,
                            monthlyPayment: intakeResult.monthlyPayment,
                            stripeUrl: intakeResult.stripeUrl,
                        });

                        if (intakeResult.tutor) {
                            const tutorList = intakeResult.tutor.map(tutor => tutor.id);
                            setSelectedTutor(tutorList);
                        }

                        setScheduleData(weekResult.weeks);
                    })
                    .catch(error => {
                        console.error('Get intake or week data error : ', error);
                    });
            }
        })
            .then(() => {
                setCourseSteps(initCourseSteps);
                setCurrentStep(initCourseSteps[0]);
            })
            .catch(error => {
                console.error('An error occurred during setup: ', error);
            });
    }, [isCreateCourse, isEditCourse, isEditIntake, courseId, intake, setShowNavbar, setTutorData, setDetailData, setBannerData, setBodyDescriptionEditorState, setFormData, setIntakeDetailData, setSelectedTutor, setScheduleData, setCourseSteps, setCurrentStep]);


    const intakeDetail = ({isInit}) => {
        return (
            <Stack spacing={2}>
                <div className={style.title}>{isInit ? 'Intake Initialization' : 'Intake Detail'}</div>
                <Stack direction="row" spacing={2} >
                <TextField
                    className={style.intake}
                    type="number"
                    label="Intake"
                    placeholder="Intake"
                    value={isInit? detailData.intake : intakeDetailData.intake}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            intake: parseFloat(e.target.value),
                        })
                        :
                        setIntakeDetailData({
                            ...intakeDetailData,
                            intake: parseFloat(e.target.value),
                        })
                    }
                    onWheel={(e) => e.target.blur()}
                    required
                    fullWidth
                />
                <TextField
                    className={style.quota}
                    type="number"
                    label="Quota"
                    placeholder="Quota"
                    value={isInit? detailData.quota : intakeDetailData.quota}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            quota: parseFloat(e.target.value),
                        })
                        :
                        setIntakeDetailData({
                        ...intakeDetailData,
                            quota: parseFloat(e.target.value),
                        })
                    }
                    onWheel={(e) => e.target.blur()}
                    fullWidth
                />
                </Stack>
                <Divider>
                    <Chip label="Schedule" />
                </Divider>
                <Stack direction="row" spacing={2} >
                    <FormControl style={{width: 1130}}>
                        <InputLabel>Academic Level</InputLabel>
                        <Select
                            value={isInit? detailData.academicLevel :  intakeDetailData.academicLevel}
                            label="Academic Level"
                            onChange={(e) =>
                                isInit ?
                                setDetailData({
                                    ...detailData,
                                    academicLevel: e.target.value,
                                })
                                :
                                setIntakeDetailData({
                                    ...intakeDetailData,
                                    academicLevel: e.target.value,
                                })
                            }

                        >
                            {Array.from(Array(5).keys()).map((i) => (
                                <MenuItem key={i} value={i}>
                                    {i}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Cutoff Date"
                        value={isInit? dayjs(detailData.cutOffDate) :  dayjs(intakeDetailData.cutOffDate)}
                        onChange={(newValue) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                cutOffDate: newValue.format('yyyy-MM-DD'),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                cutOffDate: newValue.format('yyyy-MM-DD'),
                            })
                        }
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                        inputFormat="DD/MM/yyyy"
                    />
                    </LocalizationProvider>
                </Stack>

                <Stack direction="row" spacing={2} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Start Date"
                        value={isInit? dayjs(detailData.startDate) :  dayjs(intakeDetailData.startDate)}
                        onChange={(newValue) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                startDate: newValue.format('yyyy-MM-DD'),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                startDate: newValue.format('yyyy-MM-DD'),
                            })
                        }
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                        inputFormat="DD/MM/yyyy"
                    />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="End Date"
                        value={isInit? dayjs(detailData.endDate) :  dayjs(intakeDetailData.endDate)}
                        onChange={(newValue) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                endDate: newValue.format('yyyy-MM-DD'),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                endDate: newValue.format('yyyy-MM-DD'),
                        })
                        }
                        renderInput={(params) => (
                            <TextField fullWidth {...params} />
                        )}
                        inputFormat="DD/MM/yyyy"
                    />
                    </LocalizationProvider>
                </Stack>
                <TextField
                    label="Course schedule description (Chi)"
                    placeholder="Course schedule description (Chi)"
                    value={isInit? detailData.courseTimeDesc :  intakeDetailData.courseTimeDesc}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            courseTimeDesc: e.target.value,
                        })
                        :
                        setIntakeDetailData({
                            ...intakeDetailData,
                            courseTimeDesc: e.target.value,
                        })
                    }
                    multiline
                    maxRows={3}
                    required
                    fullWidth
                />
                <TextField
                    label="Course schedule description (Eng)"
                    placeholder="Course schedule description (Eng)"
                    value={isInit? detailData.courseTimeDescEng :  intakeDetailData.courseTimeDescEng}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            courseTimeDescEng: e.target.value,
                        })
                        :
                        setIntakeDetailData({
                            ...intakeDetailData,
                            courseTimeDescEng: e.target.value,
                        })
                    }
                    required
                    multiline
                    maxRows={3}
                    fullWidth
                />
                <Divider>
                    <Chip label="Address" />
                </Divider>
                <TextField
                    label="Course address (Chi)"
                    placeholder="Course address (Chi)"
                    value={isInit? detailData.address :  intakeDetailData.address}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            address: e.target.value,
                        })
                        :
                        setIntakeDetailData({
                            ...intakeDetailData,
                            address: e.target.value,
                        })
                    }
                    required
                    fullWidth
                />
                <TextField
                    label="Course address (ENG)"
                    placeholder="Course address (ENG)"
                    value={isInit? detailData.addressEng :  intakeDetailData.addressEng}
                    onChange={(e) =>
                        isInit ?
                        setDetailData({
                            ...detailData,
                            addressEng: e.target.value,
                        })
                        :
                        setIntakeDetailData({
                            ...intakeDetailData,
                            addressEng: e.target.value,
                        })
                    }
                    required
                    fullWidth
                />
                <Divider>
                    <Chip label="Payment" />
                </Divider>
                <Stack direction="row" spacing={2} >
                    <TextField
                        type="number"
                        label="Price (HKD)"
                        placeholder="Price (HKD)"
                        value={isInit? detailData.price :  intakeDetailData.price}
                        onChange={(e) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                price: parseFloat(e.target.value),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                price: parseFloat(e.target.value),
                            })
                        }
                        onWheel={(e) => e.target.blur()}
                        required
                        fullWidth
                    />
                    <TextField
                        className={style.discountedPrice}
                        type="number"
                        label="Discounted Price (HKD)"
                        placeholder="Discounted Price (HKD)"
                        value={isInit? detailData.discountedPrice :  intakeDetailData.discountedPrice}
                        onChange={(e) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                discountedPrice: parseFloat(e.target.value),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                discountedPrice: parseFloat(e.target.value),
                            })
                        }
                        onWheel={(e) => e.target.blur()}
                        fullWidth
                    />
                </Stack>
                <Stack direction="row" spacing={2} >
                    <TextField
                        type="number"
                        label="Tenor"
                        placeholder="Tenor (HKD)"
                        value={isInit? detailData.installmentTenor :  intakeDetailData.installmentTenor}
                        onChange={(e) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                installmentTenor: e.target.value,
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                installmentTenor: e.target.value,
                            })
                        }
                        fullWidth
                    />
                    <TextField
                        className={style.monthlyRepayment}
                        type="number"
                        label="Monthly Repayment"
                        placeholder="Monthly Repayment"
                        value={isInit? detailData.monthlyPayment :  intakeDetailData.monthlyPayment}
                        onChange={(e) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                monthlyPayment: parseFloat(e.target.value),
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                monthlyPayment: parseFloat(e.target.value),
                            })
                        }
                        onWheel={(e) => e.target.blur()}
                        fullWidth
                    />
                </Stack>
                <FormGroup fullWidth>
                    <TextField
                        className={style.tenor}
                        type="text"
                        label="Stripe Payment Link"
                        placeholder="Paste the paymeny link here"
                        value={isInit? detailData.stripeUrl :  intakeDetailData.stripeUrl}
                        onChange={(e) =>
                            isInit ?
                            setDetailData({
                                ...detailData,
                                stripeUrl: e.target.value,
                            })
                            :
                            setIntakeDetailData({
                                ...intakeDetailData,
                                stripeUrl: e.target.value,
                            })
                        }
                    />
                </FormGroup>
            </Stack>
        )
    }

    const onBackToCourseListClick = () => {
        setShowNavbar(true)
        navigate(backUrl)
    }

    useEffect(() => {
        console.log('selectedTutor', selectedTutor)
    }, [selectedTutor])

    const onScheduleDataChange = (value, field) => {
        let tempScheduleData = [...scheduleData]
        let tempSubData = { ...tempScheduleData[currentWeekIndex] }
        switch (field) {
            case 'name':
            case 'description':
                tempSubData[field] = value
                tempScheduleData[currentWeekIndex] = tempSubData
                setScheduleData(tempScheduleData)
                break
            case 'startDate':
            case 'endDate':
                tempSubData[field] = value.format('yyyy-MM-DD')
                tempScheduleData[currentWeekIndex] = tempSubData
                setScheduleData(tempScheduleData)

                break
        }
    }

    const onCourseDocumentUpload = async (e) => {
        try {
            setLoading(true)
            let result = await uploadCourseDocument(
                isCreateCourse ? newCourseId : courseId,
                e.target.files[0],
                e.target.id
            )
            console.log('upload course document successfully : ', result)
        } catch (error) {
            console.log('Upload course document error : ', error)
        } finally {
            setLoading(false)
        }
    }

    const onCourseSupportingDocumentUpload = async (e, index) => {
        try {
            setLoading(true)
            let file = e.target.files[0]
            let result = await uploadSupportingDocument(
                isCreateCourse ? newCourseId : courseId,
                formData[index].documentDescription,
                file
            )
            let newFormData = [...formData]
            let newFormSubData = {
                ...newFormData[index],
                documentName: file.name,
                disabled: true,
            }
            newFormData[index] = newFormSubData
            setFormData(newFormData)
            console.log('Upload supporting document successfully : ', result)
        } catch (error) {
            console.log('Upload supporting document error : ', error)
        } finally {
            setLoading(false)
        }
    }

    const onFormDataChange = (value, field, index) => {
        switch (field) {
            case 'documentName':
            case 'documentDescription':
                let newFormData = [...formData]
                let newFormDataSub = {
                    ...newFormData[index],
                    documentDescription: value,
                }
                newFormData[index] = newFormDataSub
                setFormData(newFormData)
                break
        }
    }

    const onNewCourseNextClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        let result = {}
        try {
            switch (currentStep) {
                case DETAIL_STEP:
                    console.log('Detail data : ', detailData)
                    // here use fetch instead of axios
                    // so the result is a bit different
                    if (detailFormRef.current.reportValidity()) {
                        let newDetailData = _.cloneDeep(detailData)
                        if (isCreateCourse) {
                            result = await createCourse(newDetailData)
                            switch (result.data.code) {
                                case FAIL_CODE:
                                    break
                                case SUCCESS_CODE:
                                    setNewCourseId(result.data.data.general.courseId)
                                    break
                            }
                        } else if (isEditCourse) {
                            result = await updateGeneral(courseId, newDetailData)
                        }
                    }
                    break
                case BANNER_STEP:
                    console.log('Banner data : ', bannerData)
                    if (bannerFormRef.current.reportValidity()) {
                        result = await updateGeneral(courseId || newCourseId, {
                            videoUrl: bannerData.videoUrl,
                            body: draftToHtml(convertToRaw(bodyDescriptionEditorState.getCurrentContent())),
                            certName: bannerData.certName,
                            certDescription: bannerData.certDescription,
                        })
                    }
                    break
                case TUTOR_STEP:
                    let res = Promise.all(selectedTutor.map((tutor) => assignIntakeTutor(courseId, intake, tutor)))
                    // for below switch usage
                    result.data = {}
                    result.data.code = SUCCESS_CODE
                    break
                case SCHEDULE_STEP:
                    console.log('Schedule data : ', scheduleData)
                    if (scheduleFormRef.current.reportValidity()) {
                        let newScheduleData = scheduleData.filter((week) => !week.weekId)
                        let existScheduleData = scheduleData.filter((week) => week.weekId)
                        result = await Promise.all(existScheduleData.map((week) => editWeek(courseId, intake, week)))
                        result = await addWeeks(courseId, intake, newScheduleData)
                    }
                    break
                case FORM_STEP:
                    console.log('Form step : ', formData)
                    if (formFormRef.current.reportValidity()) {
                    }
                    result = {}
                    result.data = {}
                    result.data.code = SUCCESS_CODE
                    break
                case INTAKE_DETAIL_STEP:
                    console.log('Intake deail data : ', intakeDetailData)
                    if (intakeDetailFormRef.current.reportValidity()) {
                        result = await updateIntake(courseId, intake, intakeDetailData)
                    }
                    break
            }
            switch (result.data.code) {
                case FAIL_CODE:
                    alert('Error')
                    console.log(`create course step${currentStep} error :`, result)
                    break
                case SUCCESS_CODE:
                    console.log(`Create course step ${currentStep} successfully : `, result)
                    let currentIndex = courseSteps.indexOf(currentStep)
                    if (currentIndex === courseSteps.length - 1) {
                        if (isEditIntake) {
                            navigate('/intake')
                        } else {
                            navigate(-1)
                        }
                    } else {
                        setCurrentStep(courseSteps[currentIndex + 1])
                    }
                    break
                default:
                    alert('Unhandle case')
                    console.log(`Create course ${currentStep} unhandle : `, result)
            }
        } catch (error) {
            console.log('onNewCourseNextClick error : ', error)
        } finally {
            setLoading(false)
        }
    }

    const renderNewCourseStep = () => {
        console.log('currentStep : ', currentStep)
        switch (currentStep) {
            case DETAIL_STEP:
                return (
                    <div className={style.deatilStepContainer}>
                        <form ref={detailFormRef}>
                            <div className={style.courseDetailContainer}>
                                <div className={style.title}>Course Detail</div>
                                <Stack spacing={3} style={{ marginTop: -20}}>
                                <Stack direction="row" spacing={2} style={{ marginTop: 0}}>
                                    <TextField
                                        label="Prefix Key"
                                        placeholder="Prefix Key"
                                        inputProps={{ maxLength: 5 }}
                                        value={detailData.prefixKey}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                prefixKey: e.target.value,
                                            })
                                        }
                                        style={{ width: '50%' }}
                                        required
                                    />
                                    <TextField
                                        label="Course Code"
                                        placeholder="Course Code"
                                        inputProps={{ maxLength: 5 }}
                                        value={detailData.courseCode}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                courseCode: e.target.value,
                                            })
                                        }
                                        style={{ width: '50%' }}
                                    />
                                    <TextField
                                        label="Accredible ID"
                                        placeholder="Accredible ID"
                                        inputProps={{ maxLength: 11 }}
                                        value={detailData.accredibleId}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                accredibleId: e.target.value,
                                            })
                                        }
                                        style={{ width: '50%' }}
                                    />
                                    <TextField
                                        label="Course Name (Chi)"
                                        placeholder="課程名稱"
                                        value={detailData.name}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                name: e.target.value,
                                            })
                                        }
                                        fullWidth
                                        required
                                    />
                                    <TextField
                                        label="Course Name (Eng)"
                                        placeholder="Course Name"
                                        value={detailData.nameEng}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                nameEng: e.target.value,
                                            })
                                        }
                                        fullWidth
                                        required
                                    />
                                </Stack>


                                <TextField
                                    label="Course Description"
                                    placeholder="Course Description"
                                    value={detailData.description}
                                    onChange={(e) =>
                                        setDetailData({
                                            ...detailData,
                                            description: e.target.value,
                                        })
                                    }
                                    maxRows={8}
                                    required
                                    multiline
                                />
                                    <Stack direction="row" spacing={2} style={{ marginTop: 0}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={detailData.wordpressSync}
                                            onChange={(e) =>
                                                setDetailData({
                                                    ...detailData,
                                                    wordpressSync: !detailData.wordpressSync,
                                                })
                                            }
                                        />
                                    }
                                    style={{ width: 800}}
                                    label="Wordpress Synchronization" />
                                    <TextField
                                        className={style.courseName}
                                        label="Post ID (Chi)"
                                        placeholder="Post ID (Chi)"
                                        value={detailData.postId}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                postId: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        className={style.courseName}
                                        label="Post ID (Eng)"
                                        placeholder="Post ID (Eng)"
                                        value={detailData.postEngId}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                postEngId: e.target.value,
                                            })
                                        }
                                    />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={detailData.mondaySync}
                                                onChange={(e) =>
                                                    setDetailData({
                                                        ...detailData,
                                                        mondaySync: !detailData.mondaySync,
                                                    })
                                                }
                                            />
                                        }
                                        style={{ width: 490}}
                                        label="Monday Synchronization" />
                                    <TextField
                                        label="Board ID"
                                        placeholder="Board ID"
                                        value={detailData.boardId}
                                        onChange={(e) =>
                                            setDetailData({
                                                ...detailData,
                                                boardId: e.target.value,
                                            })
                                        }
                                        fullWidth
                                    />
                                </Stack>
                                {(isCreateCourse || isEditIntake) && (
                                    intakeDetail({
                                        isInit: !(courseId && intake),
                                    })
                                )}

                                </Stack>
                            </div>
                            <div className={style.buttonContainer}>
                                <Button variant="contained" onClick={onNewCourseNextClick} type="submit">
                                    {isCreateCourse ? 'Create' : 'Update & Next'}
                                </Button>
                            </div>
                        </form>
                    </div>
                )
            case BANNER_STEP:
                return (
                    <div className={style.bannerStepContainer}>
                        <form ref={bannerFormRef}>
                            <div className={style.uploadImageContainer}>
                                <div className={style.title}>Course images and banner</div>
                                <div className={style.buttonContainer}>
                                    <label className={style.uploadLabel} htmlFor="HeroBanner">
                                        Upload Hero Banner
                                    </label>
                                    <input
                                        className={style.inputFile}
                                        type="file"
                                        id="HeroBanner"
                                        onChange={onCourseDocumentUpload}
                                    />
                                    <label className={style.uploadLabel} htmlFor="Thumbnail">
                                        Upload Thumbnail
                                    </label>
                                    <input
                                        className={style.inputFile}
                                        type="file"
                                        id="Thumbnail"
                                        onChange={onCourseDocumentUpload}
                                    />
                                    <label className={style.uploadLabel} htmlFor="Brochure">
                                        Upload Brochure
                                    </label>
                                    <input
                                        className={style.inputFile}
                                        type="file"
                                        id="Brochure"
                                        onChange={onCourseDocumentUpload}
                                    />
                                </div>
                                <div className={style.breakLine} />
                                <TextField
                                    className={style.videoUrl}
                                    label="Video URL (optional)"
                                    placeholder="Video URL (optional)"
                                    value={bannerData.videoUrl}
                                    onChange={(e) =>
                                        setBannerData({
                                            ...bannerData,
                                            videoUrl: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className={style.bodyDescriptionContainer}>
                                <div className={style.title}>Course Body Description</div>
                                <div>
                                    <Editor
                                        editorState={bodyDescriptionEditorState}
                                        onEditorStateChange={(editorState) =>
                                            setBodyDescriptionEditorState(editorState)
                                        }
                                        wrapperClassName={style.editorWrapper}
                                        editorClassName={style.editor}
                                    ></Editor>
                                    {/* <textarea value={draftToHtml(convertToRaw(bodyDescriptionEditorState.getCurrentContent()))}>

                                    </textarea> */}
                                    <div className={style.breakLine} />
                                </div>
                                <div className={style.title}>Certification description</div>
                                <TextField
                                    className={style.certName}
                                    label="Name of certification"
                                    placeholder="Name of certification"
                                    value={bannerData.certName}
                                    onChange={(e) =>
                                        setBannerData({
                                            ...bannerData,
                                            certName: e.target.value,
                                        })
                                    }
                                    required
                                />
                                <TextField
                                    className={style.certDescription}
                                    label="Description"
                                    placeholder="Description"
                                    value={bannerData.certDescription}
                                    onChange={(e) =>
                                        setBannerData({
                                            ...bannerData,
                                            certDescription: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className={style.buttonContainer}>
                                <Button
                                    variant="outlined"
                                    className={style.backButton}
                                    onClick={() => isCreateCourse ? navigate('/course') : setCurrentStep(courseSteps[courseSteps.indexOf(currentStep) - 1])}
                                    type="button"
                                >
                                    {isCreateCourse ? 'Setup later' : 'Previous'}
                                </Button>
                                <Button variant="contained" onClick={onNewCourseNextClick} type="submit">
                                    Update & Next
                                </Button>
                            </div>
                        </form>
                    </div>
                )
            case TUTOR_STEP:
                return (
                    <div className={style.tutorStepContainer}>
                        <div className={style.tutorContainer}>
                            <div className={style.title}>Select tutors</div>
                            <div className={style.breakLine} />
                            <div className={style.tutorList}>
                                {tutorData && tutorData.map((tutor, index) => (
                                    <FormGroup key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="success"
                                                    checked={selectedTutor.includes(tutor.id)}
                                                    onChange={(e) => {
                                                        if (selectedTutor.includes(tutor.id)) {
                                                            setSelectedTutor(prevState =>
                                                                prevState.filter(item => item !== tutor.id))
                                                        } else {
                                                            setSelectedTutor([...selectedTutor, tutor.id])
                                                        }
                                                    }
                                                    }
                                                />
                                            }
                                            label={tutor.nameEn}
                                        />
                                    </FormGroup>
                                ))}
                            </div>
                        </div>
                        <div className={style.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={style.backButton}
                                onClick={() => setCurrentStep(courseSteps[courseSteps.indexOf(currentStep) - 1])}
                                type="button"
                            >
                                Previous
                            </Button>
                            <Button variant="contained" onClick={onNewCourseNextClick} type="submit">
                                Update & Next
                            </Button>
                        </div>
                    </div>
                )
            case SCHEDULE_STEP:
                return (
                    <div className={style.scheduleStepContainer}>
                        <div className={style.headerContainer}>
                            <div className={style.title}>Course Content</div>
                            <Button
                                variant="outlined"
                                className={style.addWeekButton}
                                onClick={() =>
                                    setScheduleData([
                                        ...scheduleData,
                                        {
                                            name: '',
                                            description: '',
                                            startDate: null,
                                            endDate: null,
                                        },
                                    ])
                                }
                            >
                                Add Weeks
                            </Button>
                        </div>
                        <form ref={scheduleFormRef}>
                            {scheduleData.length !== 0 && (
                                <div className={style.weekSession}>
                                    <div className={style.totalWeekContainer}>
                                        <div className={style.title}>Total weeks : {scheduleData.length}</div>
                                        <div className={style.breakLine} />
                                        {scheduleData.map((week, index) => (
                                            <div
                                                key={index}
                                                className={`${style.weekListItem} ${currentWeekIndex === index && style.active
                                                    }`}
                                                onClick={() => setCurrentWeekIndex(index)}
                                            >
                                                Weel {index + 1}
                                                <div
                                                    className={style.remove}
                                                    onClick={() =>
                                                        setScheduleData(scheduleData.filter((week, i) => i !== index))
                                                    }
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={style.weekContainer}>
                                        <div className={style.title}>Week {currentWeekIndex + 1}</div>
                                        <TextField
                                            className={style.className}
                                            label="Class Name"
                                            placeholder="Class Name"
                                            value={scheduleData[currentWeekIndex].name}
                                            onChange={(e) => onScheduleDataChange(e.target.value, 'name')}
                                            required
                                        />
                                        <TextField
                                            className={style.classDescription}
                                            label="Class Description"
                                            placeholder="Class Description"
                                            value={scheduleData[currentWeekIndex].description}
                                            onChange={(e) => onScheduleDataChange(e.target.value, 'description')}
                                            required
                                        />
                                        <div className={style.inputRow}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Start Date"
                                                value={dayjs(scheduleData[currentWeekIndex].startDate)}
                                                onChange={(newValue) => onScheduleDataChange(newValue, 'startDate')}
                                                renderInput={(params) => (
                                                    <TextField className={style.startDate} {...params} />
                                                )}
                                                inputFormat="DD/MM/yyyy"
                                            />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="End Date"
                                                value={dayjs(scheduleData[currentWeekIndex].endDate)}
                                                onChange={(newValue) => onScheduleDataChange(newValue, 'endDate')}
                                                renderInput={(params) => (
                                                    <TextField className={style.endDate} {...params} />
                                                )}
                                                inputFormat="DD/MM/yyyy"
                                            />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={style.buttonContainer}>
                                <Button
                                    variant="outlined"
                                    className={style.backButton}
                                    onClick={() => setCurrentStep(courseSteps[courseSteps.indexOf(currentStep) - 1])}
                                    type="button"
                                >
                                    Previous
                                </Button>
                                <Button variant="contained" onClick={onNewCourseNextClick} type="submit">
                                    Next
                                </Button>
                            </div>
                        </form>
                    </div>
                )
            case FORM_STEP:
                return (
                    <div className={style.formStepContainer}>
                        <div className={style.headerContainer}>
                            <div className={style.title}>Supporting Document</div>
                            <Button
                                variant="outlined"
                                className={style.addButton}
                                onClick={() =>
                                    setFormData([
                                        ...formData,
                                        {
                                            documentName: '',
                                            documentDescription: '',
                                            disabled: false,
                                        },
                                    ])
                                }
                            >
                                Add
                            </Button>
                        </div>
                        <form ref={formFormRef}>
                            {formData.map((document, index) => (
                                <div key={index} className={style.documentContainer}>
                                    <div className={style.title}>Supporting Document {index + 1}</div>
                                    {document.disabled && (
                                        <div className={style.documentName}>
                                            <img src={documentIcon}></img>
                                            {document.documentName}
                                        </div>
                                    )}
                                    <div className={style.formContainer}>
                                        <div className={style.formTextfield}>
                                            <TextField
                                                className={style.documentDescription}
                                                label="Document Name (Required)"
                                                placeholder="Document Name"
                                                value={document.documentDescription}
                                                onChange={(e) => onFormDataChange(e.target.value, 'documentDescription', index)}
                                                required
                                                disabled={document.disabled}
                                            />
                                        </div>
                                        <div className={style.formAction}>
                                            {!document.disabled && (
                                                <>
                                                    <label
                                                        className={`${style.uploadLabel} ${!document.documentDescription && style.disabledLabel
                                                            }`}
                                                        htmlFor={`form${index}`}
                                                    >
                                                        Upload Form
                                                    </label>
                                                    <input
                                                        disabled={!document.documentDescription}
                                                        className={style.inputFile}
                                                        type="file"
                                                        id={`form${index}`}
                                                        onChange={(e) => onCourseSupportingDocumentUpload(e, index)}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            ))}
                            <div className={style.buttonContainer}>
                                <Button
                                    variant="outlined"
                                    className={style.backButton}
                                    onClick={() => isCreateCourse ? navigate('/course') : setCurrentStep(courseSteps[courseSteps.indexOf(currentStep) - 1])}
                                    type="button"
                                >
                                    {isCreateCourse ? 'Setup later' : 'Previous'}
                                </Button>
                                {isCreateCourse ?
                                    <Button variant="contained" onClick={() => {navigate('/intake')}} type="button">Intake Setup</Button>
                                    :
                                    <Button variant="contained" onClick={onNewCourseNextClick} type="submit">Update</Button>
                                }
                            </div>
                        </form>
                    </div>
                )
            case INTAKE_DETAIL_STEP:
                return (
                    <div className={style.intakeDeatilStepContainer}>
                        <form ref={intakeDetailFormRef}>
                            <div className={style.intakeDetailContainer}>
                                {intakeDetail({isInit: false})}
                            </div>
                            <div className={style.buttonContainer}>
                                <Button variant="contained" onClick={onNewCourseNextClick} type="submit">
                                    Update & Next
                                </Button>
                            </div>
                        </form>
                    </div>
                )
        }
    }

    return (
        <div className={style.newCourseParentContainer}>
            {loading && <Loading></Loading>}
            <div className={style.headingContainer}>
                <div className={style.backToCourseListButton} onClick={onBackToCourseListClick}>
                    <img src={backArrow} className={style.backArrow}></img>
                    <div>{`${courseId ? 'Edit' : 'Create'} ${intake ? 'Intake' : 'Master Course'}`}</div>
                </div>
            </div>
            <div className={style.mainNewCourseContainer}>
                <div className={style.stepIndicatorContainer}>
                    {courseSteps.map((step, index) => (
                        <div key={index} className={style.stepIndicator}>
                            <div className={`${style.circle} ${currentStep === step && style.active}`}>{index + 1}</div>
                            <div className={style.title}>{step}</div>
                        </div>
                    ))}
                </div>
                {renderNewCourseStep()}
            </div>
        </div>
    )
}

export default CreateEditForm
