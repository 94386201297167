import styles from './AccessControl.module.scss'

const AccessControl = () => {
    return (
        <div className={styles.ctn}>
           <div className={styles.title}>Access Denial</div>
           <div className={styles.subtitle}>Only Admin can view this page</div>
        </div>
    )
}

export default AccessControl