import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoIcon from '../../image/logo_icon.png';
import { Box, Button, Divider, Icon, Typography } from '@mui/material';
import { AdminContext } from '../../utils/context/adminContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import style from './Navbar.module.scss';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { admin, setAdmin } = useContext(AdminContext);
    const userRole = localStorage.getItem('userRole');

    const tutorItem = [
        { title: 'Intake', icon: 'school', link: '/intake', level: 1 },
        { title: 'Timesheet', icon: 'domain_verification', link: '/timesheet', level: 1 },
        { title: 'Poll Inventory', icon: 'poll', link: '/pollInventory', level: 1 },
    ];

    const adminItem = [
        { title: 'Course', icon: 'school', link: '/course', level: 1 },
        { title: 'Intake', icon: 'playlist_add', link: '/intake', level: 1 },
        { title: 'Schedule Assistant', icon: 'calendar_month', link: '/scheduleAssistant', level: 1 },
        { title: 'Timesheet', icon: 'domain_verification', link: '/timesheet', level: 1 },
        { title: 'Poll Inventory', icon: 'inventory', link: '/pollInventory', level: 1 },
        { title: 'Add Tutor', icon: 'support_agent', link: '/tutor', level: 2 },
        { title: 'Add Admin', icon: 'person_add_alt1', link: '/admin', level: 2 },
        { title: 'Whatsapp Alert', icon: <WhatsAppIcon sx={{ mr: 1, color: '#b5bbbe' }} fontSize="small" />, link: '/alert', level: 3 },
    ];

    const navbarItem = userRole === 'TUTOR' ? tutorItem : adminItem;

    const NavbarSection = ({ level, title }) => (
        <>
            {level > 1 && navbarItem.some((item) => item.level === level) && <Divider sx={{ m: 3, mb: 2, color: 'grey', opacity: 0.1 }} />}
            {title && userRole !== 'TUTOR' && (
                <Box mb={2} ml={3}>
                    <Typography variant="caption" sx={{ color: 'grey', opacity: 0.9 }}>
                        {title}
                    </Typography>
                </Box>
            )}
            <Box>
                {navbarItem
                    .filter((item) => item.level === level)
                    .map((item, index) => (
                        <Link
                            key={index}
                            to={item.link}
                            className={`${style.link} ${location.pathname.includes(item.link) && style.active}`}
                            style={{ color: 'inherit', textDecoration: 'none' }}
                        >
                            {typeof item.icon === 'string' ? (
                                <Icon fontSize="small" sx={{ mr: 1, color: '#b5bbbe' }}>
                                    {item.icon}
                                </Icon>
                            ) : (
                                <Box>{item.icon}</Box>
                            )}
                            <div>{item.title}</div>
                        </Link>
                    ))}
            </Box>
        </>
    );

    return (
        <div className={style.parentContainer}>
            <div className={style.logoContainer}>
                <img src={logoIcon} alt="Logo" className={style.logo} />
                <div className={style.textContainer}>
                    <div className={style.title}>Education Portal</div>
                    <div className={style.version}>v24.08.01</div>
                </div>
            </div>
            <NavbarSection level={1} />
            <NavbarSection level={2} title="Account" />
            <NavbarSection level={3} title="Notification" />
        </div>
    );
};

export default Navbar;
