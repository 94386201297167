import React, { useState, useEffect } from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    Stack, Breadcrumbs, Chip, Typography, Divider, CircularProgress,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import searchIcon from '../../image/search.svg'
import documentIcon from '../../image/document.svg'
import { ENROLLMENT_STATUS_REVIEW, ENROLLMENT_STATUS_UNPAID, ENROLLMENT_STATUS_PAID, ENROLLMENT_STATUS_REJECT } from '../../utils/constants'
import backArrow from '../../image/back_arrow.svg'
import {
    getEnrollmentList,
    getEnrollmentRecord,
    getAcademicProveList,
    approveAcademicLevel,
    updateEnrollmentRecord,
    getPaymentSlipList,
    getPaymentRecord,
    updateAcademicLevel,
    updatePaymentRecord,
    getEnrollmentSupportDocumentList,
} from '../../utils/request/enrollment'
import { downloadDocument } from '../../utils/request/utility'
import Loading from '../Loading/Loading'
import moment from 'moment-timezone'
import { getIntakeOnly } from '../../utils/request/course'
import AccessControl from '../UI/AccessControl';


import style from './Enrollment.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import {statusColor, steps} from "../Course/Intake/IntakeEnrollList/IntakeEnrollList";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AssignmentList from "../Course/Intake/StudentPage/AssignmentList";
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import {
    Container, Card, CardContent, Grid, IconButton, Link,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import {
    useAddEnrollPaymentMutation,
    useDeleteEnrollPaymentMutation,
    useUploadAcademicProveMutation, useUploadPaymentSlipMutation, useUploadSupportingDocumentMutation
} from "../../api/enrollmentApi";
import {useDeleteEnrollmentMutation} from "../../api/removeApi";
import {CloudUpload} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
const Enrollment = () => {
    const ACADEMIC_PROVE = 'ACADEMIC_PROVE'
    const PAYMENT_SLIP = 'PAYMENT_SLIP'
    const SUPPORTING_DOCUMENT = 'SUPPORTING_DOCUMENT'
    const timezone = 'Asia/Hong_Kong';

    const userRole = localStorage.getItem('userRole');

    // used in list view
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const [page, setPage] = useState(0)

    const [keyword, setKeyword] = useState('')

    const [enrollmentList, setEnrollmentList] = useState([])

    const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)

    // used in single record view
    const [currentRecord, setCurrentRecord] = useState(null)
    const [currentIntakeInfo, setCurrentIntakeInfo] = useState(null)

    const [paymentSlipList, setPaymentSlipList] = useState([])

    const [supportingDocumentList, setSupportingDocumentList] = useState([])

    const [paymentRecordList, setPaymentRecordList] = useState([])

    const [academicProve, setAcademicProve] = useState([])

    const [academicLevel, setAcademicLevel] = useState(0)

    const [showImageDialog, setShowImageDialog] = useState(false)

    const [currentImage, setCurrentImage] = useState(null)

    const [loading, setLoading] = useState(false)

    const [showRemovalDialog, setShowRemovalDialog] = useState(false)

    const [showStudentInfoDialog, setShowStudentInfoDialog] = useState(false)
    const [isRemoving, setIsRemoving] = useState(false)

    const [addEnrollPayment] = useAddEnrollPaymentMutation();
    const [deleteEnrollPayment] = useDeleteEnrollPaymentMutation();
    const [deleteEnrollment] = useDeleteEnrollmentMutation();
    const { courseId, intake, studentId} = useParams();
    const navigate = useNavigate();
    const { data } = useGetGeneralCourseQuery({ courseId });
    const [uploadLoading, setUploadLoading] = useState({
        academicProve: false,
        paymentSlip: false,
        hkId: false,
        declaration: false,
    });
    const [uploadQualificationFile] = useUploadAcademicProveMutation();
    const [uploadPaymentSlip] = useUploadPaymentSlipMutation();
    const [uploadSupportDocument] = useUploadSupportingDocumentMutation();

    const refreshEnrollment = () => {
        getEnrollmentRecord(studentId, courseId, intake).then((fetchEnrollment) => {
            setCurrentRecord(fetchEnrollment.data.data);
        });
    }

    const refreshPaymentSection = async () => {
        const paymentRecordResult = await getPaymentRecord(studentId, courseId, intake)
        const paymentRecord = paymentRecordResult.data.data;
        paymentRecord.payment.forEach(p => (p.monthlyPayment = paymentRecord.monthlyPayment));
        setPaymentRecordList(paymentRecord.payment);
    }
    const addPayment = async () => {
            await addEnrollPayment({studentId, courseId, intake});
            await refreshPaymentSection();
    }
    const deletePayment = async (id) => {
        await deleteEnrollPayment(id);
        await refreshPaymentSection()
    }
    useEffect(() => {
        setLoading(true);

        Promise.all([
            getIntakeOnly(courseId, intake),
            getEnrollmentRecord(studentId, courseId, intake),
            getEnrollmentSupportDocumentList(studentId, courseId, intake),
            getPaymentSlipList(studentId, courseId, intake),
        ]).then(results => {
            const [intakeInfo, enrollmentRecord, supportDocument, paymentSlipListResult] = results;

            console.log('Get enrollment record successfully : ', enrollmentRecord);
            console.log('Get supporting document successfully : ', supportDocument);
            console.log('Get payment slip list successfully : ', paymentSlipListResult);

            // Assuming the data property holds the actual data in the response
            const processedEnrollmentRecord = enrollmentRecord.data.data;
            const processedSupportDocument = supportDocument.data.data;
            const processedIntakeInfo = intakeInfo.data.data;
            const processedPaymentSlipListResult = paymentSlipListResult.data.data;

            setSupportingDocumentList(processedSupportDocument);
            setCurrentIntakeInfo(processedIntakeInfo);
            setPaymentSlipList(processedPaymentSlipListResult);
            setCurrentRecord(processedEnrollmentRecord);

            // The following code depends on the enrollment record status

                return getPaymentRecord(studentId, courseId, intake).then(paymentRecordResult => {
                    console.log('Get payment record successfully : ', paymentRecordResult);
                    const paymentRecord = paymentRecordResult.data.data;
                    paymentRecord.payment.forEach(p => (p.monthlyPayment = paymentRecord.monthlyPayment));
                    setPaymentRecordList(paymentRecord.payment);
                    setAcademicLevel(processedEnrollmentRecord.student.academicLevel);
                });

        }).then(() => {
            return getAcademicProveList(studentId);
        }).then(academicProveListResult => {
            console.log('Get academic prove list successfully : ', academicProveListResult);
            const academicProvelist = academicProveListResult.data.data;
            setAcademicProve(academicProvelist);
        }).catch(error => {
            console.error('onTableRowClick error : ', error);
        }).finally(() => {
            setLoading(false);
        });

    }, [courseId, intake, studentId]);


    const refreshUploadFile = async (type) => {
        if(type === 'ACADEMIC_PROVE') {
            const academicProveListResult = await getAcademicProveList(studentId);
            const academicProveList = academicProveListResult.data.data;
            setAcademicProve(academicProveList);
        }else if(type === 'PAYMENT_SLIP') {
            const paymentSlipListResult = await getPaymentSlipList(studentId, courseId, intake);
            const paymentSlipList = paymentSlipListResult.data.data;
            setPaymentSlipList(paymentSlipList);
        }else if(type === 'SUPPORING_DOCUMENT') {
            const supportDocumentListResult = await getEnrollmentSupportDocumentList(studentId, courseId, intake);
            const supportDocumentList = supportDocumentListResult.data.data;
            setSupportingDocumentList(supportDocumentList);
        }
    }


    const onUpdateStatus = async (status) => {
        setLoading(true)
        try {
            setCurrentRecord({
                ...currentRecord,
                status,
                statusDescription: steps[status],
            })
            let updateStatusResult = await updateEnrollmentRecord(currentRecord.studentId, currentRecord.courseId, status, currentRecord.intake)
            refreshEnrollment();
            console.log('Update enrollment status successfully : ', updateStatusResult)
            setShowRemovalDialog(false);
        } catch (error) {
            console.log('onUpdateAsPaidClick error : ', error)
        } finally {
            setLoading(false)
        }
    }

    const onApproveAcademicProveClick = async () => {
        if (!academicLevel) {
            alert('Please choose an academic level')
            return
        }
        setLoading(true)
        try {
            let studentId = currentRecord.studentId
            let courseId = currentRecord.courseId
            let intake = currentRecord.intake

            let approveResult = await updateAcademicLevel(studentId, academicLevel)
            console.log('Approve academic level successfully : ', approveResult)
            let updateStatusResult = await updateEnrollmentRecord(studentId, courseId, ENROLLMENT_STATUS_UNPAID, intake)
            console.log('Update enrollment status successfully : ', updateStatusResult)
            setCurrentRecord({
                ...currentRecord,
                status: ENROLLMENT_STATUS_UNPAID,
            })

            // get payment record
            let paymentRecordResult = await getPaymentRecord(studentId, courseId, intake)
            console.log('Get payment record successfully : ', paymentRecordResult)
            paymentRecordResult = paymentRecordResult.data.data
            paymentRecordResult.payment.map((p) => (p.monthlyPayment = paymentRecordResult.monthlyPayment))
            setPaymentRecordList(paymentRecordResult.payment)

            setLoading(false)
        } catch (error) {
            console.log('Approve academic prove error : ', error)
            setLoading(false)
        }
    }

    const onPaymentAmountChange = (e, index) => {
        let amount = e.target.value
        let tempList = [...paymentRecordList]
        tempList[index] = { ...tempList[index], amount: amount }
        setPaymentRecordList(tempList)
    }

    const onTransferDateChange = (date, index) => {
        let tempList = [...paymentRecordList]
        tempList[index] = { ...tempList[index], transferDate: date.format('YYYY-MM-DD') }
        setPaymentRecordList(tempList)
    }

    const onPaymentRecordApproveClick = async (paymentRecord, index) => {
        try {
            let result = await updatePaymentRecord({
                ...paymentRecord,
                paymentStatus: 'APPROVE',
                transferredAmt: paymentRecord.amount,
            })
            console.log('Update payment record successfully : ', result)
            let tempList = [...paymentRecordList]
            tempList[index] = { ...tempList[index], status: ENROLLMENT_STATUS_PAID }
            setPaymentRecordList(tempList)
        } catch (error) {
            console.log('Update payment record error : ', error)
        }
    }

    const readyShowImageDialog = async (imageUrl, documentType, index) => {
        if (imageUrl) {
            setShowImageDialog(true)
            setCurrentImage(imageUrl)
        } else {
            setLoading(true)
            try {
                let image
                let url
                let tempList
                let tempObj
                switch (documentType) {
                    case ACADEMIC_PROVE:
                        image = await downloadDocument(academicProve[index].path)
                        url = URL.createObjectURL(image.data)
                        tempList = [...academicProve]
                        tempObj = { ...academicProve[index], url: url }
                        tempList[index] = tempObj
                        setAcademicProve(tempList)
                        break
                    case PAYMENT_SLIP:
                        image = await downloadDocument(paymentSlipList[index].path)
                        url = URL.createObjectURL(image.data)
                        tempList = [...paymentSlipList]
                        tempObj = { ...paymentSlipList[index], url: url }
                        tempList[index] = tempObj
                        setPaymentSlipList(tempList)
                        break
                    case SUPPORTING_DOCUMENT:
                        image = await downloadDocument(supportingDocumentList[index].path)
                        url = URL.createObjectURL(image.data)
                        tempList = [...supportingDocumentList]
                        tempObj = { ...supportingDocumentList[index], url: url }
                        tempList[index] = tempObj
                        setSupportingDocumentList(tempList)
                        break
                }
                setShowImageDialog(true)
                setCurrentImage(url)
            } catch (error) {
                console.log('Download images error : ', error)
            } finally {
                setLoading(false)
            }
        }
    }

    const onImageDialogClose = () => {
        setShowImageDialog(false)
        setCurrentImage(null)
    }

    const downloadDocumentToLocal = async (doc) => {
        try {
            let file = await downloadDocument(doc.path)
            const e = document.createElement('a')
            e.href = URL.createObjectURL(file.data)
            e.download = doc.documentName
            e.click()
        } catch (error) {
            console.log('Download document error : ', error)
        }
    }

    const onDocumentClick = async (doc, documentType, index) => {
        console.log("doc : ", doc);
        if (doc.contentType.includes('image')) {
            console.log("ready show images dialog");
            readyShowImageDialog(doc.url, documentType, index)
        } else {
            console.log("download document");
            downloadDocumentToLocal(doc)
        }
    }

    const onRemovePaymentRecord = (index, paymentId) => {
        const updatedList = paymentRecordList.filter((_, i) => i !== index);
        setPaymentRecordList(updatedList);
        deletePayment(paymentId).finally();
    };

    const handleQualificationFileSelect = async (e) => {
        let file = e.target.files[0]
        setUploadLoading({
            ...uploadLoading,
            academicProve: true,
        });
        await uploadQualificationFile({
            studentId,
            file,
        });
        await refreshUploadFile('ACADEMIC_PROVE');
        setUploadLoading({
            ...uploadLoading,
            academicProve: false,
        });
    }

    const handlePaymentSlipFileSelect = async (e) => {
        let file = e.target.files[0]
        setUploadLoading({
            ...uploadLoading,
            paymentSlip: true,
        });
        await uploadPaymentSlip({
            studentId,
            courseId,
            intake,
            file,
        });
        await refreshUploadFile('PAYMENT_SLIP');
        setUploadLoading({
            ...uploadLoading,
            paymentSlip: false,
        });

    }

    const handleUploadSupportingDocument = async (e, documentKey) => {
        let file = e.target.files[0]
        {documentKey === 'HKID' ? setUploadLoading({
            ...uploadLoading,
            hkId: true
        }) : setUploadLoading({
            ...uploadLoading,
            declaration: true
        })}
        await uploadSupportDocument({
            studentId,
            courseId,
            intake,
            file,
            documentKey,
        });
        await refreshUploadFile('SUPPORING_DOCUMENT');
        {documentKey === 'HKID' ? setUploadLoading({
            ...uploadLoading,
            hkId: false
        }) : setUploadLoading({
            ...uploadLoading,
            declaration: false
        })}
    }


    if(userRole === 'TUTOR'){
        return <AccessControl></AccessControl>;
    }

    if(!currentRecord) {
        return "";
    }
    return (
        <Box>
            <Box sx={{ p: 8, mt: 3, borderRadius: 5, backgroundColor: 'white' }}>
                    {/* Page Title */}
                <Stack direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {currentRecord.courseNameEng || currentRecord.courseName}
                    </Typography>
                    {isRemoving ?
                        <CircularProgress size={18} /> :
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation()
                            if (window.confirm("Are you sure to remove? It will delete all the enrollment related data.")) {
                                setIsRemoving(true);
                                deleteEnrollment({studentId: currentRecord.studentId, courseId: currentRecord.courseId, intake: currentRecord.intake}).then(() => {
                                    setIsRemoving(false);
                                    navigate(`/intake/${courseId}/${intake}`);
                                });
                            }
                        }}
                        size="small"

                    >
                        <DeleteIcon />
                    </IconButton>
                    }

                </Stack>
                    {/* Description */}
                    <Typography variant="body1" gutterBottom>
                        Intake {currentRecord.intake} <strong>#{currentRecord.applicationNo}</strong>
                    </Typography>

                    {/* Cards */}
                    <Box my={4}>
                        <Grid container spacing={2}>
                            {/* Core Web Vitals Card */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined" sx={{ height: 220}}>
                                    <CardContent>
                                        <Box display="flex" >
                                            <Stack direction={"row"} >
                                                <InfoOutlinedIcon fontSize="small" sx={{ m: 1, color: "rgba(46, 125, 50)"}}  />
                                                <Typography variant="h6">Enrollment</Typography>
                                            </Stack>
                                        </Box>
                                        <Grid container spacing={2} mt={2} ml={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="textSecondary">English Name</Typography>
                                                <Typography variant="h6">{currentRecord.student.nameEn}</Typography>
                                                <Typography variant="body2" color="textSecondary">Tel: {currentRecord.student.mobile}  (<Link href={`https://api.whatsapp.com/send?phone=${currentRecord.student.mobile}`} target="_blank">Open</Link>)</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="textSecondary">Status</Typography>
                                                <Typography variant="h6" color={`${currentRecord.status === 99 ? "error": "success.main"}`}>{currentRecord.statusDescription}</Typography>
                                                <Typography variant="body2" color="textSecondary">Enroll on {moment(currentRecord.enrolTime).format("YYYY-MM-DD HH:mm")}</Typography>
                                            </Grid>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* HTTPS Card */}
                            <Grid item xs={12} md={6}>
                                <Card variant="outlined" sx={{ height: 220}}>
                                    <CardContent>
                                        <Box display="flex">
                                            <Stack direction={"row"} >
                                                <PersonIcon fontSize="small" sx={{ m: 1, color: "rgba(46, 125, 50)"}}  />
                                                <Typography variant="h6">Applicant Information</Typography>
                                            </Stack>
                                        </Box>
                                        <Stack direction={"row"} spacing={3}  sx={{ p: 3}}>
                                        <Box>
                                            <Typography variant="h6" color="success.main">{currentRecord.student.hkid}</Typography>
                                            <Typography variant="body2" color="textSecondary">Identity Document No</Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="h6" color="success.main">{moment(currentRecord.student.dob).format("YYYY-MM-DD")}</Typography>
                                            <Typography variant="body2" color="textSecondary">Date of birth</Typography>
                                        </Box>
                                        </Stack>
                                        <Box display="flex" justifyContent="flex-end">
                                            <IconButton size="small" onClick={() => {
                                                navigate(`/intake/${courseId}/${intake}/student/${currentRecord.studentId}`);
                                            }}>
                                                <ArrowForwardIosIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Other aspects of page experience */}
                    <Typography variant="h6" gutterBottom>
                        Reject application
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Once you reject this application, the applicant will be notified and the application will be removed from the intake list and this application could not be recovered. Are you sure you want to reject this application?
                    </Typography>

                    {/* Learn More Button */}
                    <Box mt={4}>
                        <Button variant="contained" color="error" disabled={currentRecord.status > 0} onClick={() => {
                            if (window.confirm("It could not be recovered. Are you sure you want to reject this application?")) {
                                onUpdateStatus(99)
                            }
                        }}>Confirm Reject</Button>
                    </Box>

            </Box>
            <Box sx={{p: 8, mt: 3, borderRadius: 5, backgroundColor: 'white'}}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Qualification Review
                </Typography>
                <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                    <Box>
                        <Typography variant="body2" component="h2" mt={2} mb={2}>Uploaded File</Typography>
                        <Stack spacing={1}>
                            {academicProve.map((document, index) => (
                                <Stack direction="row" spacing={1}>
                                    <DescriptionIcon  fontSize="small" />
                                    {userRole === 'DIRECTOR' || currentRecord.status === ENROLLMENT_STATUS_REVIEW ?
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            onDocumentClick(document, ACADEMIC_PROVE, index)
                                        }}
                                    >{document.documentName}</Link>
                                        :
                                        <Typography variant={"body2"}>{document.documentName}</Typography>
                                    }
                                </Stack>
                            ))}
                            <Box mt={2} />
                            <LoadingButton variant="outlined" component="label" loading={uploadLoading.academicProve} startIcon={<CloudUpload />} sx={{ width: 170}}>
                                Upload File
                                <input type="file" hidden onChange={handleQualificationFileSelect} />
                            </LoadingButton>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack spacing={2}>
                            <FormControl sx={{ width: 200}} disabled={currentRecord.status > 0}>
                                <InputLabel>Academic Level</InputLabel>
                                <Select
                                    label="Academic Level"
                                    value={academicLevel}
                                    onChange={(e) => setAcademicLevel(e.target.value)}
                                >
                                    {Array.from(Array(8).keys()).map((i) => (
                                        <MenuItem key={i} value={i}>
                                            {i}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="contained"
                                onClick={onApproveAcademicProveClick}
                                disabled={currentRecord.status > 0}
                            >
                                Confirm
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{p: 8, mt: 3, borderRadius: 5, backgroundColor: 'white'}}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Payment Record
                </Typography>
                <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                    <Box>
                        <Typography variant="body2" component="h2" mt={2} mb={2}>Uploaded File</Typography>
                        <Stack spacing={1}>
                            {paymentSlipList.map((paymentSlip, index) => (
                                <Stack>
                                    <strong>{moment(paymentSlip.uploadTime).format('D MMM [,] YYYY HH[:]mm')}</strong>
                                    <Stack direction="row" spacing={1}>
                                        <DescriptionIcon fontSize="small"/>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                onDocumentClick(paymentSlip, PAYMENT_SLIP, index)
                                            }}
                                        >{paymentSlip.documentName}</Link>
                                    </Stack>
                                </Stack>

                            ))}
                            <Box mt={2} />
                            <LoadingButton variant="outlined" component="label" loading={uploadLoading.paymentSlip} startIcon={<CloudUpload />} sx={{ width: 170}}>
                                Upload File
                                <input type="file" hidden onChange={handlePaymentSlipFileSelect} />
                            </LoadingButton>
                        </Stack>
                    </Box>
                    <Stack spacing={2} alignItems={"end"} alignContent={"flex-end"}>
                        {paymentRecordList.map((paymentRecord, index) => (
                            <Card variant="outlined" key={index} sx={{position: 'relative'}}>
                                <CardContent>
                                    <Box display="flex" flexDirection="column">
                                        <Stack spacing={2} width="100%">
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Typography variant="subtitle1" fontWeight="bold" component="h2"
                                                            gutterBottom>
                                                    Payment {index + 1}
                                                </Typography>
                                                {paymentRecord.status === ENROLLMENT_STATUS_PAID &&
                                                    <Chip label={paymentRecord.stripeId ? 'Stripe' : 'Manual'} color="default" variant="outlined" sx={{mr: 1}}/>
                                                }
                                            </Stack>
                                            <Stack direction="row" spacing={2} alignItems="center"
                                                   justifyContent="space-between">
                                                <TextField
                                                    className={style.paymentAmount}
                                                    label="Payment Amount"
                                                    placeholder="Payment Amount"
                                                    value={paymentRecord.amount}
                                                    onChange={(e) => onPaymentAmountChange(e, index)}
                                                    required
                                                    disabled={paymentRecord.status === ENROLLMENT_STATUS_PAID}
                                                />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Transfer Date"
                                                        value={dayjs(paymentRecord.transferDate)}
                                                        onChange={(date) => onTransferDateChange(date, index)}
                                                        renderInput={(params) => (
                                                            <TextField className={style.transferDate} {...params} />
                                                        )}
                                                        inputFormat="DD/MM/yyyy"
                                                        disabled={paymentRecord.status === ENROLLMENT_STATUS_PAID}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                            <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                                                <Box>
                                                    {paymentRecord.stripeId &&
                                                        <Link
                                                            href={`https://dashboard.stripe.com/test/payments/${paymentRecord.stripeId}/review`}
                                                            target="_blank"
                                                        >{paymentRecord.stripeId}
                                                        </Link>
                                                    }
                                                </Box>
                                                <Button
                                                    variant="outlined"
                                                    disabled={paymentRecord.status === ENROLLMENT_STATUS_PAID}
                                                    onClick={() =>
                                                        onPaymentRecordApproveClick(paymentRecord, index)
                                                    }
                                                >
                                                    Acknowledge
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </CardContent>
                                {paymentRecord.status !== ENROLLMENT_STATUS_PAID && (paymentRecordList.length > 1 && index !== 0) &&
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => onRemovePaymentRecord(index, paymentRecord.paymentId)}
                                        sx={{
                                            position: 'absolute',
                                            top: 2,
                                            right: 2,
                                            color: 'grey',
                                            '&:hover': {
                                                color: 'red',
                                            },
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                }
                            </Card>
                        ))}
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={addPayment}>
                            Add Payment
                        </Button>
                    </Stack>
                </Stack>
                <Divider sx={{mt: 3, mb: 3, color: 'grey'}}/>
                <Typography variant="body2" component="h2">
                    If the application fulfill the minimum payment requirement, click "Acknowledge Payment" to proceed.
                </Typography>

                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained"
                                    disabled={currentRecord.status !== 1}
                                    onClick={() => onUpdateStatus(2)}
                            >Acknowledge Payment</Button>
                            <Button variant="outlined"
                                    disabled={currentRecord.status !== 2}
                                    onClick={() => onUpdateStatus(3)}
                            >Send Receipt Reminder</Button>
                        </Stack>

            </Box>
            <Box sx={{p: 8, mt: 3, borderRadius: 5, backgroundColor: 'white'}}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Supporting Document
                </Typography>
                <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                    <Box>
                        <Typography variant="body2" component="h2" mt={2} mb={2}>Uploaded File</Typography>
                        <Stack spacing={1}>
                            {supportingDocumentList.map((supportDocument, index) => (
                                <Stack>
                                    <strong>{moment(supportDocument.uploadTime).format('D MMM [,] YYYY HH[:]mm')}</strong>
                                    <Stack direction="row" spacing={1}>
                                        <DescriptionIcon fontSize="small"/>
                                        {userRole === 'DIRECTOR' || currentRecord.status < 4 ?
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                onDocumentClick(supportDocument, SUPPORTING_DOCUMENT, index)
                                            }}
                                        >{supportDocument.documentName}</Link> :
                                            <Typography variant={"body2"}>{supportDocument.documentName}</Typography>
                                        }
                                    </Stack>

                                </Stack>

                            ))}
                        </Stack>
                        <Box mt={2} />
                        <Stack direction={"row"} spacing={1}>
                        <LoadingButton variant="outlined" component="label" loading={uploadLoading.hkId} startIcon={<CloudUpload />} sx={{ width: 120}}>
                            HKID
                            <input type="file" hidden onChange={(e) => handleUploadSupportingDocument(e, "HKID")} />
                        </LoadingButton>
                            <LoadingButton variant="outlined" component="label" loading={uploadLoading.declaration} startIcon={<CloudUpload />} sx={{ width: 200}}>
                                Declaration
                                <input type="file" hidden onChange={(e) => handleUploadSupportingDocument(e, "DECLARATION")} />
                            </LoadingButton>
                        </Stack>
                    </Box>
                    <Box>
                    <Button
                        variant={"contained"}
                        onClick={() => onUpdateStatus(4)}
                        disabled={currentRecord.status != 3}
                    >
                        Approve Document
                    </Button>
                    </Box>
                </Stack>
            </Box>
            <div className={style.parentContainer}>
                <Dialog open={showImageDialog} onClose={onImageDialogClose} maxWidth="xl">
                    {/* Need add a div to do styling */}
                    <div className={style.imageDialog}>
                        <img src={currentImage} />
                    </div>
                </Dialog>



            </div>
        </Box>
    )
}

export default Enrollment
