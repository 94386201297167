import { getToken, mainRequest } from './request'

export const getCourseGeneralList = (pageNum, pageSize) =>
    mainRequest.post('/course/getGeneralList', {
        pageNum,
        pageSize,
    })

export const getCourseIntakeListByCourse = (courseId) =>
mainRequest.post('/course/getFullIntake', {
    courseId
})

export const updateGeneral = (courseId, generalClass) =>
    mainRequest.post('/course/updateGeneral', {
        ...generalClass,
        courseId,
    })

export const getGeneral = (courseId) =>
    mainRequest.post('/course/getGeneral', {
        courseId,
    })

export const createCourse = (course) => mainRequest.post('/course/create', course)

export const getCourseIntakeList = (pageNum, pageSize) =>
    mainRequest.post('/course/getIntakeList', {
        pageNum,
        pageSize,
    })

export const getIntakeOnly = (courseId, intake) =>
    mainRequest.post('/course/getIntake', {
        courseId,
        intake,
    })

export const updateIntake = (courseId, intake, intakeDetail) =>
    mainRequest.post('/course/updateIntake', {
        courseId,
        intake,
        ...intakeDetail,
    })

export const updateIntakeStatus = (courseId, intake, status) =>
    mainRequest.post('/course/updateIntakeStatus', {
        courseId,
        intake,
        status,
    })

export const getWeeks = (courseId, intake) =>
    mainRequest.post('/course/getWeeks', {
        courseId,
        intake,
    })

export const addWeeks = (courseId, intake, weeks) =>
    mainRequest.post('/course/addWeeks', {
        courseId,
        intake,
        weeks,
    })

export const editWeek = (courseId, intake, week) =>
    mainRequest.post('/course/editWeek', {
        courseId,
        intake,
        week,
    })

export const deleteWeek = (courseId, intake, weekId) =>
    mainRequest.post('/course/deleteWeek', {
        courseId,
        intake,
        weekId,
    })

export const addClass = (newClass) => mainRequest.post('/course/addClass', newClass)

export const addMaterial = (material) => mainRequest.post('/course/addMaterial', material)

export const addAssignment = (assignment) => mainRequest.post('/course/addAssignment', assignment)

export const editClass = (editClass) => mainRequest.post('/course/editClass', editClass)

export const editMaterial = (material) => mainRequest.post('/course/editMaterial', material)

export const editAssignment = (assignment) => mainRequest.post('/course/editAssignment', assignment)

export const uploadCourseDocument = (courseId, file, docType) => {
    let formData = new FormData()
    formData.append('courseId', courseId)
    formData.append('file', file)
    formData.append('docType', docType)
    return mainRequest.post('/course/uploadDoc', formData)
}

export const uploadSupportingDocument = (courseId, description, file) => {
    let formData = new FormData()
    formData.append('courseId', courseId)
    formData.append('description', description)
    formData.append('file', file)
    return mainRequest.post('/course/uploadSupportingDoc', formData)
}

export const uploadAssignment = (assignmentId, file) => {
    let formData = new FormData()
    formData.append('assignmentId', assignmentId)
    formData.append('file', file)
    return mainRequest.post('/course/uploadAssignment', formData)
}

// {{dev-main}}/main/api/course/getAssignmentDoc
export const getAssignmentDoc = (assignmentId) =>
    mainRequest.post('/course/getAssignmentDoc', {
        assignmentId,
    })
    

export const getSupportDocument = (courseId) =>
    mainRequest.post('/course/getSupportDoc', {
        courseId,
    })

export const cloneIntake = (courseId, intake, nextIntake) =>
    mainRequest.post('/course/cloneCourse', {
        courseId,
        intake,
        nextIntake
    })

export const assignIntakeTutor = (courseId, intake, tutorId) =>
    mainRequest.post('/course/assignIntakeTutor', {
        courseId,
        intake,
        tutorId
    })

export const deleteMaterial = (courseId, intake, weekId, materialId) =>
    mainRequest.post('/course/deleteMaterial', {
        courseId,
        intake,
        weekId,
        materialId,
    })

export const deleteAssignment = (courseId, intake, weekId, assignmentId) =>
    mainRequest.post('/course/deleteAssignment', {
        courseId,
        intake,
        weekId,
        assignmentId,
    })

export const deleteClass = (courseId, intake, weekId, classId) =>
    mainRequest.post('/course/deleteClass', {
        courseId,
        intake,
        weekId,
        classId,
    })