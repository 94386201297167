
import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import {useEffect, useState} from "react";
import {
    useGetGeneralCourseQuery, useGetIntakeMutation,
    useGetIntakeStudentListMutation, useGetMeetingDetailMutation,
    useGetStudentParticipantMutation,
    useGetStudentPollMutation,
} from "../../../../api/courseIntakeApi";
import {useNavigate, useParams} from 'react-router-dom';
import  {CustomColumnMenu, defaultColumnType} from "../../../PollInventory/PollInventoryList";
import {
    Breadcrumbs,
    Button,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import moment from "moment";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {
    useCreateStudentAttendanceMutation, useGetAttendClassAttendanceMutation,
    useGetMakeupClassAttendanceMutation,
    useRemoveStudentAttendanceMutation
} from "../../../../api/attendanceApi";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import {useDispatch} from "react-redux";
export default function ParticipationList({ state }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { courseId, intake } = useParams();
    const [fetchParticipant] = useGetStudentParticipantMutation();
    const [fetchIntakeStudentList] = useGetIntakeStudentListMutation();
    const [fetchMeetingDetail] = useGetMeetingDetailMutation();
    const [fetchPolls] = useGetStudentPollMutation();
    const [addStudentAttendance] = useCreateStudentAttendanceMutation();
    const [removeStudentAttendance] = useRemoveStudentAttendanceMutation();
    const [data, setData] = useState(null);
    const [participantData, setParticipantData] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [meetingData, setMeetingData] = useState(null);
    const { data: courseGeneral } = useGetGeneralCourseQuery({ courseId });
    const [getMakeupClassList] = useGetMakeupClassAttendanceMutation();
    const [getAttendClassList] = useGetAttendClassAttendanceMutation();
    const [fetchCourseIntake] = useGetIntakeMutation();
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        if(state) {
            refresh(state.zoomId);
        }
    }, [state]);

    if(!state){
        return ""
    }
    const { name, type, startDate, endDate, classId, assignedTutorId, editClass, tutorName } = state;

    const addAttend = (studentId, previousClassId) => {
        addStudentAttendance({ courseId, intake,  classId: state.classId, studentList: [{
                classId: previousClassId,
                studentId
            }]
        });
    }
    const removeAttend = (studentId, previousClassId) => {
        removeStudentAttendance({
            courseId, intake, classId: state.classId, studentList: [{
                classId: previousClassId,
                studentId
            }]
        });
    }

    const refresh = (meetingId) => {
        Promise.all([
            getAttendClassList({ courseId, intake, classId: state.classId }),
            getMakeupClassList({ courseId, intake, classId: state.classId }),
        ]).then(([normalData, makeupData] ) => {
            const { data: content } = normalData.data;
            const { data: makeupContent } = makeupData.data;
            const merge = [
                ...content,
                ...makeupContent
            ];
            setData(merge);
            const selected = merge
                .filter(item => item.takeAttendDate)
                .map(item => item.studentId);
            setRowSelectionModel(selected);
        });
        fetchParticipant({ meetingId }).then(({ data }) => {
            if(data.participants){
                const { participants } = data;
                setParticipantData(participants);
            }
        });
        fetchPolls({ meetingId }).then(({ data }) => {
            if(data.questions){
                const { questions } = data;
                setPollData(questions);
            }
        });
        fetchMeetingDetail({ meetingId }).then(({ data }) => {
            if(data?.id){
                setMeetingData(data);
            }
        });

    };




    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Attendance",
            renderCell: (params) => {
                return (
                    <>
                        <Stack>
                        <Stack direction="row" spacing={1}>
                        <Typography variant="body2">{params.row.studentName}</Typography>
                            {params.row.previousClassId &&
                                <Chip color={"success"} sx={{ blockSize: 20}} style={{ marginLeft: 8, marginTop: 1}} label={`Intake ${params.row.previousIntake}`} />
                            }
                            </Stack>
                        ({params.row.email})
                        </Stack>
                    </>
                )
            },
            width: 280
        },
        {
            field: "meetingTime",
            ...defaultColumnType,
            headerName: "In meeting time",
            flex: 1,
            renderCell: (params) => {
                const participantRecord  = participantData.filter((participant) => participant.user_email === params.row.email && participant.status === 'in_meeting');
                if(participantRecord.length === 0){
                    return "N/A"
                }
                return (
                    <Stack spacing={1}>
                        {participantRecord.map(item => {
                        return (
                           <span>{moment(item.join_time).format("YYYY-MM-DD HH:mm:ss")} - {moment(item.leave_time).format("YYYY-MM-DD HH:mm:ss")}</span>
                        )

                    })}
                    </Stack>
                )
            },
        },
        {
            field: "assessment",
            ...defaultColumnType,
            headerName: "Assessment",
            renderCell: (params) => {
                const pollRecord  = pollData.find((poll) => poll.email === params.row.email);
                if(!pollRecord || pollRecord.question_details.length === 0){
                    return "N/A"
                }
                return (
                                <span>Answered {pollRecord.question_details.length} question(s)</span>
                )
            },
            flex: 1
        },
        {
            field: "participantRate",
            ...defaultColumnType,
            renderHeader: (params) => {
                return (
                     <span>Participantion (Total: {meetingData?.duration || "0"} mins)</span>
                )
            },
            renderCell: (params) => {
                const participantRecord  = participantData.filter((participant) => participant.user_email === params.row.email && participant.status === 'in_meeting');
                if(participantRecord.length === 0){
                    return "N/A"
                }
                let totalDuration = 0;
                participantRecord.map(item => {
                    totalDuration += item.duration / 60;
                });
                return (
                    <span>{totalDuration.toFixed(2)} mins ({(totalDuration / meetingData?.duration * 100).toFixed(2)}%)</span>
                )
            },
            flex: 1
        },

    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },

    }

    const handleTutorChange = (classId, obj) => (event) => {
        editClass({
            ...obj,
            courseId,
            intake,
            assignedTutorId: event.target.value || null,
        });
    };


    return (

        <Box sx={{ width: '100%', mt: 3, backgroundColor: 'white'}}>
            <Stack direction="row" alignContent="space-between" justifyContent="space-between">
                <Box>
                    <Stack direction="row" alignContent="center" spacing={2} p={5} pb={0}>
                        <IconButton onClick={() => dispatch(setIntakeTab({ route: "Class Detail"}))}>
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <Stack>
                            <Stack direction="row" spacing={1}>
                            <Typography variant="body1" fontWeight="bold">{name}</Typography>
                            <Chip label={type} color={type === 'ONLINE' ? 'success' : 'default'} variant="outlined" sx={{ blockSize: '90%', mr: 1 }} />
                            </Stack>
                            <Typography>
                            {moment(startDate).format("YYYY-MM-DD hh:mm")} - {moment(endDate).format("hh:mm")}
                        </Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Box mr={5} mt={3}>
                    <Box width={200} mt={1}>
                        <Typography variant="body2" fontWeight={"bold"}>Assigned Tutor</Typography>
                        <Typography variant="body2">{tutorName}</Typography>
                    </Box>
                </Box>
            </Stack>
                        <StyledTableOne
                            autoHeight
                            rows={data || []}
                            columns={columns}
                            slots={{
                                columnMenu: CustomColumnMenu,
                            }}
                            initialState={initState}
                            rowHeight={80}
                            getRowId={(row) => row.studentId}
                            disableRowSelectionOnClick
                            checkboxSelection
                            loading={data === null}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                const added = newRowSelectionModel.find(id => !rowSelectionModel.includes(id));
                                const removed = rowSelectionModel.find(id => !newRowSelectionModel.includes(id));


                                const isMakeup = (id) => {
                                    return data.some(item => item.studentId === id && item.previousClassId);
                                }

                                let previousClassId = null;

                                if(isMakeup(added) || isMakeup(removed)){
                                    previousClassId = data.find(item => item.studentId === (added || removed)).previousClassId;
                                }
                                if (added) {
                                    addAttend(added, previousClassId);
                                }

                                if (removed) {
                                   removeAttend(removed, previousClassId);
                                }
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            pageSizeOptions={[5]}
                            pagination
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-row': {
                                    cursor: 'pointer', // Change mouse cursor to pointer
                                },
                                  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    display: "none"
                                  }

                            }}
                        />
                    </Box>
    );
}
