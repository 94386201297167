import CheckinTable from "./CheckinTable";
import {Breadcrumbs} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from "react-router-dom";
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import Box from "@mui/material/Box";
export default function CheckinPage() {
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
        <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
                onClick={() => navigate("/timesheet")}
                label="Timesheet System"
                icon={<DomainVerificationIcon fontSize="small" />}
            />
        </Breadcrumbs>
        </Box>
            <Box sx={{ p: 4, borderRadius: 5, backgroundColor: 'white'}}>
        <CheckinTable />
            </Box>
        </Box>
    )
}
