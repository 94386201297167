import ScheduleTable from "./ScheduleTable";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import SchoolIcon from "@mui/icons-material/School";
import Box from "@mui/material/Box";
export default function ScheduleAssistantPage() {
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb label="Schedule Assistant" icon={<Icon fontSize={"small"} >calendar_month</Icon>} />
                </Breadcrumbs>
            </Box>
            <Box sx={{ p: 4, borderRadius: 5, backgroundColor: 'white'}}>
            <ScheduleTable />
            </Box>
        </Box>
    )
}
