import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import EditMasterCourse from "./EditMasterCourse";
export default function EditCoursePage() {
    const { courseId } = useParams();
    const navigate = useNavigate();
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        onClick={() => navigate("/course")}
                        label="Course"
                        icon={<Icon fontSize={"small"}>school</Icon>}
                    />
                    <StyledBreadcrumb label={`${courseId === "create" ? "New" : "Edit"}`} />
                </Breadcrumbs>
            </Box>
            <EditMasterCourse />
        </Box>
    )
}
