import React, { useState, useEffect } from 'react'
import TablePage from '../UI/TablePage';
import { getCourseGeneralList, getCourseIntakeListByCourse, getWeeks } from '../../utils/request/course';
import { Select, FormControl, InputLabel, MenuProps, MenuItem, Button } from '@mui/material';
import moment from 'moment';
import { getZoomParticipant, getZoomReport, getZoomPollsReport } from '../../utils/request/zoom';
import style from './Report.module.scss';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import AccessControl from '../UI/AccessControl';

const Report = () => {

    const userRole = localStorage.getItem('userRole');

    const [isLoading, setIsLoading] = useState(false)
    const [courseGeneralList, setCourseGeneralList] = useState([])
    const [courseIntakeList, setCourseIntakeList] = useState([])
    const [courseClassList, setCourseClassList] = useState([])

    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedIntake, setSelectedIntake] = useState(null)
    const [selectedClass, setSelectedClass] = useState(null)

    const [participantData, setParticipantData] = useState(null);
    const [detailData, setDetailData] = useState(null);

    const onGetZoomParticipant = async (zoomId) => {
        try {
            let res = await getZoomParticipant(zoomId)
            let data = await res.data.participants;
            setParticipantData(data)
            data && data.code === "SYS9999" ?   alert('No Meeting data') : generatePDF(data, 'PARTICIPANT');
            console.log('Get zoom participant successfuly : ', data)
        } catch (error) {
            console.log('Get zoom participant error : ', error)
        }
    }

    const onGetZoomReport = async (zoomId) => {
        try {
            let res = await getZoomReport(zoomId);
            let data = await res.data;
            setDetailData(data);
            data && data.code === "SYS9999" ?   alert('No Meeting data') : generatePDF(data ,'DETAIL');
            console.log('Get zoom report successfuly : ', res)
        } catch (error) {
            console.log('Get zoom report error : ', error)
        }
    }

    const onGetZoomPollsReport = async (zoomId) => {
        try {
            let res = await getZoomPollsReport(zoomId);
            let data = await res.data.questions;
            console.log('Get zoom polls report successfuly : ', data)
            data && data.code === "SYS9999" ?   alert('No Meeting data') : generatePDF(data, 'POLLS');
        } catch (error) {
            console.log('Get zoom polls report error : ', error)
        }
    }


    const generatePDF = (data, type) => {
        const doc = new jsPDF();
        let tableColumn = [];
        let tableRows = [];

        let tableColumn2 = [];
        let tableRows2 = [];
        switch (type) {
            case 'PARTICIPANT':
                tableColumn = ['Name', 'Email', 'Duration (min)', 'Join Time', 'Leave Time'];
                tableRows = [];
                data && data.forEach(item => {
                    const rowData = [
                        item.name,
                        item.user_email? item.user_email: 'N/A',
                        Math.round(item.duration / 60, 2),
                        moment(item.join_time).format('DD, MMM YYYY HH:mm:ss'),
                        moment(item.leave_time).format('DD, MMM YYYY HH:mm:ss'),
                    ];
                    tableRows.push(rowData);
                });
                autoTable(
                    doc,
                    {
                        head: [tableColumn],
                        body: tableRows,
                        startY: 30,
                    }
                )
                doc.text('Zoom Class Participant Report', 10, 10);
                doc.text(`${selectedClass.name}: ${moment(selectedClass.startDate).format('DD, MMM YYYY HH:mm')} - ${moment(selectedClass.endDate).format('DD, MMM YYYY HH:mm')}`, 10, 20);
                doc.save('Report_Participant.pdf');

                break;
            case 'DETAIL':
                tableColumn = ['Key', 'Detail'];
                tableRows = [];
                    console.log('detailData', data)
                    data && Object.entries(data).forEach(([key, value]) => {
                    const rowData = [key, value];
                    tableRows.push(rowData);
                });
                autoTable(
                    doc,
                    {
                        head: [tableColumn],
                        body: tableRows,
                    }
                )
                console.log('tableRows', tableRows);
                doc.text('Zoom Class Detail Report', 10, 10);
                doc.save('Report_Detail.pdf');
                break;
            case 'POLLS':
                tableColumn = ['Firstname', 'name'];
                tableRows = [];

                tableColumn2 = ['Question', 'Date', 'Answer'];
                tableRows2 = [];

                console.log('polls data', data);
                data.map((item) => {
                    const rowData = [item.first_name, item.name];
                    tableRows.push(rowData);
                    item.question_details.map((question) => {
                        const rowData = [question.question, question.date_time, question.answer];
                        tableRows2.push(rowData);
                    })
                });

                autoTable(
                    doc,
                    {
                        head: [tableColumn],
                        body: tableRows,
                        startY: 30,
                    }
                )

                autoTable(
                    doc,
                    {
                        head: [tableColumn2],
                        body: tableRows2,
                        startY: doc.lastAutoTable.finalY + 20,
                    }
                )
                console.log('tableRows', tableRows);
                doc.text('Zoom Class Poll Report', 10, 10);
                doc.save('Report_Polls.pdf');
                break;
        }

    };


    const init = async () => {
        setIsLoading(true)
        try {
            let list = await getCourseGeneralList(0, 20)
            console.log('Get course general list successfuly : ', list)
            setCourseGeneralList(list.data.data.content)
        } catch (error) {
            console.log('Get course general list error : ', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCourseChange = async (event) => {
        setSelectedIntake(null);
        setCourseClassList([]);
        const {
            target: { value },
        } = event;
        setSelectedCourse(event.target.value)
        try {
            let res = await getCourseIntakeListByCourse(value)
            console.log('Get course intake list successfuly : ', res)
            setCourseIntakeList(res.data.data.intakes)

        } catch (error) {
            console.log('Get course intake list error : ', error)
        }
    }

    const handleIntakeChange = async (event) => {
        setCourseClassList([]);
        const {
            target: { value },
        } = event;
        setSelectedIntake(value);
        try {
            let res = await getWeeks(selectedCourse, value)
            console.log('Get course class list successfuly : ', res)
            let list = []
            res.data.data.weeks.map((week) => {
                week.classes.map((classItem) => {
                    list.push(classItem)
                })
            })
            console.log('courseClassList', list);
            setCourseClassList(list)
        } catch (error) {
            console.log('Get course class list error : ', error)
        }
    }

    useEffect(() => {
        if (userRole === 'TUTOR') {
            return;
        } else {
            init();
        }
    }, [])





    return (

            userRole === 'TUTOR' ?
            <AccessControl></AccessControl> :
            <TablePage title='Reports'>
            <div className={style.card}>
                <div className={style.cardTitle}>Zoom Report</div>
                <div className={style.grid}>
                    <div className={`${style.gridLeft} ${style.borderRight}`}>
                        <div className={style.gridInner}>
                            <FormControl fullWidth sx={{ m: 2 }}>
                                <InputLabel id="subject-select-label">1. Choose Master Course</InputLabel>
                                <Select
                                    labelId="subject-select-label"
                                    displayEmpty
                                    value={selectedCourse}
                                    label="Choose Master Course"
                                    onChange={handleCourseChange}
                                // MenuProps={MenuProps}
                                >
                                    {courseGeneralList ?
                                        courseGeneralList.map((course) => (
                                            <MenuItem key={course.courseId} value={course.courseId}>
                                                {course.name}
                                            </MenuItem>
                                        )) : null
                                    }
                                </Select>
                            </FormControl>

                            {courseIntakeList &&
                                <FormControl fullWidth sx={{ m: 2 }}>
                                    <InputLabel id="subject-select-label">2. Choose Intake</InputLabel>
                                    <Select
                                        labelId="subject-select-label"
                                        displayEmpty
                                        value={selectedIntake}
                                        label="Choose Master Course"
                                        onChange={handleIntakeChange}
                                    >
                                        {courseIntakeList ?
                                            courseIntakeList.map((intake) => (
                                                <MenuItem key={intake.intake} value={intake.intake}>
                                                    Intake - {intake.intake} | {moment(intake.startDate).format('D MMM YYYY')} - {moment(intake.endDate).format('D MMM YYYY')}
                                                </MenuItem>
                                            )) : null
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                    <div className={style.gridRight}>
                        {
                            courseClassList && (
                                <div className={style.tableHeader}>
                                    <div className={style.tableHeaderItem}>Zoom Class</div>
                                    <div className={style.tableHeaderAction}>Details</div>
                                    <div className={style.tableHeaderAction}>Participant</div>
                                    <div className={style.tableHeaderAction}>Polls</div>
                                </div>
                            )
                        }

                        {
                            courseClassList && (
                                courseClassList.map((classItem) => {
                                    return (
                                        <div className={style.tableRow}>
                                            <div className={style.tableRowItem}>{classItem.zoomId ? 'Zoom' : 'F2F'} {classItem.name}</div>
                                            <div className={style.tableRowAction}>
                                                <Button variant="text" onClick={()=>onGetZoomReport(classItem.zoomId)}>Download</Button>
                                            </div>
                                            <div className={style.tableRowAction}>
                                                <Button variant="text" onClick={()=>onGetZoomParticipant(classItem.zoomId)}>Download</Button>
                                            </div>
                                            <div className={style.tableRowAction}>
                                                <Button variant="text" onClick={()=>onGetZoomPollsReport(classItem.zoomId)}>Download</Button>
                                            </div>
                                        </div>
                                    )
                                }))
                        }
                    </div>
                </div>
            </div>


        </TablePage>

    )
}

export default Report
