import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AdminWrapper } from './utils/context/adminContext'
import { NavbarWrapper } from './utils/context/navbarContext'
import { AxiosWrapper } from './utils/context/axiosContext'
import { CustomThemeWrapper } from './utils/context/customThemeContext'
import { Provider } from 'react-redux'
import { makeStore } from './redux/store'
ReactDOM.render(
    <React.StrictMode>
        <Provider store={makeStore()}>
        <BrowserRouter>
            <AxiosWrapper>
                <CustomThemeWrapper>
                    <AdminWrapper>
                        <NavbarWrapper>
                            <App />
                        </NavbarWrapper>
                    </AdminWrapper>
                </CustomThemeWrapper>
            </AxiosWrapper>
        </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
