import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import Box from "@mui/material/Box";
import Enrollment from "./Enrollment";

const EnrollmentIndex = () => {
    const navigate = useNavigate();
    const { courseId, intake, quizId } = useParams();
    const { data } = useGetGeneralCourseQuery({ courseId });
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb onClick={() => navigate("/intake")} label="Intake" icon={<Icon fontSize={"small"}>playlist_add</Icon>}/>
                    <StyledBreadcrumb onClick={() => navigate(`/intake/${courseId}/${intake}`)}
                                      label={data?.data ? `${data?.data?.prefixKey}-${intake}` : '...'}/>
                    <StyledBreadcrumb label="Enrollment"/>
                </Breadcrumbs>
            </Box>
            <Enrollment />
        </Box>
    )
}

export default EnrollmentIndex;
