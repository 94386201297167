import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const tutorlApi = createApi({
    baseQuery,
    reducerPath: "tutorlApi",
    endpoints: builder => ({
        getTutors: builder.mutation({
            query: (body) => ({
                url: "/tutor/getList",
                method: "POST",
                body: {},
            }),
        }),
        getTutor: builder.query({
            query: (avatar) => ({
                url: `/tutor/getList?avatar=${avatar ? `1` : "0"}`,
            })
        }),
        addCourseTutor: builder.mutation({
            query: (body) => ({
                url: "/course/assignIntakeTutor",
                method: "POST",
                body,
            }),
        }),
        deleteCourseTutor: builder.mutation({
            query: (body) => ({
                url: "/course/assignIntakeTutor",
                method: "DELETE",
                body,
            }),
        }),
        updateTutorProfile: builder.mutation({
            query: (body) => ({
                url: "/tutor/update",
                method: "POST",
                body,
            }),
        }),
        updateTutorAvatar: builder.mutation({
            query: ({ tutorId, file }) => {
                const formData = new FormData();
                formData.append('tutorId', tutorId );
                formData.append('file', file);

                return {
                    url: '/tutor/updateThumbnail',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
    }),
});

export const {
    useLazyGetTutorQuery,
    useGetTutorsMutation,
    useAddCourseTutorMutation,
    useDeleteCourseTutorMutation,
    useUpdateTutorProfileMutation,
    useUpdateTutorAvatarMutation,
} = tutorlApi;
