import React, { useState, useEffect, useContext, useRef } from 'react';
import Navbar from './pages/Navbar/Navbar';
import {Routes, Route, useLocation, Navigate, useNavigate} from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Enrollment from './pages/Enrollment/Enrollment';
import Assignment from './pages/Assignment/Assignment';
import Admin from './pages/Admin/Admin';
import Tutor from './pages/Tutor/Tutor';
import Report from './pages/Report/Report';
import Login from './pages/Login/Login';
import CreateCourse from './pages/Course/MasterCourse/CreateCourse/CreateCourse';
import EditCourse from './pages/Course/MasterCourse/EditCourse/EditCourse';
import EditIntake from './pages/Course/Intake/EditIntake/EditIntake';
import EditMaterial from './pages/Course/MasterCourse/EditMaterial/EditMaterial';
import Testing from './pages/Testing/Testing';
import { AdminContext } from './utils/context/adminContext';
import { NavbarContext } from './utils/context/navbarContext';
import { CustomThemeContext } from './utils/context/customThemeContext';
import { LOCAL_STORAGE_ADMIN } from './utils/constants';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import AssignmentList from './pages/Assignment/AssignmentList';
import Quiz from './pages/Quiz/Quiz';
import QuizList from './pages/Quiz/QuizList';
import PollInventory from './pages/PollInventory';
import style from './App.module.scss';
import QuizPage from './pages/Course/MasterCourse/EditMaterial/Quiz/Quiz';
import DocumentPage from "./pages/Document";
import { LicenseInfo } from '@mui/x-license-pro';
import IntakeInitPage from "./pages/Course/Intake/IntakeList";
import IntakeDetailPage from "./pages/Course/Intake/IntakeDetail";
import Topbar from "./pages/Navbar/Topbar";
import { setUserProfile } from "./redux/slices/authSlice";
import {useDispatch, useSelector} from "react-redux";
import ScheduleAssistantPage from "./pages/ScheduleAssistant";
import InternalNotificationPage from "./pages/InternalNotification";
import SettingPage from "./pages/Setting";
import CheckinPage from "./pages/Timesheet";
import PollInventoryDetail from "./pages/PollInventory/PollInventoryDetail";
import PollDetail from "./pages/PollInventory/PollDetail";
import EditPolls from "./pages/PollInventory/EditPolls";
import ClassPolls from "./pages/Course/Intake/Polls/ClassPolls";
import EditMeetingPolls from "./pages/Course/Intake/Polls/EditMeetingPolls";
import { jwtDecode } from "jwt-decode";
import ParticipationList from "./pages/Course/Intake/Participation/ParticipationList";
import BreakoutRoomPage from "./pages/Course/Intake/BreakoutRoom";
import CourseInitPage from "./pages/Course/MasterCourse/CourseList";
import StudentPage from "./pages/Course/Intake/StudentPage";
import Box from "@mui/material/Box";
import QuizInitPage from "./pages/Course/Intake/Quiz";
import EditCoursePage from "./pages/Course/MasterCourse/EditCourse";
import EnrollmentIndex from "./pages/Enrollment";
import EditMaterialPage from "./pages/Course/MasterCourse/EditMaterial";
import TutorInitPage from "./pages/Tutor";
import EditTutorPage from "./pages/Tutor/EditTutor";
import AdminInitPage from "./pages/AddAdmin";
import EditAdminPage from "./pages/AddAdmin/EditAdmin";
import MobileOffIcon from '@mui/icons-material/MobileOff';
import {Button, Container, Typography} from "@mui/material";

LicenseInfo.setLicenseKey("63cdcff003c86a961f1b47b5703dd5e0Tz0wLEU9MjUzNDA0ODY0MDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==");

const useMobileDetection = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust the width for your mobile breakpoint
        };

        handleResize(); // Check initially
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { admin, setAdmin } = useContext(AdminContext);
    const { showNavbar, setShowNavbar } = useContext(NavbarContext);
    const theme = useContext(CustomThemeContext);
    const [render, setRender] = useState(false);
    const [navbarContainerWidth, setNavbarContainerWidth] = useState(0);
    const topbarRef = useRef(null); // new ref for controlling the topbar

    const logout = () => {
        setAdmin(null);
        localStorage.clear();
        navigate('/login')
    }

    const isMobile = useMobileDetection(); // Use the custom hook

    useEffect(() => {
        dispatch(setUserProfile({
            email: localStorage.getItem("email"),
            role: localStorage.getItem("userRole")
        }));
        const handleScroll = () => {
            const opacity = Math.max(1 - window.scrollY / 50, 0); // Adjust 200 to control the speed of fading
            if (topbarRef.current) {
                topbarRef.current.style.opacity = opacity;
                topbarRef.current.style.visibility = opacity > 0 ? 'visible' : 'hidden';
            }
        };

        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        try {
            let token = localStorage.getItem("token");
            if (token) {
                const jwt = jwtDecode(token.replace("Bearer ", ""));
                if (new Date().getTime() / 1000 > jwt.exp) {
                    alert('Session expired. Please login again');
                    localStorage.removeItem(LOCAL_STORAGE_ADMIN);
                    localStorage.removeItem("token");
                    window.location = '/login';
                    return;
                }
            }
            let localStorageAdmin = localStorage.getItem(LOCAL_STORAGE_ADMIN);
            if (localStorageAdmin) {
                setAdmin(JSON.parse(localStorageAdmin));
            }
        } catch (error) {
            if (error instanceof SyntaxError) {
                localStorage.removeItem(LOCAL_STORAGE_ADMIN);
                setAdmin(null);
            }
        }
        setRender(true);
    }, []);

    if (!render) {
        return <></>;
    }

    if (isMobile && location.pathname !== '/login') {
        return (
            <ThemeProvider theme={theme}>
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    textAlign: 'center',
                    padding: 2,
                }}
            >
                <MobileOffIcon sx={{ fontSize: 160, color: 'gray', m: 3 }} />
                <Typography variant="h5" component="h1" gutterBottom>
                    No Mobile Version Supported
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please use a desktop or laptop to access this application.
                </Typography>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={logout}
                    sx={{ marginTop: 2 }}
                >
                    Sign out
                </Button>
            </Container>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={style.parentContainer}>
                    {admin && location.pathname !== '/login' && showNavbar && (
                        <div
                            className={style.navbarContainer}
                            ref={(ref) => setNavbarContainerWidth(ref ? ref.offsetWidth : 0)}
                        >
                            <div ref={topbarRef} style={{ transition: 'opacity 0.5s, visibility 0.5s' }}> {/* Add transition style */}
                                <Topbar />
                            </div>
                            <Navbar />
                        </div>
                    )}
                    <div
                        style={{
                            width: `calc(100% - ${navbarContainerWidth}px)`,
                            marginTop: showNavbar && location.pathname !== '/login' && 40,
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: 1450, margin: 'auto' }}>
                            <Routes>
                                {admin ? (
                                    <>
                                        <Route path="/pollInventory" element={<PollInventory />} />
                                        <Route path="/pollInventory/:courseId" element={<PollInventoryDetail />} />
                                        <Route path="/pollInventory/:courseId/:pollId" element={<EditPolls />} />
                                        <Route path="/scheduleAssistant" element={<ScheduleAssistantPage />} />
                                        <Route path="/alert" element={<InternalNotificationPage />} />
                                        <Route path="/timesheet" element={<CheckinPage />} />
                                        <Route path="/assignment" element={<Assignment />} />
                                        <Route path="/assignment/:courseId/:intake" element={<AssignmentList />} />
                                        <Route path="/quiz" element={<Quiz />} />
                                        <Route path="/quiz/:courseId/:intake/" element={<QuizList />} />
                                        <Route path="/admin" element={<AdminInitPage />} />
                                        <Route path="admin/:adminId" element={<EditAdminPage />} />
                                        <Route path="/tutor" element={<TutorInitPage />} />
                                        <Route path="tutor/:tutorId" element={<EditTutorPage />} />
                                        <Route path="/report" element={<Report />} />
                                        <Route path="/setting" element={<SettingPage />} />
                                        <Route path="/testing" element={<Testing />} />
                                        <Route path="/course" element={<CourseInitPage />} />
                                        <Route path="/course/:courseId" element={<EditCoursePage />} />
                                        <Route path="/intake" element={<IntakeInitPage />} />
                                        <Route path="/intake/:courseId/:intake" element={<IntakeDetailPage />} />
                                        <Route path="/intake/:courseId/:intake/enrollment/:studentId" element={<EnrollmentIndex />} />
                                        <Route path="/intake/:courseId/:intake/student/:studentId" element={<StudentPage />} />
                                        <Route path="/intake/:courseId/:intake/quiz/:quizId" element={<QuizInitPage />} />
                                        <Route path="/intake/:courseId/:intake/participation/:meetingId" element={<ParticipationList />} />
                                        <Route path="/intake/:courseId/:intake/polls/:meetingId" element={<ClassPolls />} />
                                        <Route path="/intake/:courseId/:intake/polls/:meetingId/:pollId" element={<EditMeetingPolls />} />
                                        <Route path="/intake/:courseId/:intake/breakoutRoom/:meetingId" element={<BreakoutRoomPage />} />
                                        <Route path="/intake/document/:courseId/:intake" element={<DocumentPage />} />
                                        <Route path="/intake/editIntake/:courseId/:intake" element={<EditIntake />} />
                                        <Route path="/intake/:courseId/:intake/editMaterial" element={<EditMaterialPage />} />
                                        <Route path="/intake/EditMaterial/:courseId/:intake/:assignmentId/quiz" element={<QuizPage />} />
                                        <Route path="/intake/EditMaterial/:courseId/:intake/:assignmentId/quiz/:quizId" element={<QuizPage />} />
                                        <Route path="/" element={<Dashboard />} />
                                        <Route path="*" element={<Navigate replace to={'/'} />} />
                                    </>
                                ) : (
                                    <>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="*" element={<Navigate replace to={'/login'} />} />
                                    </>
                                )}
                            </Routes>
                        </Box>
                    </div>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
