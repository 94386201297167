import React, { useState, useContext } from 'react'
import { adminLogin } from '../../utils/request/auth'
import logo from '../../image/logo.png'
import { TextField } from '@mui/material'
import { SUCCESS_CODE, FAIL_CODE, LOCAL_STORAGE_ADMIN, LOCAL_STORAGE_ROLE } from '../../utils/constants'
import { AdminContext } from '../../utils/context/adminContext'
import Loading from '../Loading/Loading'
import {setUserProfile} from "../../redux/slices/authSlice";

import style from './Login.module.scss'
import {useDispatch} from "react-redux";

const Login = () => {
    const dispatch = useDispatch()
    const { admin, setAdmin } = useContext(AdminContext)

    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')

    const [errorMessage, setErrorMessage] = useState({
        email: {
            error: false,
            message: '',
        },
        password: {
            error: false,
            message: '',
        },
    })

    const [loading, setLoading] = useState(false)

    const onLoginClick = (e) => {
        e.preventDefault()
        let valid = true
        let newErrorMessage = {
            email: {
                error: false,
                message: '',
            },
            password: {
                error: false,
                message: '',
            },
        }

        if (email.length === 0) {
            newErrorMessage.email.error = true
            newErrorMessage.email.message = 'Cannot be empty'
            valid = false
        }

        if (password.length === 0) {
            newErrorMessage.password.error = true
            newErrorMessage.password.message = 'Cannot be empty'
            valid = false
        }

        setErrorMessage(newErrorMessage)
        if (!valid) {
            return
        } else {
            setLoading(true)
            adminLogin(email, password, true)
                .then((result) => {
                    console.log('Admin login successfully : ', result)
                    if (result.data.data === "User is in wrong direction."){
                        alert("Use an Admin account to login.")
                        setLoading(false);
                        return
                    }
                    console.log("result", result)
                    if(result.data.id){
                        console.log('SuccesI cs')
                        let newAdmin = {
                            authorization: result.headers.authorization,
                        }
                        setAdmin(newAdmin)
                        dispatch(setUserProfile({
                            email: result.data.username,
                            role: result.data.role
                        }))
                        localStorage.setItem("token", result.headers.authorization);
                        localStorage.setItem("email", result.data.username);
                        localStorage.setItem(LOCAL_STORAGE_ADMIN, JSON.stringify(newAdmin))
                        localStorage.setItem(LOCAL_STORAGE_ROLE, result.data.role)
                    } else {
                        console.log('Fail')
                        alert('Invalid email or password')
                    }

                    setLoading(false)
                })
                .catch((error) => {
                    console.log('Admin login error : ', error)
                    setLoading(false)
                })
        }
    }

    return (
        <div className={style.parentContainer}>
            {loading && <Loading />}
            <div className={style.dialogContainer}>
                <img className={style.logo} src={logo} />
                <div className={style.dialogContentContainer}>
                    <div className={style.header}>登入帳戶</div>
                    <form className={style.form}>
                        <TextField
                            className={style.textField}
                            variant="outlined"
                            label="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            error={errorMessage.email.error}
                            helperText={errorMessage.email.message}
                            inputProps={{
                                className: style.input,
                            }}
                            InputLabelProps={{
                                className: style.input,
                            }}
                            FormHelperTextProps={{
                                className: style.input,
                            }}
                            autoFocus
                        />

                        <TextField
                            className={style.textField}
                            variant="outlined"
                            label="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            type="password"
                            error={errorMessage.password.error}
                            helperText={errorMessage.password.message}
                            inputProps={{
                                className: style.input,
                            }}
                            InputLabelProps={{
                                className: style.input,
                            }}
                            FormHelperTextProps={{
                                className: style.input,
                            }}
                        />

                        <button className={style.loginButton} type="submit" onClick={onLoginClick}>
                            登入
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
