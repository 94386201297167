import * as React from 'react';
import Box from '@mui/material/Box';
import {
    GridColumnMenu,
} from '@mui/x-data-grid-pro';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {useGetSubmissionMutation} from "../../../../api/submissionApi";
import {Modal, ModalInner} from "../../../UI/Modal";
import {Backdrop, Button, Stack, TextField} from "@mui/material";
import style from "../../../Assignment/Assignment.module.scss";
import {getFullSubmissionRecord, postMarkSubmission, postSubmitEvaluation} from "../../../../utils/request/assignment";
import {downloadDocument} from "../../../../utils/request/utility";
import moment from "moment";


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}
export default function QuizList() {

    const [fetchQuiz] = useGetSubmissionMutation();
    const [data, setData] = useState([]);
    const { courseId, intake, studentId } = useParams();

    const refresh = () => {
        fetchQuiz({
            courseId,
            intake,
            studentId,
            type: 1
        }).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "createDate",
            ...defaultColumnType,
            headerName: "Submission Date",
            renderCell: (params) => {
                return moment(params.row.createDate).format('YYYY-MM-DD HH:mm:ss');
            },
            width: 280
        },
        {
            field: "assignmentName",
            ...defaultColumnType,
            headerName: "Name",
            width: 280
        },
        {
            field: "score",
            ...defaultColumnType,
            headerName: "Score",
            width: 160
        },
        {
            field: "Status",
            ...defaultColumnType,
            headerName: "Status",
            renderCell: (params) => {
                return params.row.status === 1 ? 'Latest' : 'Depre'
            },
            width: 220
        },
        {
            field: "Progress",
            ...defaultColumnType,
            headerName: "Progress",
            renderCell: (params) => {
                return params.row.progress === 1 ? 'Marked' : '-'
            },
            width: 220
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                disableRowSelectionOnClick
                getRowId={(row) => row.submissionId}
                pageSizeOptions={[5]}
                pagination
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
