import React, { useState, useEffect } from 'react';
import {
 Fade, Chip, Typography,
} from '@mui/material'

import Box from "@mui/material/Box";
import StyledTableOne from "../../components/StyledTable/StyledTableOne";
import {useNavigate} from "react-router-dom";
import {useLazyGetTutorQuery} from "../../api/courseTutorApi";
import noneAvatar from "../Setting/images/none.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

const Tutor = () => {

    const [tutorList, setTutorList] = useState(null)
    const navigate = useNavigate();
    const [getTutor] = useLazyGetTutorQuery();

    const columns = [
        {
            field: "Avatar",
            ...defaultColumnType,
            headerName: "Tutor",
            renderCell: (params) => {
                return (
                    params.row.thumbnailPath ?
                    <Box
                        sx={{
                            width: 64,
                            height: 64,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '4px solid rgba(46, 125, 50, 0.08)', // Adjust the border color and width as needed
                        }}
                    >
                        <img
                            src={params.row.thumbnailPath || noneAvatar}
                            alt="avatar"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </Box>
                            :
                            <AccountCircleIcon sx={{fontSize: 64, opacity: 0.5 }}/>
                )
            },
            width: 150
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Name",
            renderCell: (params) => {
                return <Typography variant={"body2"} fontWeight={"bold"}>{params.row.nameEn} {params.row.nameZh || ""} </Typography>;
            },
            width: 320
        },
        {
            field: "title",
            ...defaultColumnType,
            headerName: "Title",
            flex: 1,
        },
        {
            field: "disable",
            ...defaultColumnType,
            headerName: "",
            renderCell: (params) => {
                const isDisable = params.row.disable;
                return (
                    <Chip color={isDisable ? "default" : "success"}
                          sx={{ blockSize: 20}}
                          style={{ marginLeft: 8, marginTop: 1}}
                          label={`${isDisable ? 'Inactive' : 'Active'}`}
                    />
                )
            },
            width: 150
        },
    ];

    const init =  () => {
        getTutor(true).then(({ data }) => {
            let { data: content } = data;
            setTutorList(content);
        });
    }
    useEffect(() => {
            init();
    }, [])


    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "brokerId": false
            }
        },
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
    }

    const onRowClick = ({ row }) => {
        navigate(`/tutor/${row.id}`, {
            state: { row }
        });
    };

    return (
        <Fade in={true}>
            <Box sx={{ width: '100%'}}>
                <StyledTableOne
                    autoHeight
                    rows={tutorList || []}
                    columns={columns}
                    slots={{
                        columnMenu: () => null,
                    }}
                    initialState={initState}
                    rowHeight={80}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    pageSizeOptions={[10]}
                    loading={tutorList === null}
                    pagination
                    onRowClick={onRowClick}
                    sx={{
                        border: 'none'
                    }}
                />
            </Box>
        </Fade>
    )

}

export default Tutor
