import SettingTab from "./SettingTab";
import {Breadcrumbs} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import SchoolIcon from "@mui/icons-material/School";
import Box from "@mui/material/Box";
export default function SettingPage() {
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb label="Setting" icon={<SchoolIcon fontSize="small" />} />
                </Breadcrumbs>
            </Box>
            <SettingTab />
        </Box>
    )
}
