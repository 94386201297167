import React from 'react'
import style from './Loading.module.scss'

const Loading = () => {
    return (
        <div className={style.backgroundMask}>
            <div className={`spinner-border ${style.loading}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loading
