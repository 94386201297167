import Grid from "@mui/material/Unstable_Grid2";
import noneAvator from "../../images/none.png";
import Box from "@mui/material/Box";
import {Stack, TextField, Button, Divider, Fade } from "@mui/material";
import * as React from "react";
import AuditTable from "./AuditTable";
import {useGetProfileQuery, useUpdateProfileMutation} from "../../../../api/settingApi";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function General(){
    const [engName, setEngName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [updateProfile] = useUpdateProfileMutation();
    const { data } = useGetProfileQuery();
    const update = async () => {
        setLoading(true);
        await updateProfile({
            engName,
            mobile,
        });
        setLoading(false);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };

    if(data) {
        return (
            <Fade in={true}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box minWidth={600} maxWidth={1000}>
                                <h6><strong>My Profile Setting</strong></h6>
                                <img src={noneAvator}
                                     alt="avator"
                                     width={300}
                                     height={300}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box minWidth={300} maxWidth={600}>
                                <Stack spacing={2}>
                                    <TextField
                                        label="English Name"
                                        defaultValue={data?.data.engName}
                                        variant="standard"
                                        onChange={(e) => {
                                            setEngName(e.target.value)
                                        }}
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                    />
                                    <TextField
                                        label="Email"
                                        value={data?.data.email}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                        InputLabelProps={{shrink: true}}
                                    />
                                    <TextField
                                        label="Mobile No."
                                        defaultValue={data?.data.mobile}
                                        onChange={(e) => {
                                            setMobile(e.target.value)
                                        }}
                                        variant="standard"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                    />
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button color={"success"} variant="contained" sx={{backgroundColor: '#354147'}}
                                                onClick={update} disabled={loading}>Update</Button>
                                    </div>
                                    {showAlert &&
                                        <Fade in={showAlert} {...{ timeout: 1000 }}>
                                            <Alert severity="success">
                                                Your profile has been updated successfully.
                                            </Alert>
                                        </Fade>
                                    }
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 5, mb: 5, minWidth: 800, maxWidth: 1300}}/>
                    <Box minWidth={600} maxWidth={1300}>
                        <AuditTable/>
                    </Box>
                </div>
            </Fade>

        )
    } else {
        //TODO: add loading
        return (
            <Box sx={{ width: 300 }}>
            </Box>
        )
    }
}
