import PollInventoryList from "./PollInventoryList";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import Box from "@mui/material/Box";
export default function Index() {
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Poll Inventory"
                        icon={<Icon fontSize={"small"}>inventory</Icon>}
                    />
                </Breadcrumbs>
            </Box>
            <PollInventoryList />
        </Box>
    )
}
