import { mainRequest } from "./request";

export const getAttendanceByClass = (courseId) =>
    mainRequest.post('/attendance/course/getList', {
        courseId,
    })


export const createAttendance = (classId, courseId, intake, studentIds) =>
mainRequest.post('/attendance/create', {
    classId,
    courseId,
    intake,
    studentIds
})

export const getAttendanceByIntake = (courseId, intake) =>
mainRequest.post('/attendance/course/getList', {
    courseId,
    intake
})

export const getAttendanceByStudent = (courseId, intake, studentId) =>
mainRequest.post('/quiz/attendance/student/getListByCourse', {
    courseId,
    intake,
    studentId
})

/// TODO : Update attendance