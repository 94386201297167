import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Card, Typography, Button, Stack, Chip, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import {
    useGetIntakeStudentListMutation,
    useLazyGetMeetingQuery,
    useUpdateMeetingMutation
} from "../../../../api/courseIntakeApi";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Constants
const ItemTypes = {
    STUDENT: 'student'
};

// Student Component
const Student = ({ id, name, moveStudent, showDelete }) => {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.STUDENT,
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <Chip
            ref={drag}
            label={name}
            onDelete={showDelete ? () => moveStudent(id, null) : undefined}
            sx={{
                cursor: 'move',
                margin: '5px',
                opacity: isDragging ? 0.5 : 1,
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                },
            }}
            color="success"
            variant="outlined"
        />
    );
};

// Room Component
const Room = ({ id, students, moveStudent, onDeleteRoom }) => {
    const [, drop] = useDrop({
        accept: ItemTypes.STUDENT,
        drop: (item, monitor) => {
            if (!monitor.didDrop()) {
                moveStudent(item.id, id);
            }
        },
    });

    return (
        <Box
            ref={drop}
            sx={{
                padding: '20px',
                margin: '10px 0', // Adjusted for single-line layout
                width: '100%', // Adjusted for single-line layout
                height: '200px',
                overflowY: 'auto',
                backgroundColor: '#f2f6fc', // Changed background color
                borderRadius: '20px',
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" sx={{ textAlign: 'center' }}>Room {id}</Typography>
                <IconButton onClick={() => onDeleteRoom(id)} size="small" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ marginTop: '10px', flexWrap: 'wrap' }}>
                {students.length > 0 ? (
                    students.map((student) => (
                        <Student key={student.id} id={student.id} name={student.name} moveStudent={moveStudent} showDelete={true} />
                    ))
                ) : (
                    <Typography variant="body2">No students</Typography>
                )}
            </Stack>
        </Box>
    );
};

// Sidebar Component for unassigned students
const Sidebar = ({ students, moveStudent }) => {
    return (
        <Stack sx={{
            width: '270px',
            padding: '10px',
            backgroundColor: '#ffffff',
            height: '95%',
            overflowY: 'auto',
            borderRadius: '20px',
        }}>
            {students.length > 0 ? (
                students.map((student) => (
                    <Student key={student.id} id={student.id} name={student.name} moveStudent={moveStudent} />
                ))
            ) : (
                <Typography variant="body2" sx={{ textAlign: 'center', mt: 1}}>No students</Typography>
            )}
        </Stack>
    );
};

// Main App Component
const BreakoutRoom = ({ courseId, intake }) => {
    const dispatch = useDispatch();
    const [fetchMeeting] = useLazyGetMeetingQuery();
    const [rooms , setRooms] = useState([]);
    const [students, setStudents] = useState([]);
    const [fetchIntakeStudentList] = useGetIntakeStudentListMutation();
    const [updateMeeting] = useUpdateMeetingMutation();
    const { intakeTab } = useSelector((state) => state.intake);
    const { state: { row: { zoomId: meetingId } }} = intakeTab;
    const [init, setInit] = useState(false);

    useEffect(() => {
        const getStudent = fetchIntakeStudentList({
            courseId,
            intake
        });

        const getMeeting = fetchMeeting(Number(meetingId));
        Promise.all([getStudent, getMeeting]).then(([{ data: studentData }, { data: meetingData }]) => {
            console.log("studentData", studentData.data)
            setStudents(studentData.data.map((item) => ({
                id: item.email,
                name: item.name

            })));
            setRooms(meetingData?.settings?.breakout_room?.rooms?.map((item, index) => {
                return {
                    id: index + 1,
                    studentIds: item.participants.map((email) => {
                        return studentData.data.find((student) => student.email === email).email
                    })
                }

            }) || []);
            setInit(true);
        });

    }, []);

    useEffect(() => {
        if(init) {
            updateMeetingWithCurrentRooms();
        }
    }, [rooms]);

    const updateMeetingWithCurrentRooms = () => {
        updateMeeting({
            id: meetingId,
            body: rooms.map((room, index) => ({
                name: "Room " + (index + 1),
                participants: room.studentIds,
            }))
        })
            .unwrap()
            .then((updatedMeeting) => {
                console.log("updatedMeeting", updatedMeeting)
                    alert("Fail to update meeting. Please try again later.");
            })
    };

    const moveStudent = (studentId, roomId) => {
        // If roomId is null, the student is unassigned from any room
        let newRooms = rooms.map(room => ({
            ...room,
            studentIds: room.studentIds.filter(id => id !== studentId)
        }));

        if (roomId != null) {
            newRooms = newRooms.map(room => {
                if (room.id === roomId) {
                    return { ...room, studentIds: [...room.studentIds, studentId] };
                }
                return room;
            });
        }
        setRooms(newRooms);
    };

    const autoAssignStudents = () => {
        const unassigned = unassignedStudents.slice(); // Copy the unassigned students
        let newRooms = rooms.slice(); // Copy the current rooms

        // Distribute the unassigned students evenly across the rooms
        while (unassigned.length > 0) {
            newRooms.forEach(room => {
                if (unassigned.length > 0) {
                    const student = unassigned.shift();
                    room.studentIds.push(student.id);
                }
            });
        }

        // Remove any excess rooms that have no students after auto-assigning
        newRooms = newRooms.filter(room => room.studentIds.length > 0);

        // Re-index the rooms
        newRooms = newRooms.map((room, index) => ({ ...room, id: index + 1 }));

        setRooms(newRooms);
    };

    const createRoom = () => {
        if (unassignedStudents.length > rooms.length) {
            const newRoomId = rooms.length > 0 ? rooms[rooms.length - 1].id + 1 : 1;
            setRooms([...rooms, { id: newRoomId, studentIds: [] }]);
        }
    };


    const deleteRoom = (roomId) => {
        const newRooms = rooms.filter(room => room.id !== roomId)
            .map((room, index) => ({ ...room, id: index + 1 })); // Reorder room IDs
        setRooms(newRooms);
    };

    const unassignedStudents = students.filter(
        (student) => !rooms.some(room => room.studentIds.includes(student.id))
    );

    return (
        <Box sx={{ p: 6, borderRadius: 5, backgroundColor: 'white'}}>
            <DndProvider backend={HTML5Backend}>
                <Stack mb={2} direction="row" alignContent={"space-between"} justifyContent={"space-between"}>
                    <IconButton sx={{m: -1, mb: 2}} onClick={() => dispatch(setIntakeTab(intakeTab.previousTab))}>
                        <ArrowBackIosNewIcon/>
                    </IconButton>
                    <Stack direction="row" spacing={1}>
                    <Button color={"inherit"} startIcon={<DataUsageIcon />} onClick={autoAssignStudents} disabled={rooms.length ===0}>
                        AUTO ASSIGN
                    </Button>
                    <Button color={"inherit"} startIcon={<AddCircleOutlineIcon />} onClick={createRoom}>
                        NEW ROOM
                    </Button>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                    <Sidebar students={unassignedStudents} moveStudent={moveStudent} showDelete={false} />
                    <Stack direction="column" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                        <Stack direction="column" spacing={3} sx={{ width: '100%' }}> {/* Adjusted to single-column layout */}
                            {rooms.map((room) => (
                                <Room
                                    key={room.id}
                                    id={room.id}
                                    students={students.filter(student => room.studentIds.includes(student.id))}
                                    moveStudent={moveStudent}
                                    onDeleteRoom={deleteRoom}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </DndProvider>
        </Box>
    );
};

export default BreakoutRoom;
