import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";


import * as React from 'react';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import {useLazyGetPollInventoryDetailQuery} from "../../../../api/courseGeneralApi";
import {useNavigate, useParams} from 'react-router-dom';
import {CustomColumnMenu, defaultColumnType} from "../../../PollInventory/PollInventoryList";
import {formatDistanceToNow} from "date-fns";
import FormControl from "@mui/material/FormControl";
import {styled} from "@mui/system";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useSavePollMutation} from "../../../../api/courseIntakeApi";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";


export function PollInventoryList({ courseId, row, handleToggle }) {

    const param = useParams();
    const [fetchPollInventoryDetail] = useLazyGetPollInventoryDetailQuery();
    const [data, setData] = useState(null);
    const [savePoll] = useSavePollMutation();
    const refresh = () => {
        fetchPollInventoryDetail({courseId}).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    };

    useEffect(() => {
        refresh();
    }, []);


    const handleAdd = (obj) => {
        savePoll({ id: row.zoomId, body: obj }).then(() => {
            handleToggle()
        })
    }

    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "title",
            ...defaultColumnType,
            headerName: "Poll Name",
            flex: 2
        },
        {
            field: "questions",
            ...defaultColumnType,
            headerName: "Questions",
            renderCell: (params) => {
                return JSON.parse(params.row.questions).length;
            },
            flex: 0.5
        },
        {
            field: "action",
            ...defaultColumnType,
            headerName: "",
            renderCell: (params) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button color={"success"} variant={"outlined"} onClick={()=> handleAdd(params.row)}>
                            Add
                        </Button>
                    </Box>
                );
            },
            flex: 0.8
        }
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 5 } },
    }


    return (
        <Box sx={{width: '100%', position: 'relative'}}>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                headerHeight={0}
                components={{
                    Header: () => null,// to remove header completely
                }}
                disableRowSelectionOnClick
                pageSizeOptions={[5]}
                pagination
                loading={data === null}
                sx={{
                    border: 'none',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer', // Change mouse cursor to pointer
                    },
                }}
            />
        </Box>
    );
}


const ImportPoll = ({handleToggle, toggle, row, courseId}) => {
    return (
        <Dialog
            open={toggle}
            //onClose={handleToggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleToggle}
            maxWidth="md"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description" width={700} >
                <PollInventoryList courseId={courseId} row={row} handleToggle={handleToggle}/>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default ImportPoll;
