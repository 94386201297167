import { createContext, useState } from 'react'
import { LOCAL_STORAGE_ADMIN } from '../constants'

export const AdminContext = createContext()

export const AdminWrapper = ({ children }) => {
    const [admin, setAdmin] = useState(null)

    return <AdminContext.Provider value={{ admin, setAdmin }}>{children}</AdminContext.Provider>
}
