import React, {useEffect} from 'react';
import {
    Box,
    Button, CircularProgress, Divider,
    FormControlLabel, FormHelperText,
    Grid, IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm, useWatch} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteCourseMutation} from "../../../api/removeApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useUpdateTutorAvatarMutation, useUpdateTutorProfileMutation} from "../../../api/courseTutorApi";
import {
    useCreateUserMutation,
    usePasswordResetMutation,
    useRemoveUserMutation,
    useUpdateAdminProfileMutation, useUpdateAdminRoleMutation
} from "../../../api/settingApi";
import {IOSSwitch} from "../../../components/Switcher";
import noneAvatar from "../../Setting/images/none.png";
import LoadingButton from "@mui/lab/LoadingButton";



export default function EditAdmin(props) {
    const navigate = useNavigate();
    const { adminId } = useParams()
    const location = useLocation();
    const isCreate = adminId === "create";
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [deleteCourse] = useDeleteCourseMutation();
    const [img, setImg] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const row = location.state?.row;
    const [createTutor] = useCreateUserMutation();
    const [updateAdminProfile] = useUpdateAdminProfileMutation();
    const [updateAdminRole] = useUpdateAdminRoleMutation();
    const [updateAvatar] = useUpdateTutorAvatarMutation();
    const [updatePassword] = usePasswordResetMutation();
    const [removeAdmin] = useRemoveUserMutation();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()


    useEffect(() => {
        if(!isCreate) {
            if(row) {
                const { adminId: adminProfileId, roleId, nameEn, username, mobile, enabled, thumbnailPath } = row;
                setValue('adminProfileId', adminProfileId);
                setValue('nameEn', nameEn)
                setValue('username', username)
                setValue('mobile', mobile)
                setValue('enabled', enabled)
                setValue('roleId', roleId)
                setImg(thumbnailPath)
            }
        }
    }, [adminId]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImg(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const roleType = [{
        value: 4,
        label: 'ADMIN'
    }, {
        value: 5,
        label: 'COURSECOOR'
    }, {
        value: 6,
        label: 'DIRECTOR'
    }]
    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const { password, adminProfileId, enabled, username: email, nameEn: engName, mobile, roleId } = data;
        let userId = null;
        const role = roleType.find(role => role.value === roleId).label;
        if(isCreate) {
            const { data : { data : { id } }} = await createTutor({ username: email, password, roleType: role});
            if(id){
                await updateAdminProfile({ id, email, engName, mobile });
            } else {
                alert("Fail to create new admin.");
            }
        }else {
            userId = adminId;
            await updateAdminProfile({ id: adminProfileId, email, engName, mobile });
            await updatePassword({ username: email , password, enabled });
            await updateAdminRole({ userId: adminId, roleName: role});
        }

        if (selectedFile) {
            await updateAvatar({ adminId: userId, file: selectedFile });
        }
        navigate("/admin")
    }

    return (
        <Fade in={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{p: 5, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                    {isRemoving ?
                        <CircularProgress size={18} /> :
                        !isCreate &&
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation()
                                if (window.confirm("Are you sure to remove? It will delete all the related data.")) {
                                    setIsRemoving(true);
                                    removeAdmin({ username: row?.username, roleType: row?.roleName }).then(() => {
                                        setIsRemoving(false);
                                        navigate("/admin")
                                    });
                                }
                            }}
                            size="small"

                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                    </Stack>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={4}>
                            <Box>
                                {/*<input*/}
                                {/*    type="file"*/}
                                {/*    accept="image/*"*/}
                                {/*    style={{display: 'none'}}*/}
                                {/*    id="avatar-upload"*/}
                                {/*    onChange={handleFileChange}*/}
                                {/*/>*/}
                                <label htmlFor="avatar-upload">
                                    <Button
                                        component="span"

                                        sx={{
                                            fontSize: 300,
                                            opacity: img ? 1 : 0.5,
                                            borderRadius: '50%',
                                            transition: 'all 0.3s ease',
                                            color: 'black',
                                            '&:hover': {
                                                opacity: 0.6
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 300,
                                                height: 300,
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '4px solid rgba(46, 125, 50, 0.08)', // Adjust the border color and width as needed
                                            }}
                                        >
                                            {img ?
                                                <img
                                                    src={img}
                                                    alt="avatar"
                                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                />

                                                :
                                                <AccountCircleIcon color={"inherit"} sx={{fontSize: 'inherit'}}/>
                                            }
                                        </Box>
                                    </Button>
                                </label>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                        <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='nameEn'>
                                                Name *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='nameEn'
                                                type='text'
                                                placeholder='Cha Tai Man'
                                                {...register('nameEn', {required: 'This is required field.'})}
                                            />
                                            {errors.nameEn &&
                                                <FormHelperText error>{errors.nameEn.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-6'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'>
                                                Role *
                                            </label>
                                            <Controller
                                                name='roleId'
                                                control={control}
                                                rules={{required: 'This is required field.'}}
                                                render={({field, field: {onChange, value}}) => (
                                                    <Select
                                                        labelId='roleName-selection'
                                                        id='roleName-selection'
                                                        fullWidth
                                                        onChange={(e) => {
                                                            onChange(e);
                                                        }}
                                                        value={Number(value)}
                                                        sx={{height: 40}}
                                                    >
                                                        {roleType.map((role) => (
                                                            <MenuItem key={role.value} value={role.value}>
                                                                {role.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                            {errors.roleId &&
                                                <FormHelperText error>{errors.roleId.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='username'>
                                                Email *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='username'
                                                type='email'
                                                placeholder='example@email.com'
                                                disabled={!isCreate}
                                                {...register('username', {required: 'This is required field.'})}
                                            />
                                            {errors.username &&
                                                <FormHelperText error>{errors.username.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='mobile'>
                                                Mobile *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='mobile'
                                                type='text'
                                                placeholder='50101010'
                                                {...register('mobile', {required: 'This is required field.'})}
                                            />
                                            {errors.mobile &&
                                                <FormHelperText error>{errors.mobile.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='password'>
                                                Password {isCreate ? "*" : "(Leave blank if not changing)"}
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='nameEn'
                                                type='password'
                                                placeholder='********'
                                                {...register('password', isCreate ? {required: 'This is required field.'} : {})}
                                            />
                                            {errors.password &&
                                                <FormHelperText error>{errors.password.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <div className='mb-4'>
                                            <Controller
                                                name='enabled'
                                                control={control}
                                                render={({ field, field: { onChange, value} }) => (
                                                    <FormControlLabel
                                                        control={<IOSSwitch sx={{ m: 3, ml: 15 }} onChange={() => onChange(!value)} checked={isCreate || value} disabled={isCreate}/>}
                                                        label={isCreate || value ? "Active" : "Inactive"}
                                                    />
                                            )}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>



                </Box>
                <Box sx={{p: 5, pt: 3, pb: 3, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
                    <Stack direction='row' spacing={2} justifyContent="flex-end">
                        <Button variant="outlined" color="primary" sx={{mt: 3}} onClick={() => navigate("/admin")}>
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" color="primary" sx={{mt: 3}} loading={isSubmitting}>
                            {isCreate ? "Create" : "Save Change"}
                        </LoadingButton>
                    </Stack>
                </Box>
            </form>
        </Fade>
    );
}
