import style from './TablePage.module.scss';
import { Button } from '@mui/material';

const TablePage = (props) => {

    return (
        <div className={style.parentContainer}>
            <div className={style.allRecordContainer}>
                <div className={style.header}>
                    {props.title}
                    {props.onClickEvent ? 
                    <Button variant="contained" color="primary" onClick={props.onClickEvent}>{props.buttonText}</Button> : 
                    null
                }
                </div>
                {props.children}
            </div>
        </div>

    )
}

export default TablePage