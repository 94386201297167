import PollDetail from "./PollDetail";
import {Breadcrumbs, Icon} from "@mui/material";
import StyledBreadcrumb from "../../components/StyledBreadcrumb";
import HomeIcon from "@mui/icons-material/Home";
import {useNavigate, useParams} from "react-router-dom";
import {useGetGeneralCourseQuery} from "../../api/courseIntakeApi";
import Box from "@mui/material/Box";

const EditPolls = () => {
    const navigate = useNavigate();
    const { courseId, pollId } = useParams();
    const { data } = useGetGeneralCourseQuery({ courseId });
    return (
        <Box sx={{ width: '100%', padding: 5}}>
            <Box sx={{ mb: 2, p: 2, borderRadius: 5, backgroundColor: 'white'}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Poll Inventory"
                        onClick={() => navigate("/pollInventory")}
                        icon={<Icon fontSize={"small"}>inventory</Icon>}
                    />
                    <StyledBreadcrumb onClick={() => navigate(`/pollInventory/${courseId}`)} label={data?.data ? `${data?.data?.prefixKey}` : '...'} />
                    <StyledBreadcrumb label={pollId === 'Create' ? 'Create Poll': 'Edit Poll'} />
                </Breadcrumbs>
            </Box>
        <PollDetail />
        </Box>
    )
}

export default EditPolls;
