import React, {useState, useEffect, useContext, useCallback} from 'react'
import courseIcon from '../../../../image/course.svg'
import {
    Button,
    Dialog,
    TextField,
    FormGroup,
    FormControl,
    FormControlLabel,
    Stack,
    InputLabel,
    IconButton,
    Select,
    MenuItem, Checkbox, Box, Typography, Paper, Icon, Chip, CircularProgress, Grid,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import pencilIcon from '../../../../image/pencil.svg'
import backArrow from '../../../../image/back_arrow.svg'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
    addClass,
    addMaterial,
    addAssignment,
    deleteWeek,
    editClass,
    editAssignment,
    editMaterial,
    uploadAssignment,
    getAssignmentDoc,
    deleteAssignment,
    deleteClass,
    deleteMaterial,
} from '../../../../utils/request/course'
import { NavbarContext } from '../../../../utils/context/navbarContext'
import { useParams, useNavigate } from 'react-router-dom'
import Loading from '../../../Loading/Loading'
import { downloadDocumentToLocal } from '../../../../utils/request/utility'
import style from './EditMaterial.module.scss'
import { getQuizByAssignmentId } from '../../../../utils/request/quiz'
import { set } from 'lodash'
import {useLazyGetAssociateClassesQuery} from "../../../../api/courseIntakeApi";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import {GridColumnMenu} from "@mui/x-data-grid-pro";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuizIcon from '@mui/icons-material/Quiz';
import PollIcon from '@mui/icons-material/Poll';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MaterialList from "./MaterialList";
import {useDispatch, useSelector} from "react-redux";
import AssignmentEdit from "./AssignmentEdit";
import QuizEdit from "./QuizEdit";
import ReadingEdit from "./ReadingEdit";
import VideotEdit from "./VideoEdit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import {useGetWeeksMutation} from "../../../../api/courseClassApi";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SchoolIcon from '@mui/icons-material/School';
import ClassEdit from "./ClassEdit";
import ExamEdit from "./ExamEdit";
import ClassPolls from "../../Intake/Polls/ClassPolls";
import PollDetail from "../../../PollInventory/PollDetail";
import BreakoutRoom from "../../Intake/BreakoutRoom/BreakoutRoom";
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};


function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}>
        </GridColumnMenu>
    );
}

const EditMaterial = () => {
    const [data, setData] = useState([])
    const [material, setMaterial] = useState([])
    const [getWeeks] = useGetWeeksMutation();


    const [loading, setLoading] = useState(false)
    const { courseId, intake } = useParams()
    const { intakeTab } = useSelector((state) => state.intake);
    const dispatch = useDispatch();

    const handleMaterialFilter = (classId, type) => {
        const name = data.find((item) => item.classId === classId).name;
        dispatch(setIntakeTab({
            primary: "Class Detail",
            route: "Manage Classes",
            subRoute: 'All Materials',
            state: {
                name,
                classId: classId,
                type: type
            }
        }));
    }

    const handleRoute = (row, route) => {
        const defaultState = {
            primary: 'Class Detail',
            secondary: 'Class and Exam',
            route: 'Manage Classes',
            state: { row }
        }
        dispatch(setIntakeTab({
            ...defaultState,
            subRoute: route
        }));
    }


    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "classDetail",
            ...defaultColumnType,
            headerName: "Class Information",
            renderCell: (params) => {
                const ClassChip = () => {
                    let color;
                    let label = params.row.type;
                    switch (params.row.type) {
                        case 'ONLINE':
                            color = 'success';
                            break
                        case 'OFFLINE':
                            color = 'default';
                            break;
                        case 'EXAM':
                            color = 'secondary';
                            break;
                        case 'ONLINE_EXAM':
                            color = 'primary';
                            label = 'EXAM';
                            break;
                        default:
                            color = "secondary";
                    }
                    return (
                        <Chip label={label} color={color} variant="outlined" sx={{blockSize: '90%', mr: 1}}/>

                    )
                }

                return (
                    <Stack spacing={1}>
                        <span style={{fontSize: 15, fontWeight: 'Bold'}}>{params.row.name}</span>
                        <span>
                            <ClassChip sx={{blockSize: 5}}/>
                            {moment(params.row.startDate).format("YYYY-MM-DD hh:mm")} - {moment(params.row.endDate).format("hh:mm")}
                        </span>
                        <span> {params.row.venue || (params.row.type.includes('ONLINE') ? "Zoom Meeting: " + params.row.zoomId : "N/A")}</span>
                    </Stack>
                )
            },
            flex: 2,
        },
        {
            field: "action",
            ...defaultColumnType,
            headerName: "Action",
            renderCell: (params) => {
                const { classId } = params.row;
                const match = material.filter((item) => params.row.classId === item.associateClassId);
                const assignment = match.filter((item) => item.type === 'ASSIGNMENT');
                const quiz = match.filter((item) => item.type === 'QUIZ');
                const reading = match.filter((item) => item.type === 'READING');
                const video = match.filter((item) => item.type === 'VIDEO');

                const alignBtn = { justifyContent: 'flex-start', textAlign: 'left', padding: '6px 14px' };
                if(["ONLINE_EXAM", "EXAM"].includes(params.row.type)) {
                    return (
                        <Button variant="outlined" startIcon={<ReduceCapacityIcon/>} onClick={(e) => {
                            e.stopPropagation();
                            handleRoute(params.row, "BreakoutRoom")
                        }} sx={[alignBtn, {width: 140}]}  disabled={params.row.type !== 'ONLINE_EXAM'}>
                            Break Room
                        </Button>
                    )
                }
                return (
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" startIcon={<AssignmentIcon/>} onClick={(e) => {
                                e.stopPropagation();
                                handleMaterialFilter(classId, "ASSIGNMENT")
                            }} sx={[alignBtn, {width: 170}]}>
                                Assignment ({assignment.length})
                            </Button>
                            <Button variant="outlined" startIcon={<QuizIcon/>} onClick={(e) => {
                                e.stopPropagation();
                                handleMaterialFilter(classId, "QUIZ")
                            }} sx={[alignBtn, {width: 130}]}>
                                Quiz ({quiz.length})
                            </Button>
                            <Button variant="outlined" startIcon={<PollIcon/>} onClick={(e) => {
                                e.stopPropagation();
                                handleRoute(params.row, "Polls")
                            }} sx={[alignBtn, {width: 100}]}
                                    disabled={params.row.type !== 'ONLINE'}>
                                Polls
                            </Button>
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" startIcon={<MenuBookIcon/>} onClick={(e) => {
                                e.stopPropagation();
                                handleMaterialFilter(classId, "READING")
                            }} sx={[alignBtn, {width: 170}]}>
                                Reading ({reading.length})
                            </Button>
                            <Button variant="outlined" startIcon={<YouTubeIcon/>} onClick={(e) => {
                                e.stopPropagation();
                                handleMaterialFilter(classId, "VIDEO")
                            }} sx={[alignBtn, {width: 130}]}>
                                Video ({video.length})
                            </Button>
                        </Stack>
                    </Stack>
                )
            },
            width: 450,
        },
        {
            field: "next",
            ...defaultColumnType,
            headerName: "",
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '98%' }}>
                        <ArrowForwardIosIcon color={"disabled"} fontSize={"medium"} />
                    </Box>
                )
            },
            width: 20,
        },
    ];

    const intakeInfo = [
        {
            displayName: 'Class and Exam',
            backendName: 'Class and Exam',
            icon: 'school',
        },
        {
            displayName: 'All Materials',
            backendName: 'All Materials',
            icon: 'assignment',
        },
    ]

    const init = async () => {
        setLoading(true)
        try {
            let result = await getWeeks({courseId, intake})
            console.log('Get weeks successfully : ', result)
            let resultWeeks = result.data.data.weeks
            const flattenedClasses = resultWeeks?.flatMap(week => week.classes)
                .sort((a, b) => a.startDate - b.startDate);
            setData(flattenedClasses);

            const flattedAssignment = resultWeeks?.flatMap(week => week.assignments)
                .sort((a, b) => a.startDate - b.startDate);

            const flattenedMaterial = resultWeeks?.flatMap(week => week.materials)
                .sort((a, b) => a.startDate - b.startDate);
            setMaterial([...flattedAssignment, ...flattenedMaterial]);
        } catch (error) {
            console.log('Get weeks error : ', error)
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [intakeTab.subRoute])


    const userRole = localStorage.getItem('userRole');


    const InfoNavItem = React.memo(({ isActive, onClick, displayName, icon }) => {
        const itemClass = isActive ? `${style.infoNavItem} ${style.active}` : style.infoNavItem;

        return (

            <div className={itemClass} onClick={onClick}>
                <Icon fontSize={"small"} sx={{mr: 1, color: '#b5bbbe'}}>{icon}</Icon>
                {displayName}
            </div>
        );
    });

    const InfoNavbar = () => {


        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleCreate = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleNew = (subRoute) => {
            const defaultState = {
                primary: 'Class Detail',
                secondary: 'All Materials',
                route: 'Manage Classes',
                state: { row: null, materials: null, classes: null }
            }
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute,
            }));

        }

        const handleClick = useCallback(
            (backendName) => {
                dispatch(setIntakeTab({
                    primary: "Class Detail",
                    route: "Manage Classes",
                    subRoute: backendName
                }));
            },
            [setIntakeTab]
        );

        return (
            <Box sx={{p: 5, pt: 1,  mt: 3, mb: -6, borderTopLeftRadius: 20 , borderTopRightRadius: 20, backgroundColor: 'white'}}>
               <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
                <Box className={style.infoNavbar}>
                    {intakeInfo
                        .map((info, index) => (
                            <InfoNavItem
                                key={info.backendName}
                                isActive={[intakeTab.subRoute, intakeTab.secondary].includes(info.backendName)}
                                onClick={() => handleClick(info.backendName)}
                                displayName={info.displayName}
                                icon={info.icon}
                            />
                        ))}
                </Box>

                   <Box mt={5}>
                       <Button
                           id="demo-customized-button"
                           aria-controls={open ? 'demo-customized-menu' : undefined}
                           aria-haspopup="true"
                           aria-expanded={open ? 'true' : undefined}
                           variant="contained"
                           disableElevation
                           onClick={handleCreate}
                           endIcon={<KeyboardArrowDownIcon/>}
                           disabled={intakeTab.subRoute !== intakeTab.secondary}
                       >
                           New
                       </Button>
                       <StyledMenu
                           id="demo-customized-menu"
                           MenuListProps={{
                               'aria-labelledby': 'demo-customized-button',
                           }}
                           anchorEl={anchorEl}
                           open={open}
                           onClose={handleClose}
                       >
                           <MenuItem onClick={() => handleNew("Class")} disableRipple>
                               <SchoolIcon/>
                               Class
                           </MenuItem>
                           <MenuItem onClick={() => handleNew("Exam")} disableRipple>
                               <AccessTimeIcon/>
                               Exam
                           </MenuItem>
                           <Divider sx={{my: 0.5}}/>
                           <MenuItem onClick={() => handleNew("Assignment")} disableRipple>
                               <AssignmentIcon/>
                               Assignment
                           </MenuItem>
                           <MenuItem onClick={() => handleNew("Quiz")} disableRipple>
                               <QuizIcon/>
                               Quiz
                           </MenuItem>
                           <MenuItem onClick={() => handleNew("Reading")} disableRipple>
                               <MenuBookIcon/>
                               Reading
                           </MenuItem>
                           <MenuItem onClick={() => handleNew("Video")} disableRipple>
                               <YouTubeIcon/>
                               Video
                           </MenuItem>
                       </StyledMenu>
                   </Box>
               </Stack>
            </Box>
        );
    };




    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 1000 } },
    }

    const onRowClick = ({ row }) => {
        const defaultState = {
            primary: 'Class Detail',
            secondary: 'Class and Exam',
            route: 'Manage Classes',
            state: { row }
        }
        if(['ONLINE', 'OFFLINE'].includes(row.type)) {
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute: 'Class'
            }));
        }else if(['EXAM', 'ONLINE_EXAM'].includes(row.type)) {
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute: 'Exam'
            }));
        }
    }

    const renderTab = () => {
        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Class and Exam"){
            return (
                <Box sx={{ width: '100%', mt: 3 }}>
                    <StyledTableOne
                        autoHeight
                        rows={data || []}
                        columns={columns}
                        slots={{
                            columnMenu: CustomColumnMenu,
                            columnHeaders: () => null,
                        }}
                        onRowClick={onRowClick}
                        initialState={initState}
                        rowHeight={130}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.classId}
                        pageSizeOptions={[1000]}
                        disableColumnMenu
                        pagination
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        loading={loading}
                        sx={{
                            border: 'none'
                        }}
                    />
                </Box>
            )
        }
        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "All Materials"){
            return (
                <MaterialList courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Assignment"){
            return (
                <AssignmentEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Quiz"){
            return (
                <QuizEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Reading"){
            return (
                <ReadingEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Video"){
            return (
                <VideotEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Class"){
            return (
                <ClassEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Exam"){
            return (
                <ExamEdit courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "Polls") {
            return (
                <ClassPolls courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "EditPoll") {
            return (
                <PollDetail courseId={courseId} intake={intake} />
            )
        }

        if(intakeTab.route === "Manage Classes" && intakeTab.subRoute === "BreakoutRoom") {
            return (
                <BreakoutRoom courseId={courseId} intake={intake} />
            )
        }


    }
    return (
            <Box>


                <InfoNavbar />
                {renderTab()}
            </Box>
    );
}

export default EditMaterial
