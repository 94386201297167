import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery, {authQuery} from "./base";
export const settingApi = createApi({
    baseQuery: authQuery,
    reducerPath: "settingApi",
    endpoints: builder => ({
        getProfile: builder.query({
            query: (param) => ({
                url: `/user/internal/profile`,
            })
        }),
        updateProfile: builder.mutation({
            query: (body) => ({
                url: "/user/internal/profile",
                method: "POST",
                body,
            }),
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: "/user/internal/createUser",
                method: "POST",
                body,
            }),
        }),
        passwordReset: builder.mutation({
            query: (body) => ({
                url: "/user/internal/passwordReset",
                method: "POST",
                body,
            }),
        }),
        removeUser: builder.mutation({
            query: (body) => ({
                url: "/user/internal/removeUser",
                method: "POST",
                body,
            }),
        }),
        getAdminList: builder.query({
            query: (param) => ({
                url: `/user/internal/getAdminList`,
            })
        }),
        updateAdminProfile: builder.mutation({
            query: (body) => ({
                url: "/user/internal/updateAdminProfile",
                method: "POST",
                body,
            }),
        }),
        updateAdminRole: builder.mutation({
            query: (body) => ({
                url: "/user/internal/updateAdminRole",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useGetProfileQuery,
    useLazyGetAdminListQuery,
    useUpdateProfileMutation,
    useCreateUserMutation,
    usePasswordResetMutation,
    useRemoveUserMutation,
    useUpdateAdminProfileMutation,
    useUpdateAdminRoleMutation,
} = settingApi;
