import React, {useEffect} from 'react';
import {
    Box,
    Button, CircularProgress, Divider,
    FormControlLabel, FormHelperText,
    Grid, IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm, useWatch} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteCourseMutation} from "../../../../api/removeApi";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ display: value === index ? 'block' : 'none' }} // Use CSS to hide instead of not rendering
            {...other}
        >
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        </div>
    );
}

export default function EditMasterCourse(props) {
    const navigate = useNavigate();
    const { courseId } = useParams()
    const isCreate = courseId === "create";
    const [tab, setTab] = React.useState(0);
    const [fetchCourseGeneral] = useGetGeneralCourseMutation();
    const [updateGeneral] = useUpdateGeneralCourseMutation();
    const [createCourse] = useCreateCourseMutation();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [deleteCourse] = useDeleteCourseMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if(!isCreate) {
            fetchCourseGeneral({courseId}).then(({data: { data }}) => {
                const {
                    accredibleId,
                    boardId,
                    courseCode,
                    description,
                    mondaySync,
                    name,
                    nameEng,
                    postEngId,
                    postId,
                    prefixKey,
                    wordpressSync,
                } = data;
                setValue('courseKey', prefixKey)
                setValue('courseCode', courseCode)
                setValue('chineseName', name)
                setValue('englishName', nameEng)
                setValue('descriptionUrl', description)
                setValue('accredibleId', accredibleId)
                setValue('boardId', boardId)
                setValue('postIdChi', postId)
                setValue('postIdEng', postEngId)
                setValue('mondaySynced', mondaySync)
                setValue('wordPressSynced', wordpressSync)

            });

        }
    }, []);

    const onSubmit = (data) => {
        setIsSubmitting(true);
        const {
            courseKey: prefixKey,
            courseCode,
            chineseName: name,
            englishName: nameEng,
            descriptionUrl: description,
            accredibleId, boardId,
            postIdChi: postId,
            postIdEng: postEngId,
            mondaySynced: mondaySync,
            wordPressSynced: wordpressSync,
            intakeNo: intake,
            quota,
            academicLevel,
            startDate,
            endDate,
            cutOffDate,
            price,
            discountedPrice,
            earlyDiscountEnable,
            earlyDate,
            earlyDiscount,
            earlyDiscountWithCEF,
            scheduleDescriptionChi: courseTimeDesc,
            scheduleDescriptionEng: courseTimeDescEng,
            venueChi: address,
            venueEng: addressEng,
        } = data;
        if(isCreate) {
            createCourse({
                prefixKey,
                courseCode,
                name,
                nameEng,
                description,
                accredibleId,
                boardId,
                postId,
                postEngId,
                mondaySync,
                wordpressSync,
                intake,
                quota,
                academicLevel,
                startDate,
                endDate,
                cutOffDate,
                price,
                discountedPrice,
                earlyDiscountEnable,
                earlyDate,
                earlyDiscount,
                earlyDiscountWithCEF,
                courseTimeDesc,
                courseTimeDescEng,
                address,
                addressEng,
            }).then(({ data }) => {
                if(data.code === 'SYS0000') {
                    navigate("/course")
                } else {
                    alert("Failed to create. Please check the fields input.");
                }
                setIsSubmitting(false)
            });
        }else{
            updateGeneral({
                courseId,
                prefixKey,
                courseCode,
                name,
                nameEng,
                description,
                accredibleId,
                boardId,
                postId,
                postEngId,
                mondaySync,
                wordpressSync,
            }).then(({ data }) => {
                if(data.code === 'SYS0000') {
                    navigate("/course")
                } else {
                    alert("Failed to update. Please check the fields input.");
                }
                setIsSubmitting(false)
            });
        }


    }

    useWatch({
        control,
        name: 'earlyDiscountEnable',
    });

    const isEarlyDiscountEnabled = getValues("earlyDiscountEnable");
    return (
        <Fade in={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{p: 5, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
            <Stack direction={"row"} alignContent={"space-between"} justifyContent={"space-between"}>
            <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="master course tabs"
                sx={{
                    '.MuiTab-root': {
                        color: '#1f1f1f',
                        cursor: 'pointer',
                        marginRight: '2rem',
                        fontSize: '14px',
                        padding: '10px',
                        transition: 'background-color 0.5s ease',
                        borderRadius: '0.625rem',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: 'rgba(46, 125, 50, 0.05)',
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(46, 125, 50, 0.08)',
                            fontWeight: 'bolder',
                        }
                    },
                    '.MuiTabs-indicator': {
                        display: 'none',
                    }
                }}
            >
                <Tab label="Basic Information" />
                <Tab label="Integration" />
            </Tabs>

            {isRemoving ?
                <CircularProgress size={18} /> :
                 !isCreate &&
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation()
                            if (window.confirm("Are you sure to remove? It will delete all the intake related data.")) {
                                setIsRemoving(true);
                                deleteCourse({courseId}).then(() => {
                                    setIsRemoving(false);
                                    navigate(`/`)
                                });
                            }
                        }}
                        size="small"

                    >
                        <DeleteIcon />
                    </IconButton>
            }
            </Stack>
            <TabPanel value={tab} index={0}>

                <Grid container columnSpacing={3} >
                    <Grid item xs={12} md={6}>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Course Key *
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='courseKey'
                        type='text'
                        placeholder='PCPDM'
                        maxLength={5}
                        {...register('courseKey', { required: 'This is required field.' })}
                    />
                    {errors.courseKey &&
                        <FormHelperText error>{errors.courseKey.message}</FormHelperText>}
                </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Course Code
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='courseCode'
                                type='text'
                                placeholder='33K137886'
                                {...register('courseCode' )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                         Name (Chinese) *
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='chineseName'
                        type='text'
                        placeholder='數碼營銷實戰專業證書'
                        {...register('chineseName', { required: 'This is required field.' })}
                    />
                    {errors.chineseName &&
                        <FormHelperText error>{errors.chineseName.message}</FormHelperText>}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                         Name (English) *
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='englishName'
                        type='text'
                        placeholder='Professional Certificate in Practical Digital Marketing'
                        {...register('englishName', { required: 'This is required field.' })}
                    />
                    {errors.englishName &&
                        <FormHelperText error>{errors.englishName.message}</FormHelperText>}
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Description URL
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='descriptionUrl'
                        type='text'
                        placeholder='https://cef.ad-linkage.com/courses/digital-marketing/'
                        {...register('descriptionUrl' )}
                    />
                </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>

                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Accredible Template ID
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='accredibleId'
                                type='text'
                                placeholder='1040523A'
                                {...register('accredibleId' )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Controller
                    key={"mondaySynced"}
                    name={"mondaySynced"}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (

                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                            color='success'
                            sx={{
                                '& .MuiSvgIcon-root': {fontSize: 20}
                            }}
                        />
                    }
                    label={"Monday synced"}
                    sx={{mb: 1, '& .MuiTypography-root': {fontSize: 14}}}
                />
                    )}
                />

                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Board ID
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='boardId'
                                type='text'
                                placeholder='1010110001'
                                {...register('boardId' )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Controller
                    key={"wordPressSynced"}
                    name={"wordPressSynced"}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                            color='success'
                            sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                        />
                    }
                    label={"WordPress synced"}
                    sx={{mb: 1, '& .MuiTypography-root': {fontSize: 14}}}
                />
                    )}
                />
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Post ID (Chinese)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='postIdChi'
                                type='text'
                                placeholder='10000'
                                {...register('postIdChi' )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Post ID (English)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='postIdEng'
                                type='text'
                                placeholder='10001'
                                {...register('postIdEng' )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </TabPanel>

        </Box>
                {isCreate && (
            <Box sx={{p: 5, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Intake No. *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='intakeNo'
                                type='text'
                                placeholder='1'
                                {...register('intakeNo', { required: 'This is required field.' })}
                            />
                            {errors.intakeNo &&
                                <FormHelperText error>{errors.intakeNo.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Quota *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='quota'
                                type='text'
                                placeholder='50'
                                {...register('quota', { required: 'This is required field.' })}
                            />
                            {errors.quota &&
                                <FormHelperText error>{errors.quota.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-6'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>
                                QF Level *
                            </label>
                            <Controller
                                name='academicLevel'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                labelId='academicLevel-level-selection'
                                id='academicLevel-level-selection'
                                fullWidth
                                sx={{height: 40}}
                            >
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={0}>0</MenuItem>
                            </Select>
                                )}
                            />
                            {errors.academicLevel &&
                                <FormHelperText error>{errors.academicLevel.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                Start Date *
                            </label>
                            <Controller
                                name='startDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                            />
                            {errors.startDate &&
                                <FormHelperText error>{errors.startDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                End Date *
                            </label>
                            <Controller
                                name='endDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                                />
                                {errors.endDate &&
                                    <FormHelperText error>{errors.endDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='dateOfBirth'>
                                Cut-off Date *
                            </label>
                            <Controller
                                name='cutOffDate'
                                control={control}
                                rules={{ required: 'This is required field.' }}
                                render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            height: 10,
                                        }
                                    }}
                                    {...field}
                                />
                            </LocalizationProvider>
                                )}
                            />
                            {errors.cutOffDate &&
                                <FormHelperText error>{errors.cutOffDate.message}</FormHelperText>}
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Price (HKD) *
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='price'
                                type='text'
                                placeholder='13800'
                                {...register('price', { required: 'This is required field.' })}
                            />
                            {errors.price &&
                                <FormHelperText error>{errors.price.message}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                Discounted Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='discountedPrice'
                                type='text'
                                placeholder='3200'
                                {...register('discountedPrice')}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Controller
                    key={"earlyDiscountEnable"}
                    name={"earlyDiscountEnable"}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color='success'
                                    sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                                />
                            }
                            label={"Enable Early Discount"}
                            sx={{mb: 1, '& .MuiTypography-root': {fontSize: 14}}}
                        />
                    )}
                />
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>

                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDate'>
                                Cut-off Date
                            </label>
                            <Controller
                                name='earlyDate'
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disabled={!isEarlyDiscountEnabled}
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-input': {
                                                    height: 10,
                                                }
                                            }}
                                            {...field}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDiscount'>
                                Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='earlyDiscount'
                                type='text'
                                placeholder='12000'
                                disabled={!isEarlyDiscountEnabled}
                                {...register('earlyDiscount' )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className='mb-4'>
                            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='earlyDiscountWithCEF'>
                                Discounted Price (HKD)
                            </label>
                            <input
                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                id='earlyDiscountWithCEF'
                                type='text'
                                placeholder='3000'
                                disabled={!isEarlyDiscountEnabled}
                                {...register('earlyDiscountWithCEF' )}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Schedule Description (Chinese)
                    </label>
                    <textarea
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='scheduleDescriptionChi'
                        rows={3}
                        placeholder='每逢&#10;星期二 : 19:00 - 22:00&#10;星期四 : 19:00 - 22:00'
                        {...register('scheduleDescriptionChi')}

                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Schedule Description (English)
                    </label>
                    <textarea
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='scheduleDescriptionEng'
                        rows={3}
                        placeholder='Every&#10;Tue :   19:00 - 22:00&#10;Thur : 19:00 - 22:00'
                        {...register('scheduleDescriptionEng')}

                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Venue (Chinese)
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='venueChi'
                        type='text'
                        placeholder='北角炮台山英皇道250號北角城中心12樓1201室 (炮台山港鐵站B出口)'
                        {...register('venueChi')}
                    />
                </div>
                <div className='mb-4'>
                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                        Venue (English)
                    </label>
                    <input
                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                        id='venueEng'
                        type='text'
                        placeholder='Rm1201, 12/F, Fortress Tower, 250 King’s Road, North Point, Hong Kong (Exit B, Fortress Hill MTR Station)'
                        {...register('venueEng')}
                    />
                </div>

            </Box>
                    )}
                <Box sx={{p: 5, pt: 3, pb: 3, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
            <Stack direction='row' spacing={2} justifyContent="flex-end">
            <Button variant="outlined" color="primary" sx={{mt: 3}} onClick={() => navigate("/course")}>
                Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{mt: 3}} disabled={isSubmitting}>
                {isCreate ? "Create" : "Save Change" }
            </Button>
            </Stack>
                </Box>
            </form>
        </Fade>
    );
}
