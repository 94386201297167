import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
import {editAssignment} from "../utils/request/course";
export const courseClassApi = createApi({
    baseQuery: baseQuery,
    reducerPath: "courseClassApi",
    endpoints: builder => ({
        getWeeks: builder.mutation({
            query: (body) => ({
                url: `/course/getWeeks`,
                method: "POST",
                body: body
            }),
        }),
        addClass: builder.mutation({
            query: (body) => ({
                url: "/course/addClass",
                method: "POST",
                body,
            }),
        }),
        editClass: builder.mutation({
            query: (body) => ({
                url: "/course/editClass",
                method: "POST",
                body,
            }),
        }),
        removeClass: builder.mutation({
            query: (body) => ({
                url: "/course/deleteClass",
                method: "POST",
                body,
            }),
        }),
        editMaterial: builder.mutation({
            query: (body) => ({
                url: "/course/editAssignment",
                method: "POST",
                body,
            }),
        }),
        addMaterial: builder.mutation({
            query: (body) => ({
                url: "/course/addAssignment",
                method: "POST",
                body,
            }),
        }),
        updateQuizDetail: builder.mutation({
            query: (body) => ({
                url: "/course/updateQuizDetail",
                method: "POST",
                body,
            }),
        }),
        getQuizDetail: builder.mutation({
            query: (body) => ({
                url: "/course/getQuizDetail",
                method: "POST",
                body,
            }),
        }),
        removeMaterial: builder.mutation({
            query: (body) => ({
                url: "/course/deleteAssignment",
                method: "POST",
                body,
            }),
        }),
        editMaterial2: builder.mutation({
            query: (body) => ({
                url: "/course/editMaterial",
                method: "POST",
                body,
            }),
        }),
        addMaterial2: builder.mutation({
            query: (body) => ({
                url: "/course/addMaterial",
                method: "POST",
                body,
            }),
        }),
        removeMaterial2: builder.mutation({
            query: (body) => ({
                url: "/course/deleteMaterial",
                method: "POST",
                body,
            }),
        }),
        uploadMaterialAttachment: builder.mutation({
            query: ({ assignmentId, file }) => {
                const formData = new FormData();
                formData.append('assignmentId', assignmentId);
                formData.append('file', file);

                return {
                    url: '/course/uploadAssignment',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        getMaterialAttachment: builder.mutation({
            query: (body) => ({
                url: "/course/getAssignmentDoc",
                method: "POST",
                body,
            }),
        }),
        deleteAttachment: builder.mutation({
            query: (body) => ({
                url: "/course/deleteDoc",
                method: "DELETE",
                body,
            }),
        }),
    }),
});

export const {
    useGetWeeksMutation,
    useAddMaterialMutation,
    useRemoveMaterialMutation,
    useEditMaterial2Mutation,
    useAddMaterial2Mutation,
    useRemoveMaterial2Mutation,
    useAddClassMutation,
    useRemoveClassMutation,
    useEditClassMutation,
    useEditMaterialMutation,
    useUploadMaterialAttachmentMutation,
    useGetMaterialAttachmentMutation,
    useDeleteAttachmentMutation,
    useUpdateQuizDetailMutation,
    useGetQuizDetailMutation,
} = courseClassApi;
