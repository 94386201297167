import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import {IOSSwitch} from "../../components/Switcher";
import Grid from '@mui/material/Grid';
import {
    useGetInternalNotificationQuery,
    useUpdateActiveMutation,
    useUpdateGroupListMutation
} from "../../api/notificationApi";
import Skeleton from '@mui/material/Skeleton';
import {styled} from "@mui/system";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import GroupTransferList from "./GroupTransferList";
import LoadingButton from "@mui/lab/LoadingButton";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
export default function InternalNotification() {
    const [toggle, setToggle] = React.useState(false);
    const [groupItem, setGroupItem] = React.useState({});
    const [saving, setSaving] = React.useState(false);
    const ref = React.useRef();
    const TransferModal = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const handleOpen = (item) => {
        setGroupItem(item)
        setToggle(true);
    }

    const handleSave = () => {
        setSaving(true);
        updateNotificationGroup({
            groupId: groupItem.id,
            userIds: ref.current.selectedUsers(),
        }).then(() => {
            setSaving(false);
            setToggle(false);
        });
    }
    const handleClose = () => {
        setToggle(false);
    }
    const result = useGetInternalNotificationQuery();
    const data = result?.data?.data;

    const [updateNotificationActive] = useUpdateActiveMutation();
    const [updateNotificationGroup] = useUpdateGroupListMutation();
    const updateActive = (id, checked) => {
        updateNotificationActive({
            id,
            active: checked,
        });
    }

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    let gridSize;

    if (isXs) {
        gridSize = 12; // full width on extra small screens
    } else if (isSm) {
        gridSize = 6; // 2 cards on small screens
    } else if (isMd) {
        gridSize = 6; // 3 cards on medium screens
    } else if (isLg) {
        gridSize = 4; // 4 cards on large screens
    } else if (isXl) {
        gridSize = 4; // 6 cards on extra large screens
    }

    const emptyBox = Array.from({ length: 9 }).map((_, index) => Array.from({ length: 9 }).map((_, index) =>
        <Grid item xs={gridSize}>
            <Card sx={{ height: '100%', display: 'flex',  borderRadius: 5, flexDirection: 'column' }}>
                <Skeleton variant="rectangular" height={140} />
                <CardContent>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                </CardContent>
                <CardActions>
                    <Skeleton variant="text" width="80px" height="24px" />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto'}}>
                        <Skeleton variant="circle" width={24} height={24} />
                    </div>
                </CardActions>
            </Card>
        </Grid>
    ));

    return (
        <>
        <TransferModal
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose()
                }
            }}
            maxWidth={"md"}
            aria-labelledby="customized-dialog-title"
            open={toggle}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Edit {groupItem.title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
               <GroupTransferList groupItem={groupItem} ref={ref}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton autoFocus onClick={handleSave} loading={saving}>
                    Save changes
                </LoadingButton>
            </DialogActions>
        </TransferModal>
        <Fade in={true}>
            <Box sx={{ p: 5, borderRadius: 5, backgroundColor: 'white'}}>
                <Stack spacing={2} direction="row" mt={-2} mb={2} justifyContent="flex-end">
                    <Button  startIcon={<SettingsIcon />} onClick={()=> { window.open("https://developers.facebook.com/docs/whatsapp/message-templates/guidelines") }}>
                        Template Setup
                    </Button>
                </Stack>
            <Grid container spacing={2} alignItems="stretch">
                {!data ? emptyBox
                    : (
                    data.map((item) =>
                        <Grid item xs={gridSize} key={item.id}>
                            <Card sx={{ height: 480, display: 'flex', borderRadius: 5, flexDirection: 'column' }}>
                                <CardMedia
                                    component="img"
                                    alt={item.title}
                                    height="140"
                                    image={item.img}
                                    sx={{ height: '50%', objectFit: 'cover' }}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" fontWeight={"bold"} component="div">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {item.type !== "group" ? <small>All {item.type}s</small> :
                                        <Button size="small" onClick={() => handleOpen(item)}>Edit Member</Button>
                                    }
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            defaultChecked={item.active}
                                            onChange={(e) => updateActive(item.id, e.target.checked)}
                                        />
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                )}
            </Grid>
            </Box>
        </Fade>
        </>
    );
}
