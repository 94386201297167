import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade, TextField
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useCloneIntakeMutation,
    useGetIntakeListQuery, useGetIntakeStudentListMutation,
    useLazyGetIntakeListQuery, useUpdateExamScoreMutation
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate} from "react-router-dom";
import {delay} from "../../../../utils/util";
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import {statusColor} from "../IntakeEnrollList/IntakeEnrollList";


const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

const MarkExamDialog = ({handleToggle, toggle, row, refresh}) => {
    const [loading, setLoading] = useState(false);
    const [updateExamScore] = useUpdateExamScoreMutation();
    const [score, setScore] = useState("");
    return (
        <Dialog
            open={toggle}
            //onClose={handleToggle}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleToggle()
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {row?.applicationNo}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" width={450}>
                    <Stack direction={"row"} spacing={2}>
                        <span>Input Score</span>
                    <TextField
                        fullWidth
                        onChange={(e) => setScore(e.target.value)}
                    />
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleToggle}>Cancel</Button>
                <LoadingButton
                    color="primary"
                    onClick={ async () => {
                        setLoading(true)
                        await updateExamScore({
                            id: row?.id,
                            score,
                        });
                        await refresh();
                        await delay(1000);
                        setLoading(false);
                        handleToggle()
                    }}
                    loading={loading}
                    loadingPosition="center"
                    autoFocus
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default function IntakeStudentList({ courseId, intake}) {

    const [fetchIntakeStudentList] = useGetIntakeStudentListMutation();
    const [data, setData] = useState(null);
    const [toggle, setToggle] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const navigate = useNavigate();

    const handleToggle = (row) => {
        setRow(row);
        setToggle(!toggle);
    };

    const refresh = () => {
        fetchIntakeStudentList({
            courseId,
            intake
        }).then(({ data }) => {
            const { data: content } = data;
            setData(content);
        });
    }

    useEffect(() => {
        refresh();
    }, []);



    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "name",
            ...defaultColumnType,
            headerName: "Name",
            renderCell: (params) => {
                return (
                    <>
                    {params.row.name} <br/> ({params.row.email})
                    </>
                )
            },
            width: 320
        },
        {
            field: "mobile",
            ...defaultColumnType,
            headerName: "Mobile",
            width: 160
        },
        {
            field: "assignment",
            ...defaultColumnType,
            headerName: "Assignment",
            renderCell: (params) => {
                if (params.row.assignmentScore.length === 0) {
                    return "N/A";
                }

                const reviewedCount = params.row.assignmentScore.filter(item => item.score).length;

                return (
                    <Box>{reviewedCount} / {params.row.assignmentScore.length} Reviewed</Box>
                );
            },
            flex: 1.3
        },
        {
            field: "quiz",
            ...defaultColumnType,
            headerName: "Quiz",
            renderCell: (params) => {
                return "N/A"
            },
            width: 250
        },
        {
            field: "exam",
            ...defaultColumnType,
            headerName: "Exam",
            renderCell: (params) => {
                if(params.row.examScore.length === 0) {
                    return "No exam required"
                }
                return (
                    <Stack spacing={1}>
                        {params.row.examScore.map((score, index) => {
                            if(score.score) {
                                let passFail, color;
                                if (score.score / score.maxPoint >= score.passPercentage / 100) {
                                    color = 'green';
                                    passFail = 'PASS'
                                } else {
                                    color = 'red'
                                    passFail = 'FAIL'
                                }
                                return (
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                        <Chip label={passFail}  variant="filled" sx={{ mr: 1, blockSize: '90%', color: 'white', backgroundColor: color}}/>
                                    </Box>
                                )
                            }else{
                                return <Button color={"success"} onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggle(params.row)
                                }}>Input score</Button>;
                            }
                        })}
                    </Stack>
                )},
            width: 220
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 10 } },
    }

    const onRowClick = (params) => {
        navigate(`/intake/${courseId}/${intake}/student/${params.row.studentId}`);
    };

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <MarkExamDialog handleToggle={handleToggle} toggle={toggle} row={row} refresh={refresh}/>
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                }}
                initialState={initState}
                rowHeight={80}
                onRowClick={onRowClick}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                pageSizeOptions={[10]}
                loading={data === null}
                pagination
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
