import {createApi} from "@reduxjs/toolkit/query/react";
import baseQuery from "./base";
export const courseDocumentApi = createApi({
    baseQuery,
    reducerPath: "courseDocumentApi",
    endpoints: builder => ({
        // getAccountList: builder.query({
        //     query: (params) => `/course/document/metadata`,
        // }),
        getCourseDocuments: builder.mutation({
            query: (body) => ({
                url: "/course/document/metadata",
                method: "POST",
                body,
            })
        }),
        regenerateCourseDocuments: builder.mutation({
            query: (body) => ({
                url: "/course/document/regenerate",
                method: "POST",
                body,
            })
        }),
        generateAccredibleCert: builder.mutation({
            query: (body) => ({
                url: "/course/document/accredible/generate",
                method: "POST",
                body,
            })
        }),
        regenerateAccredibleCert: builder.mutation({
            query: (body) => ({
                url: "/course/document/accredible/regenerate",
                method: "POST",
                body,
            })
        }),
        generateLeaflet: builder.mutation({
            query: (body) => ({
                url: "/course/document/leaflet/generate",
                method: "POST",
                body,
            })
        }),
        generateDeclaration: builder.mutation({
            query: (body) => ({
                url: "/course/document/declaration/generate",
                method: "POST",
                body,
            })
        }),
        generateProofOfCompletion: builder.mutation({
            query: (body) => ({
                url: "/course/document/proofOfCompletion/generate",
                method: "POST",
                body,
            })
        }),
        generateAttendanceList: builder.mutation({
            query: (body) => ({
                url: "/course/document/attendanceList/generate",
                method: "POST",
                body,
            })
        }),
        generateTableOfCollection: builder.mutation({
            query: (body) => ({
                url: "/course/document/tableOfCollection/generate",
                method: "POST",
                body,
            })
        }),
        generateStudentGuideline: builder.mutation({
            query: (body) => ({
                url: "/course/document/studentGuideline/generate",
                method: "POST",
                body,
            })
        }),
        uploadTemplate: builder.mutation({
            query: ({ template, file }) => {
                let formData = new FormData();
                formData.append('file', file);
                return {
                    url: `/course/document/template/upload/${template}`,
                    method: "POST",
                    body: formData,
                };
            }
        }),
        updateMetaData: builder.mutation({
            query: (body) => ({
                url: "/course/document/updateMetadata",
                method: "POST",
                body,
            })
        }),
    }),
});

export const {
    useGetCourseDocumentsMutation,
    useGenerateAccredibleCertMutation,
    useRegenerateAccredibleCertMutation,
    useRegenerateCourseDocumentsMutation,
    useGenerateLeafletMutation,
    useGenerateProofOfCompletionMutation,
    useGenerateTableOfCollectionMutation,
    useGenerateAttendanceListMutation,
    useGenerateStudentGuidelineMutation,
    useGenerateDeclarationMutation,
    useUploadTemplateMutation,
    useUpdateMetaDataMutation,
} = courseDocumentApi;
