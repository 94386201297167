
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Button,
    Stack,
} from '@mui/material';
import style from './Quiz.module.scss'
import TablePage from '../UI/TablePage'
import { postMarkSubmission } from '../../utils/request/assignment';
import backArrow from '../../image/back_arrow.svg';
import { Modal, ModalInner } from '../UI/Modal';
import { getQuizSubmissionList, getSubmissionDetail, getQuizAnswerList, getQuizDetail } from '../../utils/request/quiz';


const QuizList = () => {

    const navigate = useNavigate();
    const param = useParams();

    const [submissionList, setSubmissionList] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(submissionList.length)

    const [currentSubmission, setCurrentSubmission] = useState([]);
    const [openSubmission, setOpenSubmission] = useState(false);
    const [submissionScore, setSubmissionScore] = useState(0);
    const [submissionFeedback, setSubmissionFeedback] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [currentQuestions, setCurrentQuestions] = useState(null);
    const [currentAnswers, setCurrentAnswers] = useState(null);
    const [currentStudentAnswer, setCurrentStudentAnswer] = useState(null);

    const [groupedData, setGroupedData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const onBackToAssignment = () => {
        navigate('/quiz');
    }

    const onTableRowClick = async (record) => {
        console.log(record)

        setCurrentSubmission(record);

        setOpenSubmission(true)
        setIsLoading(true);

        try {
            let answerList = await getQuizAnswerList(record.quizId)
            let quizDetail = await getQuizDetail(record.quizId)

            let studentAnswer = answerList.data.data.filter((answer) => answer.submissionId === record.submissionId)

            setCurrentStudentAnswer(JSON.parse(studentAnswer[0].answers));
            setCurrentAnswers(JSON.parse(quizDetail.data.data.answers));
            setCurrentQuestions(JSON.parse(quizDetail.data.data.questions));

            setIsLoading(false);

        } catch (error) {
            console.log('get submission record error', error)
        }
    }


    useEffect(() => {
        console.log('currentStudentAnswer', currentStudentAnswer)
        console.log('currentQuestions', currentQuestions);
        console.log('setCurrentAnswers', currentAnswers)
    }, [currentAnswers])

    const onPageChange = async (e, pageNumber) => {

    }

    const onRowsPerPageChange = (e) => {
        setPage(0)
        setRowsPerPage(parseInt(e.target.value))
    }

    useEffect(() => {
        init().then(() => setOpenSubmission(false));
    }, [])

    useEffect(() => {
        if (currentSubmission) {
            //reset
            setSubmissionScore(currentSubmission.score);
            setSubmissionFeedback(currentSubmission.comment);
        }
    }, [currentSubmission])



    const onClickCloseSubmission = () => {
        setOpenSubmission(false)
    }

    const onSubmitMarkingOnly = async () => {
        // submit marking
        try {
            let result = await postMarkSubmission(
                currentSubmission.studentId,
                currentSubmission.courseId,
                currentSubmission.intake,
                currentSubmission.submissionId,
                submissionScore,
                submissionFeedback
            )
            console.log('update marking success', result)
            setOpenSubmission(false)
            init();
        } catch (error) {
            console.log('submit marking error', error)
        }
    }


    const init = async () => {
        const groupedDataList = [];
        try {
            let result = await getQuizSubmissionList(param.courseId, param.intake)

            if (result.data.data.length === 0) {
                return;
            }
            result.data.data.map((submission) => {
                const assignmentId = submission.assignmentId;
                const assigmentName = submission.assignmentName;
                if (groupedDataList.hasOwnProperty(assignmentId)) {
                    groupedDataList[assignmentId].submissions.push(submission);
                } else {
                    groupedDataList[assignmentId] = {
                        assignmentName: assigmentName,
                        submissions: [submission]
                    }
                }
            });
            console.log('groupedData', groupedDataList)
            setGroupedData(groupedDataList);
            const [firstKey, firstValue] = Object.entries(groupedDataList)[0];
            const firstGroupedObject = {
                [firstKey]: firstValue
            };
            setSelectedGroup(firstGroupedObject);
        } catch (error) {
            console.log('Get getSubmissionList error : ', error)
        }
    }

    useEffect(() => {
        if (selectedGroup) {
            console.log('selectedGroup', selectedGroup);
            setSubmissionList(Object.values(selectedGroup)[0].submissions);
            setTotalNumberOfRecords(Object.values(selectedGroup)[0].submissions.length);
        }

    }, [selectedGroup])


    return (

        <>
            {
                openSubmission ?
                    <Modal>
                        <ModalInner>
                            <div className="uM_title">
                                Evaluation
                            </div>
                            <div className="uM_body">
                                <div className='modal-info-wrapper'>
                                    <div className='modal-info'>
                                        <div>StudentId</div>
                                        <div>{currentSubmission.studentId}</div>
                                    </div>
                                </div>

                                <div className={style.quizContent}>
                                    {
                                        !isLoading && currentQuestions && currentQuestions.map((question, OuterIndex) => {
                                            return (
                                                <div className='modal-info-wrapper'>
                                                    <div className='modal-info'>
                                                        <div>{OuterIndex + 1}. {question.question}</div>

                                                        <div className={style.answerList}>
                                                            {currentAnswers[OuterIndex] &&
                                                                question.type === "MC" && question.options.map((option, index) => {
                                                                    return (
                                                                        <span
                                                                            className={`${style.answerChip} ${option === currentAnswers[OuterIndex].answer ? style.correctAns : null}`}>
                                                                            {option}</span>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>

                                                        {
                                                            currentStudentAnswer && <div>Student Answer: {currentStudentAnswer[OuterIndex].answer}</div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>





                            </div>
                            <div className="uM_action">
                            <div className='modal-input-wrapper full'>
                                    <TextField
                                        className={style.name}
                                        label="Comment"
                                        placeholder="Leave your comment here"
                                        value={submissionFeedback}
                                        onChange={(e) =>
                                            setSubmissionFeedback(e.target.value)
                                        }
                                    />
                                </div>
                                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                    <div className='modal-input-wrapper'>
                                        <TextField
                                            className={style.name}
                                            label="Score"
                                            placeholder="0"
                                            value={submissionScore}
                                            onChange={(e) =>
                                                setSubmissionScore(e.target.value)
                                            }
                                        />
                                    </div>
                                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                                        <Button onClick={onClickCloseSubmission} variant="text">Cancel</Button>
                                        <Button onClick={onSubmitMarkingOnly} variant="contained" color="primary">Submit</Button>
                                    </Stack>
                                </Stack>
                            </div>
                        </ModalInner>
                    </Modal> : null
            }
            <div className="backContainer">
                <img src={backArrow} />
                <Button onClick={onBackToAssignment} variant="Text">Back to List</Button>
            </div>
            <div className={style.container}>
                {
                    groupedData && Object.keys(groupedData).length > 0 ?
                        <div className={style.left}>
                            <div className={style.wigetContainer}>
                                <div>
                                    {selectedGroup &&
                                        Object.keys(groupedData).map((key, index) => {
                                            return (
                                                <div
                                                    className={`${style.wigetItem} ` + (Object.keys(selectedGroup)[0] === key ? style.active : '')}
                                                    onClick={() => setSelectedGroup({ [key]: groupedData[key] })}>
                                                    <div className={style.itemInner}>{groupedData[key].assignmentName} ({groupedData[key].submissions.length})</div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                        </div> : null
                }
                <div className={style.right}>
                    <TablePage title="Quiz List">
                        <TableContainer className="tableContainer">
                            <Table>
                                <TableHead className="tableHead">
                                    <TableRow>
                                        <TableCell className="theadCell">Student</TableCell>
                                        <TableCell className="theadCell">Score</TableCell>
                                        <TableCell className="theadCell">Status</TableCell>
                                        <TableCell className="theadCell">Progress</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {submissionList &&
                                        submissionList
                                            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                            .map((record, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className="tableRow"
                                                        onClick={record.status === 1 ? () => onTableRowClick(record) : null}
                                                    >
                                                        <TableCell className="tableCell">
                                                            {record.studentName}
                                                            {record.studentId}
                                                        </TableCell>
                                                        <TableCell className="tableCell">{record.score}</TableCell>
                                                        <TableCell className="tableCell">
                                                            <div>
                                                                {record.status === 1 ? 'Latest' : 'Depre'}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className="tableCell">
                                                            <div>
                                                                {record.progress === 1 ? 'Marked' : '-'}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={totalNumberOfRecords}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[10, 20]}
                                            onPageChange={onPageChange}
                                            page={page}
                                            onRowsPerPageChange={onRowsPerPageChange}
                                        ></TablePagination>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TablePage>
                </div>
            </div>

        </>
    )
}

export default QuizList;
