import React, {useEffect} from 'react'
import style from './Dashboard.module.scss'
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/course')
    }, []);
    return (
        <div className={style.sectionContainer}>
            <h1>Welcome</h1>
            <p>Start managing the course from the left bar</p>
        </div>
    )
}

export default Dashboard
