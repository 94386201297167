import * as React from 'react';
import { useState } from 'react';
import {
    Box,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Button,
    IconButton
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CloseIcon from "@mui/icons-material/Close";

export default function EditQuiz({ questions = [], setQuestions, onReturn }) {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updatedQuestions = reorder(
            questions,
            result.source.index,
            result.destination.index
        );

        setQuestions(updatedQuestions);
        onReturn(updatedQuestions); // Update parent state
    };

    const handleAnswerChange = (event, questionIndex, answerIndex = 0) => {
        const { value } = event.target;
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            if (updatedQuestions[questionIndex].questionType === 'mc') {
                const updatedAnswers = [...updatedQuestions[questionIndex].answers];
                updatedAnswers[answerIndex] = value;
                updatedQuestions[questionIndex] = {
                    ...updatedQuestions[questionIndex],
                    answers: updatedAnswers
                };
            } else {
                updatedQuestions[questionIndex] = {
                    ...updatedQuestions[questionIndex],
                    answers: value
                };
            }
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleTitleChange = (event, questionIndex) => {
        const { value } = event.target;
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions[questionIndex].title = value;
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleAddChoice = (questionIndex) => {
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions[questionIndex].answers.push('');
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleRemoveChoice = (questionIndex, answerIndex) => {
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            const updatedAnswers = [...updatedQuestions[questionIndex].answers];
            updatedAnswers.splice(answerIndex, 1);
            updatedQuestions[questionIndex].answers = updatedAnswers;
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleAddQuestion = () => {
        setQuestions(prevState => {
            const updatedQuestions = [
                ...prevState,
                { questionType: 'mc', title: '', answers: [''], correctAnswerIndex: null, explanation: '' }
            ];
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleRemoveQuestion = (questionIndex) => {
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions.splice(questionIndex, 1);
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleQuestionTypeChange = (event, questionIndex) => {
        const { value } = event.target;
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions[questionIndex] = {
                ...updatedQuestions[questionIndex],
                questionType: value,
                answers: value === 'mc' ? [''] : '', // Reset answers if changing to short answer
                correctAnswerIndex: null // Reset correct answer index
            };
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleCorrectAnswerChange = (event, questionIndex) => {
        const { value } = event.target;
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions[questionIndex].correctAnswerIndex = Number(value);
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    const handleExplanationChange = (event, questionIndex) => {
        const { value } = event.target;
        setQuestions(prevState => {
            const updatedQuestions = [...prevState];
            updatedQuestions[questionIndex].explanation = value;
            onReturn(updatedQuestions); // Update parent state
            return updatedQuestions;
        });
    };

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Fade in={true}>
                    <Box sx={{ width: '100%', position: 'relative' }}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <Box
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    sx={{ width: '100%' }}
                                >
                                    {questions.map((question, questionIndex) => (
                                        <Draggable key={questionIndex} draggableId={`item-${questionIndex}`} index={questionIndex}>
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{ p: 6, pb: 2, mt: 4, borderRadius: 5, backgroundColor: 'white', position: 'relative' }}
                                                >
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={() => handleRemoveQuestion(questionIndex)}
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 15,
                                                            right: 15,
                                                            color: 'grey',
                                                            '&:hover': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Box key={questionIndex} sx={{ mb: 4 }}>
                                                        <FormControl component="fieldset" sx={{ mb: 2, display: 'block' }}>
                                                            <FormLabel component="legend" color={"success"}>Question {questionIndex + 1}</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                value={question.questionType}
                                                                onChange={(event) => handleQuestionTypeChange(event, questionIndex)}
                                                            >
                                                                <FormControlLabel value="mc" control={<Radio />} label="Multiple Choice" />
                                                                <FormControlLabel value="short" control={<Radio />} label="Short Question" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <FormControl component="fieldset" fullWidth>
                                                            <input
                                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                                type='text'
                                                                placeholder='Which one is the correct answer?'
                                                                value={question.title || ''}
                                                                onChange={(event) => handleTitleChange(event, questionIndex)}
                                                            />
                                                        </FormControl>
                                                        {question.questionType === 'mc' ? (
                                                            <FormControl component="fieldset">
                                                                <label
                                                                    className='block text-gray-700 text-sm font-bold mt-4 mb-2'
                                                                    htmlFor={`answers-${questionIndex}`}>
                                                                    Option(s)
                                                                </label>
                                                                <RadioGroup>
                                                                    {question.answers.map((answer, answerIndex) => (
                                                                        <Box key={`${questionIndex}-${answerIndex}`}
                                                                             sx={{
                                                                                 display: 'flex',
                                                                                 alignItems: 'center',
                                                                                 mb: 1
                                                                             }}>
                                                                            <FormControlLabel
                                                                                control={<Radio
                                                                                    sx={{ fontSize: 10 }}
                                                                                    checked={question.correctAnswerIndex === answerIndex}
                                                                                    onChange={(event) => handleCorrectAnswerChange(event, questionIndex)}
                                                                                    value={answerIndex}
                                                                                />}
                                                                                label={
                                                                                    <input
                                                                                        className='appearance-none border rounded w-80 py-2 px-3 my-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                                                        type='text'
                                                                                        value={answer}
                                                                                        onChange={(event) => handleAnswerChange(event, questionIndex, answerIndex)}
                                                                                    />
                                                                                }
                                                                            />
                                                                            <IconButton
                                                                                onClick={() => handleRemoveChoice(questionIndex, answerIndex)}>
                                                                                <RemoveCircleOutlineIcon fontSize={"small"} />
                                                                            </IconButton>
                                                                        </Box>
                                                                    ))}
                                                                    <Button startIcon={<AddCircleOutlineIcon />} onClick={() => handleAddChoice(questionIndex)}>
                                                                        Add choice
                                                                    </Button>
                                                                </RadioGroup>
                                                            </FormControl>
                                                        ) : (
                                                            <FormControl component="fieldset" fullWidth>
                                                                <div className='mb-4'>
                                                                    <label
                                                                        className='block text-gray-700 text-sm font-bold mt-4 mb-2'
                                                                        htmlFor={`answers-${questionIndex}`}>
                                                                        Answer
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                                        type='text'
                                                                        value={question.answers || ''}
                                                                        onChange={(event) => handleAnswerChange(event, questionIndex)}
                                                                    />
                                                                </div>
                                                            </FormControl>
                                                        )}
                                                        <FormControl component="fieldset" fullWidth>
                                                            <div>
                                                                <label
                                                                    className='block text-gray-700 text-sm font-bold mb-2'
                                                                    htmlFor='explanation'>
                                                                    Explanation
                                                                </label>
                                                                <textarea
                                                                    className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                                    id='explanation'
                                                                    rows={3}
                                                                    placeholder=''
                                                                    value={question.explanation}
                                                                    onChange={(event) => handleExplanationChange(event, questionIndex)}
                                                                />
                                                            </div>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                        <Stack spacing={2} alignItems={"end"} alignContent={"flex-end"} p={2}>
                            <Button color={"success"} startIcon={<AddCircleOutlineIcon />} onClick={handleAddQuestion}>
                                Add Question
                            </Button>
                        </Stack>
                    </Box>
                </Fade>
            </DragDropContext>
        </Box>
    );
}
