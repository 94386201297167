import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {courseDocumentApi} from "../api/courseDocumentApi";
import {authSlice} from "./slices/authSlice";
import {courseIntakeApi} from "../api/courseIntakeApi";
import {courseGeneralApi} from "../api/courseGeneralApi";
import {tutorlApi} from "../api/courseTutorApi";
import {settingApi} from "../api/settingApi";
import {notificationApi} from "../api/notificationApi";
import {timesheetApi} from "../api/timesheetApi";
import {externalApi} from "../api/externalApi";
import {publicApi} from "../api/publicApi";
import {attendanceApi} from "../api/attendanceApi";
import {submissionApi} from "../api/submissionApi";
import {enrollmentApi} from "../api/enrollmentApi";
import {intakeSlice} from "./slices/IntakeSlice";
import {courseClassApi} from "../api/courseClassApi";
import {removeApi} from "../api/removeApi";

export const makeStore = () => {

    const middlewares = [
        courseGeneralApi.middleware,
        courseDocumentApi.middleware,
        courseIntakeApi.middleware,
        tutorlApi.middleware,
        settingApi.middleware,
        notificationApi.middleware,
        timesheetApi.middleware,
        externalApi.middleware,
        publicApi.middleware,
        attendanceApi.middleware,
        submissionApi.middleware,
        enrollmentApi.middleware,
        courseClassApi.middleware,
        removeApi.middleware,
    ];

    const apiReducer = {
        [courseGeneralApi.reducerPath]: courseGeneralApi.reducer,
        [courseDocumentApi.reducerPath]: courseDocumentApi.reducer,
        [courseIntakeApi.reducerPath]: courseIntakeApi.reducer,
        [tutorlApi.reducerPath]: tutorlApi.reducer,
        [settingApi.reducerPath]: settingApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [timesheetApi.reducerPath]: timesheetApi.reducer,
        [externalApi.reducerPath]: externalApi.reducer,
        [publicApi.reducerPath]: publicApi.reducer,
        [attendanceApi.reducerPath]: attendanceApi.reducer,
        [submissionApi.reducerPath]: submissionApi.reducer,
        [enrollmentApi.reducerPath]: enrollmentApi.reducer,
        [courseClassApi.reducerPath]: courseClassApi.reducer,
        [removeApi.reducerPath]: removeApi.reducer,
    };

    const sliceReducer = {
        auth: authSlice.reducer,
        intake: intakeSlice.reducer,
    };

    const reducer = combineReducers({
        ...apiReducer,
        ...sliceReducer,
    });

    return configureStore({
        reducer,
        middleware: gDM =>
            gDM({}).concat(middlewares),
    });
};
