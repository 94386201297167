import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Grid from '@mui/material/Unstable_Grid2';
import {Divider, FormControlLabel, FormGroup, TextField, TextareaAutosize, Button, IconButton} from "@mui/material";
import {IOSSwitch} from "../../components/Switcher";
import LoadingButton from '@mui/lab/LoadingButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import MultipleDatesPicker from '@ambiot/material-ui-multiple-dates-picker'
import ListItemText from '@mui/material/ListItemText';
import React, {useEffect, useState} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
    useGetCourseGeneralMutation,
    useGetCourseScheduleQuery,
    useLazyGetCourseListQuery
} from "../../api/courseGeneralApi";
import moment from 'moment';
import { delay } from "../../utils/util"
import {useGetPublicHolidayQuery} from "../../api/publicApi";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const TextareaWrapper = styled('div')({
    position: 'relative',
    flex: '1 1 auto',
});


const fullWeeks = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DisplayResult = ({ classSchedule, courses, course, result = [], loading }) => {
    const courseName = courses.find((item) => item.value === course)?.key;
    const intakes = [...new Set(result.map((item) => item.intake))];
    let intakeContent = "";

    for (const intake of intakes) {
        intakeContent += `<table style="width: 100%;"><tr><th colspan="3">Intake ${intake}</th></tr><tr><th>Class</th><th>Title</th><th>Date</th></tr>`;
        const intakeItems = result.filter((item) => item.intake === intake);
        console.log("intakeItems", intakeItems)
        for (let i = 0; i < intakeItems.length; i++) {
            const lesson = `${intakeItems[i].type} ${intakeItems[i].type === "Class" ? i + 1 : ""}`;
            const latestIntake = classSchedule
                .filter((item) => item.courseId === course)
            const lessonName = latestIntake.length > i ? latestIntake[i].lessonName || `Lesson ${i}` : `Lesson ${i}`;
            const startDate = moment(intakeItems[i].date).format('DD/MM/YYYY');
            intakeContent += `<tr><td>${lesson}</td><td>${lessonName}</td><td>${startDate}</td></tr>`;
        }
        intakeContent += "</table><br>";
    }

    const content = courseName && `<strong>${courseName}</strong><br>${intakeContent}`;

    return (
        !loading && loading != null &&
            <div
                style={{
                    border: '1px solid #ccc',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    fontFamily: '"Courier New", monospace'
                }}
                dangerouslySetInnerHTML={{
                    __html: content || ""
                }}
            />
    )
}

const FullCalendarWrapper = styled('div')({
    '& .fc-button-primary': {
        backgroundColor: 'transparent !important',
        color: 'grey !important',
        border: 'none !important',
        boxShadow: 'none !important', // Remove box-shadow
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: 'darkgrey !important',
        },
        '&:focus': {
            backgroundColor: 'transparent !important',
            color: 'darkgrey !important',
            boxShadow: 'none !important', // Remove box-shadow on focus
        },
        '&:active': {
            backgroundColor: 'transparent !important',
            color: 'darkgrey !important',
            boxShadow: 'none !important', // Remove box-shadow on active
        },
    },
});
function ScheduleTable() {
    //sources
    const [courses, setCourses] = useState([]);

    //display
    const [hideWeekend, setHideWeekend] = useState(false);
    const [hideExistingIntake, setHideExistingIntake] = React.useState(false);

    //AI control
    const [course, setCourse] = useState("");
    const [weeks, setWeeks] = useState(['Mon', 'Wed']);
    const [intakeLesson, setIntakeLesson] = useState(10);
    const [recurring, setRecurring] = useState(10);
    const [dayFrom, setDayFrom] = useState(dayjs(new Date()));
    const [noPublicHoliday, setNoPublicHoliday] = useState(true);
    const [multiDateOpen, setMultiDateOpen] = useState(false);
    const [ignoreDates, setIgnoreDates] = useState([]);
    const [previewChange, setPreviewChange] = useState(true);

    const [loading, setLoading] = useState(null);
    const [fetchCourseGeneral] = useLazyGetCourseListQuery();
    const [lessonDistributions, setLessonDistributions] = useState([
        { id: 1, lessons: 6, days: ['Tue', 'Fri'] },
        { id: 2, lessons: 4, days: ['Wed'] }
    ]);
    const [result, setResult] = useState([]);

    const courseSchedule = useGetCourseScheduleQuery();
    let publicHolidays = useGetPublicHolidayQuery();

    publicHolidays = publicHolidays?.data?.data;

    useEffect(() => {
        Promise
            .all([fetchCourseGeneral()])
            .then(([{data: {data : courseResult}}], ) => {
                setCourses(courseResult.map((item) => {
                    return {
                        key: item.name,
                        value: item.id,
                    }
                }));
            });
    }, []);

    const generatedEnable = course && intakeLesson && recurring && weeks.length > 0;
    const classSchedule = courseSchedule?.data?.data;
    const displayClassSchedule = classSchedule?.filter((item) => item.startDate).sort((a, b) => a.startDate - b.startDate);
    let schedule = [];
    if(!hideExistingIntake) {
        schedule = displayClassSchedule?.map((item) => {
            return {
                title: item.classId,
                date: item.startDate
            }
        });
    }
    if (classSchedule && previewChange) {
        schedule = [...schedule, ...result.map((item) => {
            return {
                title: item.classId,
                date: item.date
            }
        })];
    }

    function renderEventContent(eventInfo) {
        const { title: classId } = eventInfo.event;
        let item, isNew = false;
        if(classId <= 10000000) {
             item = displayClassSchedule.find((item) => item.classId.toString() === classId);
        }else{
             item = result.find((item) => item.classId.toString() === classId);
             isNew = true;
        }
        const lesson = item?.type === "Exam" ? "EM" : `CS-${item?.lesson}`;
        return (
            <Box
                sx={{
                    backgroundColor: item?.type === "Exam" ? (isNew ? 'red' : 'purple') : (isNew ? 'green' : '#0d6efd'),
                    width: '100%',
                    color: 'white',
                    borderRadius: 1,
                    cursor: 'pointer',
                }}
            >
                <span style={{ marginLeft: 5}}>{item?.prefixKey}-{item?.intake} {lesson}</span>
            </Box>
        );
    }

    const handleAddDistribution = () => {
        const newId = Math.max(...lessonDistributions.map(dist => dist.id), 0) + 1;
        setLessonDistributions([...lessonDistributions, { id: newId, lessons: 5, days: ['Wed'] }]);
    };

    const handleRemoveDistribution = (id) => {
        setLessonDistributions(lessonDistributions.filter(dist => dist.id !== id));
    };

    const handleDayChange = (id, days) => {
        setLessonDistributions(lessonDistributions.map(dist => dist.id === id ? { ...dist, days } : dist));
    };

    const handleLessonCountChange = (id, count) => {
        setLessonDistributions(lessonDistributions.map(dist => dist.id === id ? { ...dist, lessons: parseInt(count, 10) } : dist));
    };
    console.log("lessonDistributions", lessonDistributions)
    const generateIntakePlan = () => {
        setLoading(true);
        let output = [];
        let classId = 10000001;

        const lastIntakeNumber = classSchedule
            .filter(item => item.courseId === course)
            .reduce((max, item) => Math.max(max, item.intake || 0), 0);

        let startDate = moment(dayFrom.toDate());

        const { prefixKey } = classSchedule.find((item) => item.courseId === course);
        // Iterate over the number of intakes (recurring)
        for (let intakeIndex = 1; intakeIndex <= recurring; intakeIndex++) {
            let currentIntake = lastIntakeNumber + intakeIndex;
            let currentDate = startDate.clone(); // Clone the start date for this intake

            let displayLessonCount = 0;


            lessonDistributions.forEach(dist => {


                if(dist.days.length <= 1) {
                    let dayIndex = 0; // Index to cycle through the days
                    let lessonCount = 0;

                    while (lessonCount < dist.lessons) {
                        let day = dist.days[dayIndex % dist.days.length];
                        currentDate = moveToDate(currentDate, day); // Move to the next correct day

                        if (isPublicHoliday(currentDate) || isIgnoreDate(currentDate)) {
                            currentDate.add(1, 'week'); // Skip one week if it's a public holiday or ignored date
                            continue; // Continue without incrementing lessonCount
                        }

                        output.push({
                            title: `Class ${classId}`,
                            date: currentDate.format('YYYY-MM-DD'),
                            classId: classId++,
                            type: 'Class',
                            intake: currentIntake,
                            lesson: displayLessonCount + 1,
                            prefixKey
                        });

                        dayIndex++; // Move to the next day in the distribution list
                        lessonCount++; // Increment the count of lessons scheduled
                        displayLessonCount++;

                        if(lessonCount === dist.lessons) {
                            let currentDay = currentDate.format('ddd');

                            // No matter which day now, Move until to Sat
                            if(currentDay !== 'Sat') {
                                currentDate = moveToDate(currentDate, 'Sat');
                            }

                        }else {
                            currentDate.add(1, 'week'); // Move to the same day next week
                        }
                    }
                } else {
                    let currentLesson = 1;
                    let currentWeek = 0;
                    currentDate = moment(currentDate).add(currentWeek, 'weeks');

                    while (currentLesson <= dist.lessons) {
                        for (let j = 0; j < 7; j++) { // loop through each day of the week
                            let dayName = currentDate.format('ddd');

                            // Check if the day is one of the selected week days and not a public holiday if applicable
                            if (dist.days.some(day => dayName.includes(day)) && (!noPublicHoliday || !isPublicHoliday(currentDate)) && !isIgnoreDate(currentDate)) {
                                if (currentLesson <= dist.lessons) {
                                    let classItem = {
                                        classId: classId++,
                                        courseId: course,
                                        intake: currentIntake,
                                        prefixKey,
                                        lesson: displayLessonCount + 1,
                                        date: currentDate.format('YYYY-MM-DD'),
                                        type: "Class"
                                    };

                                    output.push(classItem);
                                    currentLesson++;
                                    displayLessonCount++;


                                }
                            }

                            currentDate.add(1, 'days'); // move to the next day
                        }
                        currentWeek++;
                    }


                }

            });



            // Schedule the exam after the last class
            let lastClassDate = moment(output[output.length - 1].date);
            let examDate = lastClassDate.add(1, 'week');
            while (isPublicHoliday(examDate) || isIgnoreDate(examDate)) {
                examDate.add(1, 'day');
            }

            output.push({
                title: `Exam ${classId}`,
                date: examDate.format('YYYY-MM-DD'),
                classId: classId++,
                type: 'Exam',
                intake: currentIntake,
                prefixKey,
                lesson: null
            });

            examDate = moveToDate(examDate, 'Sat');
            startDate = examDate.add(1, 'weeks');

        }

        setResult(output);
        setLoading(false);
    };

    const moveToDate = (currentDate, day) => {
        while (currentDate.format('ddd') !== day) {
            currentDate.add(1, 'day');
        }
        return currentDate;
    };

    const isPublicHoliday = (date) => {
        return publicHolidays.includes(date.format('YYYYMMDD'));
    };

    const isIgnoreDate = (date) => {
        return ignoreDates.map(item => moment(item).format('YYYYMMDD')).includes(date.format('YYYYMMDD'));
    };

    return (
        <Fade in={true}>
            <div>
                <Grid container spacing={2}>
                    <Grid xs={8}>
                        <FullCalendarWrapper>
                            <FullCalendar
                                plugins={[dayGridPlugin]}
                                initialView='dayGridMonth'
                                weekends={!hideWeekend}
                                events={schedule}
                                eventContent={renderEventContent}
                                height={600}
                            />
                        </FullCalendarWrapper>
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={<IOSSwitch sx={{ m: 1 }} onClick={() => setPreviewChange(!previewChange)} checked={previewChange} />}
                            label="Preview Changes"
                        />
                    </Grid>
                    <Divider orientation="vertical" variant="middle" sx={{ py: 7}} flexItem>
                        <div style={{ width: 70, height: 0}}></div>
                    </Divider>
                    <Grid xs>
                        <FormGroup style={{ marginBottom: 30 }}>
                            <h6>Display</h6>
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} onChange={() => setHideWeekend(!hideWeekend)} checked={hideWeekend} />}
                                label="Hide weekend"
                            />
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} onChange={() => setHideExistingIntake(!hideExistingIntake)} checked={hideExistingIntake} />}
                                label="Hide existing intakes"
                            />
                        </FormGroup>
                        <FormGroup>
                            <h6>AI Assistant Control</h6>
                            <FormControl variant="standard" sx={{ mt: 1, mb: 2, minWidth: 200, maxWidth: 400 }}>
                                <InputLabel id="course-select-label">Course</InputLabel>
                                <Select
                                    labelId="course-select-label"
                                    id="course-select"
                                    label="Course"
                                    value={course}
                                    onChange={(e) => {
                                        const { value } = e.target;;
                                        const lesson = classSchedule
                                            .filter((item) => item.courseId === value)
                                            .sort((a, b) => b.intake - a.intake || b.endDate - a.endDate)[0];
                                        console.log(lesson);
                                        const mapIntake = classSchedule
                                            .filter((item) => item.courseId === value && item.intake === lesson?.intake)
                                        setCourse(value)
                                        const result = lesson?.endDate ? moment(lesson.endDate).add(2, 'weeks').toDate() : new Date();
                                        setDayFrom(dayjs(result));
                                        setIntakeLesson(mapIntake.length - 1 || 10)
                                    }}
                                >
                                    {courses.map((item) => {
                                        return <MenuItem key={item.key} value={item.value}>{item.key}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
                                <TextField
                                    id="total-lesson-text"
                                    label="Intake lesson(s)"
                                    variant="standard"
                                    defaultValue={intakeLesson}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setIntakeLesson(value)
                                    }}
                                    value={intakeLesson}
                                    sx={{ minWidth: 100, maxWidth: 200 }}
                                />
                                <TextField
                                    id="total-intake-text"
                                    label="Recurring"
                                    variant="standard"
                                    defaultValue={recurring}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setRecurring(value);
                                    }}
                                    sx={{ minWidth: 100, maxWidth: 200 }}
                                />
                            </Stack>
                            {lessonDistributions.map((dist, index) => (
                                <Stack key={dist.id} direction="row" spacing={1} alignItems="center" mb={2}>
                                <TextField
                                    label="Lessons"
                                    type="number"
                                    variant="standard"
                                    value={dist.lessons}
                                    onChange={(e) => handleLessonCountChange(dist.id, e.target.value)}
                                    sx={{ width: '100px' }}
                                />
                                <FormControl variant="standard" sx={{ mt: 1, mb: 2 }} fullWidth>
                                    <InputLabel id="every-multiple-checkbox-label">Every(s)</InputLabel>
                                    <Select
                                        multiple
                                        value={dist.days}
                                        onChange={(e) => handleDayChange(dist.id, e.target.value)}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {fullWeeks.map(day => (
                                            <MenuItem key={day} value={day}>
                                                <Checkbox checked={dist.days.includes(day)} />
                                                <ListItemText primary={day} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                    {lessonDistributions.length > 1 &&
                                    <IconButton onClick={() => handleRemoveDistribution(dist.id)}>
                                        <RemoveCircleOutlineIcon fontSize={"small"} />
                                    </IconButton>
                                    }
                                </Stack>
                              ))}
                            <Button color={"success"} startIcon={<AddCircleOutlineIcon />} onClick={handleAddDistribution}>
                                Add Distribution
                            </Button>
                            <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 2 }}>
                                <Box style={{ width: '50%', minWidth: 30, maxWidth: 220 }}>Intake Start From</Box>
                                <Box style={{ width: 180 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            variant="standard"
                                            renderInput={(params) => (
                                                <TextField fullWidth {...params} />
                                            )}
                                            value={dayFrom}
                                            onChange={(e) => {
                                                setDayFrom(dayjs(e))
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 2 }}>
                                <Box style={{ width: '50%', minWidth: 30, maxWidth: 220 }}>Ignore Date(s)</Box>
                                <Box style={{ width: 180 }}>
                                    <Button variant="text" onClick={() => setMultiDateOpen(true)}>SELECT DATE ({ignoreDates.length})</Button>
                                    <MultipleDatesPicker
                                        open={multiDateOpen}
                                        selectedDates={ignoreDates}
                                        onCancel={() => setMultiDateOpen(false)}
                                        onSubmit={dates => {
                                            setIgnoreDates(dates);
                                            setMultiDateOpen(false);
                                        }}
                                    />
                                </Box>
                            </Stack>
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} onChange={() => setNoPublicHoliday(!noPublicHoliday)} checked={noPublicHoliday} />}
                                label="No public holiday"
                            />
                            <LoadingButton
                                onClick={async () => {
                                    setLoading(true);
                                    try {
                                        await delay(1000);
                                        generateIntakePlan()
                                        setLoading(false);
                                    } catch (err) {
                                        console.log(err);
                                        setLoading(null);
                                    }
                                }}
                                startIcon={<RestartAltIcon />}
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                sx={{ mt: 1, minWidth: 200, maxWidth: 410 }}
                                disabled={!generatedEnable || loading}
                            >
                                Generate
                            </LoadingButton>
                        </FormGroup>
                    </Grid>
                    <Grid xs={12}>
                        <DisplayResult classSchedule={classSchedule} courses={courses} course={course} result={result} loading={loading} />
                    </Grid>
                </Grid>
            </div>
        </Fade>
    );
}
export default ScheduleTable;
