import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro, GridColumnMenu,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid-pro';
import {
    Badge,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Fade, TextField, InputLabel, Select, MenuItem, FormControl, CircularProgress, Typography, Link
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {useEffect, useState} from "react";
import moment from "moment";
import {
    useGetIntakeMutation, useGetPreviousIntakeClassesMutation,
    useLazyGetRecordingZipQuery,
    useUpdatePreviousClassMutation
} from "../../../../api/courseIntakeApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import TaskIcon from '@mui/icons-material/Task';
import {useNavigate, useParams} from "react-router-dom";
import {delay} from "../../../../utils/util";
import PollIcon from '@mui/icons-material/Poll';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StyledTableOne from "../../../../components/StyledTable/StyledTableOne";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useLazyGetZoomJoinUrlQuery} from "../../../../api/courseGeneralApi";
import {useDispatch, useSelector} from "react-redux";
import {setIntakeTab} from "../../../../redux/slices/IntakeSlice";
import {getWeeks} from "../../../../utils/request/course";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {useGetQuizDetailMutation} from "../../../../api/courseClassApi";
const defaultColumnType = {
    resizable: false,
    filterable: true,
    sortable: false,
    editable: false,
    groupable: false,
    disableColumnMenu: false
};

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuColumnsItem: null,
            }}
        />
    );
}

export default function MaterialList({ courseId, intake }) {

    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [materials, setMaterials] = useState([]);
    const [classes, setClasses] = useState([]);
    const [getQuizDetail] = useGetQuizDetailMutation();
    const [quizDetail, setQuizDetail] = useState([]);
    const { intakeTab } = useSelector((state) => state.intake);
    const { state } = intakeTab;
    useEffect(() => {

        getWeeks(courseId, intake).then((result) => {
            const weekData = result.data.data;
            const flattenedAssignment = weekData?.weeks?.flatMap(week => week.assignments)
                .map(item => ({...item, id: item.assignmentId}))
                .sort((a, b) => a.startDate - b.startDate);

            const flattendMaterial = weekData?.weeks?.flatMap(week => week.materials)
                .map(item => ({...item, id: item.materialId}))
                .sort((a, b) => a.startDate - b.startDate);

            const flattenedClasses = weekData?.weeks?.flatMap(week => week.classes)
                .sort((a, b) => a.startDate - b.startDate);


            setClasses(flattenedClasses);
            const materials = [...flattenedAssignment, ...flattendMaterial].sort((a, b) => a.type.localeCompare(b.type));
            setMaterials(materials);

            const quizs = flattenedAssignment.filter(m => m.type === 'QUIZ');
            if(quizs.length > 0) {
                const assignmentIds = quizs.map(q => q.assignmentId);
                getQuizDetail(assignmentIds).then((result) => {
                    setQuizDetail(result.data.data);
                });
            }
            let filterMaterials = materials;
            if(state?.classId){
                const { classId, type } = state;
                filterMaterials = materials.filter(c => c.associateClassId === classId && c.type === type);
            }

            setData(filterMaterials);

        });
    }, []);







    const columns = [
        {
            field: "id",
            hide: true
        },
        {
            field: "classDetail",
            ...defaultColumnType,
            headerName: "Class Information",
            renderCell: (params) => {
                const ClassChip = () => {
                    let color;
                    let label = params.row.type;
                    switch (params.row.type) {
                        case 'ASSIGNMENT':
                            color = 'success';
                            break
                        case 'QUIZ':
                            color = 'info';
                            break;
                        case 'READING':
                            color = 'secondary';
                            break;
                        case 'VIDEO':
                            color = 'warning';
                            break;
                        default:
                            color = "secondary";
                    }
                    return (
                        <Chip label={label} color={color} variant="outlined" sx={{ blockSize: '90%', mr: 1}}/>

                    )
                }
                const matchClass = classes.find(c => c.classId === params.row.associateClassId);
                return (
                    <Stack spacing={1}>
                                <span>
                            <ClassChip sx={{blockSize: 5}}/>
                        </span>
                        <Typography variant="body1" fontWeight={"bold"} pl={0.5}>{params.row.name}</Typography>
                        <Typography variant="body2" mt={1}>
                            <Typography variant="caption" ml={0.5}>Class</Typography>
                            <NavigateNextIcon fontSize={"small"}/>
                            {matchClass ? <Link onClick={(e) => {
                                e.stopPropagation();
                            }}>{matchClass.name}</Link> : "N/A"}
                        </Typography>

                    </Stack>
                )
            },
            width: 400,
        },
        {
            field: "detail",
            ...defaultColumnType,
            renderCell: (params) => {
                if(['ASSIGNMENT', 'QUIZ'].includes(params.row.type)) {
                    const quizRecord = quizDetail.find(q => q.assignmentId === params.row.assignmentId);
                    return (
                        <Stack direction="row" spacing={10}>
                            <Stack spacing={1}>
                                <Typography variant="body2" fontWeight={"bold"}>Start Date</Typography>
                                <Typography variant="body2">{moment(params.row.startDate).format('YYYY-MM-DD HH:mm')}</Typography>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="body2" fontWeight={"bold"}>End Date</Typography>
                                <Typography variant="body2">{moment(params.row.endDate).format('YYYY-MM-DD HH:mm')}</Typography>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="body2" fontWeight={"bold"}>Passing %</Typography>
                                <Typography variant="body2">{params.row.passPercentage || "N/A"}</Typography>
                            </Stack>
                            {params.row.type === 'ASSIGNMENT' ?
                                <Stack spacing={1}>
                                    <Typography variant="body2" fontWeight={"bold"}>Max Point</Typography>
                                    <Typography variant="body2">{params.row.maxPoint}</Typography>
                                </Stack>
                                :
                                <>
                                <Stack spacing={1}>
                                    <Typography variant="body2" fontWeight={"bold"}>Question</Typography>
                                    <Typography variant="body2">{quizRecord?.questions ? JSON.parse(quizRecord?.questions).length : "0"}</Typography>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography variant="body2" fontWeight={"bold"}>Duration</Typography>
                                    <Typography variant="body2">{quizRecord?.timeLimit ? quizRecord?.timeLimit : "N/A"}</Typography>
                                </Stack>
                                </>
                            }
                        </Stack>
                    )
                }
                if(['READING', 'VIDEO'].includes(params.row.type)) {
                    return params.row.description
                }
                return "";
            },
            flex: 2,

        },
        {
            field: "next",
            ...defaultColumnType,
            headerName: "",
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '98%' }}>
                        <ArrowForwardIosIcon color={"disabled"} fontSize={"medium"} />
                    </Box>
                )
            },
            width: 20,
        },
    ];

    const initState = {
        "columns": {
            "columnVisibilityModel": {
                "id": false,
            }
        },
        pagination: { paginationModel: { pageSize: 1000 } },
    }

    const onRowClick = ({ row }) => {
        const defaultState = {
            primary: 'Class Detail',
            secondary: 'All Materials',
            route: 'Manage Classes',
            state: { row, materials, classes }
        }
        if(row.type === 'ASSIGNMENT')  {
            dispatch(setIntakeTab({
                    ...defaultState,
                    subRoute: 'Assignment'
                }));
        }else if(row.type === 'QUIZ') {
            const quizRecord = quizDetail.find(q => q.assignmentId === row.assignmentId);
            const mergeRow = {...row, questions: quizRecord.questions, timeLimit: quizRecord.timeLimit};
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute: 'Quiz',
                state: { row: mergeRow, materials, classes }
            }));
        }else if(row.type === 'READING') {
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute: 'Reading'
            }));
        }else if(row.type === 'VIDEO') {
            dispatch(setIntakeTab({
                ...defaultState,
                subRoute: 'Video'
            }));
        }
    }
    return (
        <Box sx={{ width: '100%', mt: 3, backgroundColor: 'white' }}>
            {state?.classId &&
                <Typography variant="h6"  mt={5} ml={5}> {state.name} <NavigateNextIcon/>  {state.type.charAt(0).toUpperCase() + state.type.slice(1).toLowerCase()}</Typography>
            }
            <StyledTableOne
                autoHeight
                rows={data || []}
                columns={columns}
                slots={{
                    columnMenu: CustomColumnMenu,
                    columnHeaders: () => null,
                }}
                onRowClick={onRowClick}
                initialState={initState}
                rowHeight={130}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                pageSizeOptions={[1000]}
                disableColumnMenu
                pagination
                hideFooterPagination
                hideFooterSelectedRowCount
                loading={data === null}
                sx={{
                    border: 'none'
                }}
            />
        </Box>
    );
}
