import React, {useEffect} from 'react';
import {
    Box,
    Button, CircularProgress, Divider,
    FormControlLabel, FormHelperText,
    Grid, IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {Controller, useForm, useWatch} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    useCreateCourseMutation,
    useGetGeneralCourseMutation,
    useUpdateGeneralCourseMutation
} from "../../../api/courseGeneralApi";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDeleteCourseMutation} from "../../../api/removeApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {useUpdateTutorAvatarMutation, useUpdateTutorProfileMutation} from "../../../api/courseTutorApi";
import {useCreateUserMutation, usePasswordResetMutation, useRemoveUserMutation} from "../../../api/settingApi";
import {IOSSwitch} from "../../../components/Switcher";
import noneAvatar from "../../Setting/images/none.png";
import LoadingButton from "@mui/lab/LoadingButton";



export default function EditTutor(props) {
    const navigate = useNavigate();
    const { tutorId } = useParams()
    const location = useLocation();
    const isCreate = tutorId === "create";
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [deleteCourse] = useDeleteCourseMutation();
    const [img, setImg] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const row = location.state?.row;
    const [createTutor] = useCreateUserMutation();
    const [updateTutor] = useUpdateTutorProfileMutation();
    const [updateAvatar] = useUpdateTutorAvatarMutation();
    const [updatePassword] = usePasswordResetMutation();
    const [removeTutor] = useRemoveUserMutation();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, submitCount },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
    } = useForm()


    useEffect(() => {
        if(!isCreate) {
            if(row) {
                const { nameEn, nameZh, email, mobile, title, linkedin, disable, thumbnailPath } = row;
                setValue('nameEn', nameEn)
                setValue('nameZh', nameZh)
                setValue('email', email)
                setValue('mobile', mobile)
                setValue('title', title)
                setValue('linkedin', linkedin)
                setValue('disable', disable)
                setImg(thumbnailPath)
            }
        }
    }, [tutorId]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImg(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const { password, ...finalData } = data;
        let userId = null;
        if(isCreate) {
            const { data : { data : { id } }} = await createTutor({ username: data.email, password, roleType: "TUTOR" });
            if(id){
                userId = id;
                await updateTutor({ id, ...finalData });
            } else {
                alert("Fail to create new tutor.");
            }
        }else {
            userId = tutorId;
            await updateTutor({ id: tutorId, ...finalData });

            if(password){
                await updatePassword({ username: data.email , password });
            }
        }


        if (selectedFile) {
            await updateAvatar({ tutorId: userId, file: selectedFile });
        }
        navigate("/tutor")
    }

    console.log("row", row)
    return (
        <Fade in={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{p: 5, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                    {isRemoving ?
                        <CircularProgress size={18} /> :
                        !isCreate &&
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation()
                                if (window.confirm("Are you sure to remove? It will delete all the related data.")) {
                                    setIsRemoving(true);
                                    removeTutor({ username: row.email, roleType: 'TUTOR' }).then(() => {
                                        setIsRemoving(false);
                                        navigate("/tutor")
                                    });
                                }
                            }}
                            size="small"

                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                    </Stack>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={4}>
                            <Box>
                                <input
                                    type="file"
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    id="avatar-upload"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="avatar-upload">
                                    <Button
                                        component="span"
                                        sx={{
                                            fontSize: 300,
                                            opacity: img ? 1 : 0.5,
                                            borderRadius: '50%',
                                            transition: 'all 0.3s ease',
                                            color: 'black',
                                            '&:hover': {
                                                opacity: 0.6
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 300,
                                                height: 300,
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '4px solid rgba(46, 125, 50, 0.08)', // Adjust the border color and width as needed
                                            }}
                                        >
                                            {img ?
                                                <img
                                                    src={img}
                                                    alt="avatar"
                                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                />

                                                :
                                                <AccountCircleIcon color={"inherit"} sx={{fontSize: 'inherit'}}/>
                                            }
                                        </Box>
                                    </Button>
                                </label>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <Box>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                        <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='nameEn'>
                                                Name (Eng) *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='nameEn'
                                                type='text'
                                                placeholder='Cha Tai Man'
                                                {...register('nameEn', {required: 'This is required field.'})}
                                            />
                                            {errors.nameEn &&
                                                <FormHelperText error>{errors.nameEn.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='nameZh'>
                                                Name (Chi)
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='nameZh'
                                                type='text'
                                                placeholder='陳大文'
                                                {...register('nameZh')}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='email'>
                                                Email *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='email'
                                                type='email'
                                                placeholder='example@email.com'
                                                disabled={!isCreate}
                                                {...register('email', {required: 'This is required field.'})}
                                            />
                                            {errors.email &&
                                                <FormHelperText error>{errors.email.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='mobile'>
                                                Mobile *
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='mobile'
                                                type='text'
                                                placeholder='50101010'
                                                {...register('mobile', {required: 'This is required field.'})}
                                            />
                                            {errors.mobile &&
                                                <FormHelperText error>{errors.mobile.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='mb-4'>
                                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='englishName'>
                                        Title *
                                    </label>
                                    <input
                                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                        id='title'
                                        type='text'
                                        placeholder='Bachelor of Science (Hons) in Computer Science'
                                        {...register('title', {required: 'This is required field.'})}
                                    />
                                    {errors.title &&
                                        <FormHelperText error>{errors.title.message}</FormHelperText>}
                                </div>
                                <div className='mb-4'>
                                    <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='linkedin'>
                                        Linkedin
                                    </label>
                                    <input
                                        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                        id='linkedin'
                                        type='text'
                                        placeholder='https://'
                                        {...register('linkedin')}
                                    />
                                </div>
                                <Divider sx={{borderColor: 'lightGrey', mb: 3}}/>
                                <Grid container columnSpacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <div className='mb-4'>
                                            <label className='block text-gray-700 text-sm font-bold mb-2'
                                                   htmlFor='password'>
                                                Password {isCreate ? "*" : "(Leave blank if not changing)"}
                                            </label>
                                            <input
                                                className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                id='nameEn'
                                                type='password'
                                                placeholder='********'
                                                {...register('password', isCreate ? {required: 'This is required field.'} : {})}
                                            />
                                            {errors.password &&
                                                <FormHelperText error>{errors.password.message}</FormHelperText>}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <div className='mb-4'>
                                            <Controller
                                                name='disable'
                                                control={control}
                                                render={({ field, field: { onChange, value} }) => (
                                                    <FormControlLabel
                                                        control={<IOSSwitch sx={{ m: 3, ml: 15 }} onChange={() => onChange(!value)} checked={!value}/>}
                                                        label={!value ? "Active" : "Inactive"}
                                                    />
                                            )}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>



                </Box>
                <Box sx={{p: 5, pt: 3, pb: 3, mb: 3, borderRadius: 5, backgroundColor: 'white'}}>
                    <Stack direction='row' spacing={2} justifyContent="flex-end">
                        <Button variant="outlined" color="primary" sx={{mt: 3}} onClick={() => navigate("/tutor")}>
                            Cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained" color="primary" sx={{mt: 3}} loading={isSubmitting}>
                            {isCreate ? "Create" : "Save Change"}
                        </LoadingButton>
                    </Stack>
                </Box>
            </form>
        </Fade>
    );
}
