module.exports = {
    ENROLLMENT_STATUS_REVIEW: 0,
    ENROLLMENT_STATUS_UNPAID: 1,
    ENROLLMENT_STATUS_PAID: 2,
    ENROLLMENT_STATUS_REJECT: 3,
    LOCAL_STORAGE_ADMIN: 'admin',
    LOCAL_STORAGE_ROLE: 'userRole',
    SUCCESS_CODE: 'SYS0000',
    FAIL_CODE: 'SYS9999',
}
